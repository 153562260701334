// // import React from 'react'

// // import { Typography } from '@mui/material'
// // import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
// // import InstagramIcon from '@mui/icons-material/Instagram';
// // import TwitterIcon from '@mui/icons-material/Twitter';
// // import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// // import './Footer.css'
// // const FooterContainer = () => {
// //     return (
// //         <div  className="main-footer-content">
// //             <div className='footer-content'>
// //                 <div >
// //                     <Typography variant='h6'  className='head-footer' >Prabhu's place</Typography>
// //                     <hr color='white' style={{ width: "auto" }}></hr>
// //                     <p className='content-footer'>
// //                     Prabhu's place provides an enriching and immersive travel experience that goes beyond mere accommodation, allowing travelers to connect with the local culture, forge new friendships, and create unforgettable memories.
// //                     </p>
// //                 </div>
// //                 <div className='card-two' style={{ marginBottom:'10px' }}>
// //                         <Typography className='head' variant='h6' style={{fontWeight: "200",fontSize:'20px',fontFamily:'Open Sans' }}>QUICK LINKS</Typography>

// //                         <div className='footer-list' style={{ marginTop: "10px" }}>
// //                             <a href='/'>Home</a>
// //                             <a href='/aboutus'>About Us</a>
// //                     </div>
// //                     <Typography variant='h6' className='head'  style={{fontWeight: "200",fontSize:'20px',fontFamily:'Open Sans' }}>Work With Us</Typography>
// //                     <div className='footer-list' style={{ marginTop: "10px" }}>
// //                             <a href='/hostelsignup'>Hostel Sign Up</a>
// //                     </div>
// //                 </div>

// //                 <div style={{ marginBottom: "90px" }} >
// //                     <Typography variant='h6'className='head' style={{ fontWeight: "200",fontSize:'20px',fontFamily:'Open Sans'}}>IMPORTANT LINKS</Typography>
// //                     <div className='footer-list' style={{ marginTop: "10px",height:'auto' }}>
// //                         <a href='/contacts'>Contact us</a>
// //                         <a href='/privacy&policys'>Privacy Policy</a>
// //                         <a href='/terms&conditions'>Terms & Conditions</a>
// //                     </div>

// //                 </div>

// //                 <div style={{ marginBottom: "120px" }}>
// //                     <Typography variant='h6' className='head' style={{  gap: "20px",fontSize:'20px',fontWeight:'200',fontFamily:'OPen Sans' }} >FOLLOW US ON</Typography>

// //                          <div className='social-icons' style={{marginTop:'10px',fontSize:'30px',gap:'10px'}}>
// //                             <FacebookOutlinedIcon />
// //                         <InstagramIcon />
// //                         <TwitterIcon />
// //                         <WhatsAppIcon />

// //                     </div>
// //                 </div>

// //             </div>
// //             <hr style={{ color: "gray" }}></hr>
// //             <div style={{ display: 'flex',marginLeft:"90px",fontFamily:'Open Sans' }}>
// //                 <div style={{ color: "gray", textAlign: 'start' }}>
// //                  <p >Prabusplace@gmail.com,All Right Resevrved</p>
// //                 </div>
// //                 <div style={{  display: 'flex', color: 'gray',fontFamily:'OPen Sans',justifyContent:'space-evenly'}}>

// //                 </div>

// //             </div>

// //         </div>
// //     )
// // }

// // export default FooterContainer

// import React from 'react'

// import { Typography } from '@mui/material'
// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import './Footer.css'
// const FooterContainer = () => {
//     return (
//         <div  className="main-footer-content">
//             <div className='footer-content'>
//                 <div >
//                     <Typography variant='h6'  className='head-footer' >WORLD OF HOSTELS</Typography>
//                     <hr color='white' style={{ width: "auto" }}></hr>
//                     <p className='content-footer'>
//                         World of Hostels provides an enriching and immersive travel experience that goes beyond mere accommodation, allowing travelers to connect with the local culture, forge new friendships, and create unforgettable memories.
//                     </p>
//                 </div>
//                 <div className='card-two' >
//                         <Typography className='head' variant='h6' style={{marginTop:'30px'}}>QUICK LINKS</Typography>

//                         <div className='footer-list' >
//                             <a href='/'>Home</a>
//                             <a href='/aboutus'>About Us</a>
//                     </div>

//                 </div>

//                 <div  >
//                     <Typography variant='h6'className='head' style={{marginTop:'30px'}}>IMPORTANT LINKS</Typography>
//                     <div className='footer-list'>
//                         <a href='/contacts'>Contact us</a>
//                         <a href='/privacy&policys'>Privacy Policy</a>
//                         <a href='/terms&conditions'>Terms & Conditions</a>
//                     </div>

//                 </div>

//                 <div>
//                 <Typography variant='h6' className='head' style={{marginTop:'30px'}}  >Work With Us</Typography>
//                     <div className='footer-list' >
//                             <a href='/hostelsignup'>Hostel Sign Up</a>
//                     </div>
//                     <Typography variant='h6' className='head' style={{marginTop:'30px'}} >FOLLOW US ON</Typography>

//                          <div className='social-icons' style={{}}>
//                             <FacebookOutlinedIcon />
//                         <InstagramIcon />
//                         <TwitterIcon />
//                         <WhatsAppIcon />

//                     </div>

//                 </div>

//             </div>
//             <hr style={{ color: "gray" }}></hr>
//             <div style={{ display: 'flex',marginLeft:"70px",fontFamily:'Open Sans' }}>
//                 <div style={{ color: "gray", textAlign: 'start' }}>
//                  <p >worldOfhostels@gmail.com,All Right Resevrved</p>
//                 </div>
//                 <div style={{  display: 'flex', color: 'gray',fontFamily:'OPen Sans',justifyContent:'space-evenly'}}>

//                 </div>

//             </div>

//         </div>
//     )
// }

// export default FooterContainer
import React from "react";

import { Typography } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Footer.css";
import { Link } from "react-router-dom";
const FooterContainer = () => {
  return (
    <div className="main-footer-content">
      <div className="footer-content">
        <div>
          <Typography
            variant="h6"
            className="head-footer"
            style={{ marginTop: "20px" }}
          >
            {" "}
            Prabhu's place
          </Typography>
          <hr color="white" style={{ width: "auto" }}></hr>
          <p className="content-footer">
            Prabhu's place provides an enriching and immersive travel experience
            that goes beyond mere accommodation, allowing travelers to connect
            with the local culture, forge new friendships, and create
            unforgettable memories.
          </p>
        </div>
        <div className="card-two">
          <Typography
            className="head"
            variant="h6"
            style={{ marginTop: "30px" }}
          >
            QUICK LINKS
          </Typography>

          <div className="footer-list">
            <Link to="/">Home</Link>
            <Link to="/aboutus">About Us</Link>
          </div>
        </div>

        <div>
          <Typography
            variant="h6"
            className="head"
            style={{ marginTop: "30px" }}
          >
            IMPORTANT LINKS
          </Typography>
          <div className="footer-list">
            <Link to="/contacts">Contact us</Link>
            <Link to="/privacy&policys">Privacy Policy</Link>
            <Link to="/terms&conditions">Terms & Conditions</Link>
          </div>
        </div>

        <div>
          <Typography
            variant="h6"
            className="head"
            style={{ marginTop: "30px" }}
          >
            Work With Us
          </Typography>
          <div className="footer-list">
            <Link to="/hostelsignup">Hostel Sign Up</Link>
          </div>
          <Typography
            variant="h6"
            className="head"
            style={{ marginTop: "30px" }}
          >
            FOLLOW US ON
          </Typography>

          <div className="social-icons">
            <FacebookOutlinedIcon />
            <InstagramIcon />
            <TwitterIcon />
            <WhatsAppIcon />
          </div>
        </div>
      </div>
      <hr style={{ color: "gray" }}></hr>
      <div
        style={{ display: "flex", marginLeft: "70px", fontFamily: "Open Sans" }}
      >
        <div style={{ color: "gray", textAlign: "start" }}>
          <p>Prabusplace@gmail.com,All Right Resevrved</p>
        </div>
        <div
          style={{
            display: "flex",
            color: "gray",
            fontFamily: "OPen Sans",
            justifyContent: "space-evenly",
          }}
        ></div>
      </div>
    </div>
  );
};

export default FooterContainer;
