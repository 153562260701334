import React from 'react'
import './Rateing.css'
import bookingcom1 from './../../assets/bookingcom1.png'
import bookingcomlogo from './../../assets/bookingcomlogo.svg'
import tripadvisor_logo from './../../assets/tripadvisor_logo.png'
import googleimg from './../../assets/googleimg.png'
import hostelbookers_logo from './../../assets/hostelbookers_logo.png'
import google_logo from './../../assets/google_logo.png'
const Rateing = () => {
    return (
        <div className='mainrateing' >
            <div className='submenu' style={{gap:"30px"}}>
                <div className='subtext'>
                    <h1 style={{ fontSize: "30px" }}>8.3</h1>
                    <h6 style={{ fontSize: "20px" }}>/10</h6>
                </div>
                <h4 style={{ fontSize: "20px" }}>1398 сomments</h4>
                <img src={bookingcomlogo} className='bookingimg' />
            </div>

                <div className='submenu'>
                    <div className='subtext'>
                    <h1 style={{ fontSize: "30px" }}>4.6</h1>
                    <h6 style={{ fontSize: "20px" }}>/5</h6>
                    </div>
                
                <div>
                    <h4 style={{ fontSize: "20px" }}>460 notes</h4>
                    <img src={tripadvisor_logo} className='bookingimg' />
                </div>
                </div>
            <div className='submenu'>
                <div className='subtext'>
                    <h1 style={{ fontSize: "30px" }}>4.9</h1>
                    <h6 style={{ fontSize: "20px" }}>/5</h6>
                    </div>
                
                <div>
                    <h4 style={{ fontSize: "20px" }}>2389 notes</h4>
                    <img src={google_logo} className='bookingimg' />
                </div>
                </div>
            <div className='submain'>
                <div className='submenu'>
                    <h1 style={{ fontSize: "30px" }}>98%</h1>
                </div>
                <div>
                    <h4 style={{ fontSize: "20px" }}>2389 recommendations</h4>
                    <img src={hostelbookers_logo} className='bookingimg' />
                </div>
            </div>
        </div>
       
    )
}

export default Rateing