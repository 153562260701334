import React from 'react'
import Footer from '../footer/FooterContainer'
import './Career.css'
import grouppeople from '../../assets/grouppeople.jpg'
import { Typography } from '@mui/material'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import FooterContainer from '../footer/FooterContainer'
const CareerContainer = () => {
  return (
    <div >
      <div className='career-main'>
        <div>
         <Typography   variant='h2' style={{fontFamily:'open Sans',fontWeight:'500'}}>Join our team !</Typography >
           <div className='career-buttons'>
            <button style={{width:'150px',height:'50px',borderRadius:'5px',backgroundColor:'pink'}}>Connect</button>
            <button style={{width:'150px',height:'50px',borderRadius:'5px',backgroundColor:'pink'}}>Job Openings</button>
           </div>
           
        </div>
       

        
      </div>
      <FooterContainer/>
    </div>
  )
}

export default CareerContainer
