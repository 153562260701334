import React from 'react';

const SectionContainer = React.forwardRef(({ section }, ref) => {
  return (
    <div ref={ref} id={section.id} className="privacy-section">
      <h2>{section.title}</h2>
      <p>{section.content}</p>
      <p style={{width:"auto"}}>{section.p}</p>
      <p  >{section.list}</p>
      <p  >{section.list2}</p>
      <p  >{section.list3}</p>
      <p  >{section.list4}</p>
      <p  >{section.list6}</p>
      <p  >{section.list5}</p>
      <p style={{width:"auto"}}>{section.p1}</p>

    </div>
  );
});

export default SectionContainer;