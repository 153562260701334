import React, { useEffect, useState } from "react";
import { Carousel, ProgressBar } from "react-bootstrap";
import bedroom from "../../assets/chinnaaasets/chpics/bedroom.webp";
import bedroom2 from "../../assets/chinnaaasets/chpics/bedroom2.jpeg";
import bedroom3 from "../../assets/chinnaaasets/chpics/bedroom3.jpeg";
import bedroom4 from "../../assets/chinnaaasets/chpics/bedroom4.webp";
import "./Cprabhu.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { Modal } from "react-bootstrap";
import { debounce } from "@mui/material/utils";
import CheckIcon from "@mui/icons-material/Check";
import chennaipic from "../../assets/chinnaaasets/chpics/chennaipic.webp";
import PersonIcon from "@mui/icons-material/Person";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InfoIcon from "@mui/icons-material/Info";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import RateReviewIcon from "@mui/icons-material/RateReview";

import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import wifi1 from "../../assets/wifi1.png";
import publictoilet from "../../assets/publictoilet.png";
import parking from "../../assets/parking.png";
import desk from "../../assets/desk.png";
import ac from "../../assets/ac.png";
import kitchen from "../../assets/kitchen.png";
import ReactStars from "react-rating-stars-component";

import dayjs from "dayjs";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Popover } from "@mui/material";
import "dayjs/locale/en-gb"; // Import the desired locale
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";
import FooterContainer from "../../components/footer/FooterContainer";
import birla from "../../assets/chinnaaasets/chpics/birla.jpg";
import world from "../../assets/chinnaaasets/chpics/world.jpg";
import chennaichurch from "../../assets/chinnaaasets/chpics/chennaichurch.jpg";
import chennaitemple from "../../assets/chinnaaasets/chpics/chennaitemple.jpg";
import mahabalipuram from "../../assets/chinnaaasets/chpics/mahabalipuram.jpg";
import musium from "../../assets/chinnaaasets/chpics/musium.jpg";
import beach from "../../assets/chinnaaasets/chpics/beach.jpg";
import beachchennai from "../../assets/chennaipic.jpeg";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";

const GOOGLE_MAPS_API_KEY = "AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y";

const cities = [
  { label: "Bprabhu" },
  { label: "Oprabhu" },
  { label: "Cprabhu" },
];

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const Chennaiprabhusplace = () => {
  const [selectedDates, setSelectedDates] = useState([
    dayjs(),
    dayjs().add(1, "day"),
  ]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState({
    label: "1 Guest",
    value: 1,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [cityError, setCityError] = useState(false);

  const navigate = useNavigate();

  const handleSearch = () => {
    if (selectedCity) {
      navigate(`/${selectedCity.label.toLowerCase()}`);
    } else {
      setCityError(true);
    }
  };

  const handleGuestChange = (increment) => {
    setSelectedGuest((prevGuest) => {
      let newGuestCount = prevGuest.value + increment;
      newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
      return {
        label: `${newGuestCount} Guest${newGuestCount > 1 ? "s" : ""}`,
        value: newGuestCount,
      };
    });
  };

  const handleGuestClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGuestClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "guest-popover" : undefined;

  const data = [
    {
      div: 1,
      imgsrc: bedroom,
    },
    {
      div: 2,
      imgsrc: bedroom2,
    },
    {
      div: 3,
      imgsrc: bedroom3,
    },
    {
      div: 4,
      imgsrc: bedroom4,
    },
    {
      div: 5,
      imgsrc: bedroom,
    },
    {
      div: 6,
      imgsrc: bedroom2,
    },
  ];
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const gallery = [bedroom2, bedroom3, bedroom4, bedroom, chennaipic];

  const handleClosegallery = () => setShow(false);
  const handleShowgallery = (selectedIndex) => {
    setIndex(selectedIndex);
    setShow(true);
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleShowreview = () => {
    setShowAllReviews(!showAllReviews);
  };

  const [activeTab, setActiveTab] = useState("description");
  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };

  const [model, setModel] = useState(false);
  const [tempimgsrc, settempimgsrc] = useState("");
  const getImg = (imgsrc) => {
    settempimgsrc(imgsrc);
    setModel(true);
  };
  useEffect(() => {
    let handler = () => {
      setModel(false);
    };
    document.addEventListener("mousedown", handler);
  });

  const [showLess, setshowLess] = useState(true);

  const str =
    "Siruseri, Chennai, embodies the essence of a burgeoning tech hub. With its proximity to the IT parks and business centers, mornings here often start early, as professionals commute to work amidst the city's bustling traffic. During the day, the streets buzz with activity, with software engineers and tech enthusiasts engrossed in their work within the high-rise office buildings. Evenings offer a welcome respite, as residents unwind at local eateries or take leisurely walks along serene OMR road. Siruseri is a melting pot of cultures, where modernity meets tradition, creating a unique tapestry of experiences for its inhabitants";

  var resultStr = showLess ? str.slice(0, 600) : str;

  const changeShow = () => {
    setshowLess(!showLess);
  };

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const apartments = [
    {
      name: "Fully furnished 2 bed 2 bath Apartment",
      images: [bedroom, bedroom2, bedroom3, bedroom4],
      description:
        "Fully furnished 2 bed 2 bath apartment with cooking amenities including a microwave oven, Fridge, utensils, a washer and a space to dry your clothes in the balcony.",
      originalPrice: 3500,
      discountedPrice: 6500,
    },
  ];

  const selection = {
    totalAmount: 6500,
  };

  const reviewData = [
    {
      name: "ashok",
      gender: "Male",
      age: "20-26",
      status: "Novice Nomed",
      reviews: 2,
      rating: 6.1,
      feedback:
        "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
    },
    {
      name: "raju",
      gender: "Male",
      age: "20-26",
      status: "Novice Nomed",
      reviews: 5,
      rating: 7.5,
      feedback:
        "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
    },
    {
      name: "Virat",
      gender: "Male",
      age: "30-36",
      status: "Novice Nomed",
      reviews: 1,
      rating: 10,
      feedback:
        "An example of what a hostel should be. The hospital and friendliness of the staff made me feel very comfortable and relaxed.",
    },
  ];
  const limitedReviewData = showAllReviews
    ? reviewData
    : reviewData.slice(0, 0);
  const [selectedPlaceIndex, setSelectedPlaceIndex] = useState(null);

  const handleShowtours = (index) => {
    setSelectedPlaceIndex(selectedPlaceIndex === index ? null : index);
  };

  const places = [
    {
      title: " Marina Beach ",
      description:
        " Marina Beach is the longest urban beach in India and the second longest in the world. ",
      imageUrl: beach,
      price: "1000",
    },
    {
      title: "Elliots Beach ",
      description:
        "Also known as Besant Nagar Beach, Elliot’s Beach is a quieter and more serene alternative to Marina Beach. ",
      imageUrl: beachchennai,
      price: "500",
    },
    {
      title: "Kapaleeshwarar Temple",
      description:
        "Located in Mylapore, this ancient Hindu temple is dedicated to Lord Shiva. ",
      imageUrl: chennaitemple,
      price: "800",
    },
    {
      title: "Mahabalipuram",
      description:
        "Located about 60 km from Chennai, this UNESCO World Heritage Site is famous for its rock-cut temples.  ",
      imageUrl: mahabalipuram,
      price: "700",
    },

    {
      title: "San Thome Basilica",
      description:
        "This Roman Catholic basilica was built over the tomb of St. Thomas the Apostle. ",
      imageUrl: chennaichurch,
      price: "900",
    },
    {
      title: "Government Museum",
      description:
        "Located in Egmore, this museum is the second oldest in India. It has an extensive collection of artifacts,etc.. ",
      imageUrl: musium,
      price: "5000",
    },
    {
      title: "Birla Planetarium",
      description:
        " Located in Kotturpuram, the Birla Planetarium offers an immersive experience of the universe with its state-of-the-art projections,exhibits.",
      imageUrl: birla,
      price: "4000",
    },
    {
      title: " MGM Dizzee World",
      description:
        "A popular amusement park located on the East Coast Road, MGM Dizzee World offers a variety of rides and entertainment .",
      imageUrl: world,
      price: "900",
    },
  ];

  const amenities = [
    { img: parking, text: "Parking Available", label: "Parking" },
    { img: wifi1, text: "Wifi Available", label: "Wifi" },
    { img: ac, text: "Ac Available", label: "Ac" },
    { img: desk, text: "Work Spaces", label: "Work Spaces" },
    { img: kitchen, text: "Kitchen Available", label: "Kitchen" },
    { img: publictoilet, text: "Commonwashroom Available", label: "Washroom" },
  ];

  const [thingBookings, setThingBookings] = useState(
    places.map(() => ({ isBooked: false, quantity: 0 }))
  );

  const [roomBookings, setRoomBookings] = useState(
    apartments.map(() => ({ isBooked: false, quantity: 0, price: 0 }))
  );

  const handleThingBookNow = (index) => {
    const newThingBookings = [...thingBookings];
    newThingBookings[index].isBooked = true;
    newThingBookings[index].quantity = 1;
    setThingBookings(newThingBookings);
  };

  const handleThingIncrement = (index) => {
    const newThingBookings = [...thingBookings];
    newThingBookings[index].quantity += 1;
    setThingBookings(newThingBookings);
  };

  const handleThingDecrement = (index) => {
    const newThingBookings = [...thingBookings];
    if (newThingBookings[index].quantity > 0) {
      newThingBookings[index].quantity -= 1;
      if (newThingBookings[index].quantity === 0) {
        newThingBookings[index].isBooked = false;
      }
      setThingBookings(newThingBookings);
    }
  };

  const handleAddRoom = (index) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index].isBooked = true;
    newRoomBookings[index].quantity = 1;
    newRoomBookings[index].price = apartments[index].discountedPrice;
    setRoomBookings(newRoomBookings);
  };

  const handleRoomIncrement = (index) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index].quantity += 1;
    setRoomBookings(newRoomBookings);
  };

  const handleRoomDecrement = (index) => {
    const newRoomBookings = [...roomBookings];
    if (newRoomBookings[index].quantity > 0) {
      newRoomBookings[index].quantity -= 1;
      if (newRoomBookings[index].quantity === 0) {
        newRoomBookings[index].isBooked = false;
        newRoomBookings[index].price = 0;
      }
      setRoomBookings(newRoomBookings);
    }
  };

  const totalThingAmount = thingBookings.reduce(
    (acc, booking, index) => acc + booking.quantity * places[index].price,
    0
  );

  const totalRoomAmount = roomBookings.reduce(
    (acc, booking) => acc + booking.quantity * booking.price,
    0
  );

  const totalAmount = totalThingAmount + totalRoomAmount;

  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight - 340); // Window height minus 50px

  useEffect(() => {
    const handleResize = () => setSidebarHeight(window.innerHeight - 340);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section>
      <div>
        <div className="cprabhu-main">
          <img src={bedroom} alt=""></img>
        </div>

        <div className="container   cprabhu-section">
          <div className=" row   cprabhus-contnt">
            <Container style={{ marginTop: "10px" }}>
              <Typography
                as="h5"
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "30px",
                  fontWeight: "400",
                }}
              >
                Prabhu's place in Chennai
              </Typography>
              <Typography
                as="h6"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                <LocationOnIcon style={{ fontSize: "30px" }} /> Chennai,
                Kalipathur, Tamil Nadu, India
              </Typography>
            </Container>
            <div className="cprabhu-main-containers">
              <div
                style={{
                  height: "auto",
                  width: "auto",
                  boxSizing: "border-box",
                }}
              >
                <div className="cprabhu-main-rooms-container">
                  <div className="tabs-container">
                    <Tabs
                      defaultActiveKey="description"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      style={{ overflowX: "auto" }}
                    >
                      <Tab
                        eventKey="description"
                        title={
                          <span
                            onClick={() => handleTabClick("description")}
                            style={{
                              color:
                                activeTab === "description" ? "blue" : "black",
                            }}
                          >
                            <InfoIcon /> Description
                          </span>
                        }
                      >
                        <div className="bprabhu-tabs-description">
                          <p style={{ fontFamily: "Roboto", fontSize: "16px" }}>
                            {" "}
                            Waking up to a beautiful sunrise every morning that
                            beaming into a comfortably decorated large 2 bedroom
                            2 bath apartment that is strategically located off
                            OMR road and just walking distance to Chennai
                            Mathematical Institute, SIPCOT in Siruseri. Just a
                            stone throw away from Marina Mall that boosts an
                            array of food options and shopping. The apartment
                            faces the back waters and the greenery that feels
                            like you are in Kerala.{" "}
                          </p>
                          <p style={{ fontFamily: "Roboto", fontSize: "16px" }}>
                            {showLess
                              ? `${resultStr.slice(0, 150)}...`
                              : resultStr}
                          </p>
                          <Button
                            variant="text"
                            onClick={changeShow}
                            style={{ color: "palevioletred" }}
                          >
                            {showLess ? "Show more" : "Show Less"}
                          </Button>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="Ameinites"
                        title={
                          <span
                            onClick={() => handleTabClick("Ameinites")}
                            style={{
                              color:
                                activeTab === "Ameinites" ? "blue" : "black",
                            }}
                          >
                            <LocalActivityOutlinedIcon /> Ameinites
                          </span>
                        }
                      >
                        <div className="facilities">
                          <Row>
                            {amenities.map((amenity, index) => (
                              <Col
                                xs={6}
                                md={4}
                                key={index}
                                className="amenity-col"
                              >
                                <div className="image-wrapper">
                                  <img
                                    className="facilities-bng"
                                    src={amenity.img}
                                    alt=""
                                  />
                                  <div className="hover-text">
                                    {amenity.text}
                                  </div>
                                </div>
                                <h6 className="facilities-bng-text">
                                  {amenity.label}
                                </h6>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="location"
                        title={
                          <span
                            onClick={() => handleTabClick("location")}
                            style={{
                              color:
                                activeTab === "location" ? "blue" : "black",
                            }}
                          >
                            <FmdGoodIcon /> Location
                          </span>
                        }
                      >
                        <div className="tabs-map-container">
                          <Typography
                            variant="h5"
                            style={{ fontFamily: "Roboto", fontSize: "18px" }}
                          >
                            Ramaniyam Suites and Siruseri 2 bed apartment
                          </Typography>
                          <iframe
                            width="100%"
                            height="200"
                            frameborder="0"
                            scrolling="no"
                            marginheight="0"
                            marginwidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=No.37,%20Kazhipattur%20Village,%20Siruseri,,%20Chennai,%20Tamil%20Nadu+(World%20of%20Hostels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          >
                            <a href="https://www.gps.ie/">gps devices</a>
                          </iframe>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="review"
                        title={
                          <span
                            onClick={() => handleTabClick("review")}
                            style={{
                              color: activeTab === "review" ? "blue" : "black",
                            }}
                          >
                            <RateReviewIcon /> Review
                          </span>
                        }
                      >
                        <div className="review-tab">
                          <Typography
                            as="h6"
                            style={{
                              backgroundColor: "tomato",
                              margin: "10px",
                              fontFamily: "Roboto",
                            }}
                          >
                            ⭐ 100% genuine Review from real hostel travelers
                            like you!
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              margin: "30px",
                            }}
                          >
                            <div>
                              <h1
                                style={{
                                  backgroundColor: "tomato",
                                  color: "white",
                                  borderRadius: "10%",
                                }}
                              >
                                8.9
                              </h1>
                            </div>
                            <div>
                              <h5 style={{ fontFamily: "Roboto" }}>Good</h5>
                              <h6 style={{ fontFamily: "Roboto" }}>
                                29 Total Reviews
                              </h6>
                            </div>
                          </div>
                          <Row style={{ margin: "10px" }}>
                            <Col>
                              {[
                                { label: "Security", value: 85 },
                                { label: "Staff", value: 90 },
                                { label: "Cleanliness", value: 80 },
                                { label: "Value For Money", value: 80 },
                              ].map((item, index) => (
                                <div key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      as="h6"
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                    <Typography
                                      as="h6"
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {item.value / 10}
                                    </Typography>
                                  </div>
                                  <ProgressBar
                                    variant="danger"
                                    now={item.value}
                                    style={{ height: "5px" }}
                                  />
                                </div>
                              ))}
                            </Col>
                            <Col>
                              {[
                                { label: "Location", value: 80 },
                                { label: "Atmosphere", value: 85 },
                                { label: "Facilities", value: 85 },
                              ].map((item, index) => (
                                <div key={index}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography
                                      as="h6"
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {item.label}
                                    </Typography>
                                    <Typography
                                      as="h6"
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      {item.value / 10}
                                    </Typography>
                                  </div>
                                  <ProgressBar
                                    variant="danger"
                                    now={item.value}
                                    style={{ height: "5px" }}
                                  />
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <div
                            style={{
                              marginLeft: "30px",
                              maxHeight: showAllReviews ? "none" : "200px",
                              overflow: "hidden",
                            }}
                          >
                            {limitedReviewData.map((review, index) => (
                              <div key={index} style={{ display: "flex" }}>
                                <div>
                                  <PersonIcon />
                                  <Typography
                                    as="h6"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {review.name}
                                  </Typography>
                                  <Typography
                                    as="h6"
                                    style={{ color: "gray", fontSize: "14px" }}
                                  >
                                    {review.gender}, {review.age}
                                  </Typography>
                                  <Typography
                                    as="h6"
                                    style={{ color: "gray", fontSize: "14px" }}
                                  >
                                    {review.status}
                                  </Typography>
                                  <Typography
                                    as="h6"
                                    style={{
                                      color: "orange",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {review.reviews} reviews
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "60px",
                                    textAlign: "start",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "15px" }}>
                                    <h4
                                      style={{
                                        backgroundColor: "tomato",
                                        color: "white",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      {review.rating}
                                    </h4>
                                    <h6 style={{ color: "tomato" }}>
                                      {review.rating >= 8
                                        ? "Very Good"
                                        : review.rating >= 5
                                        ? "Good"
                                        : "Average"}
                                    </h6>
                                  </div>
                                  <p
                                    style={{
                                      fontFamily: "Roboto",
                                      fontSize: "17px",
                                    }}
                                  >
                                    {review.feedback}
                                  </p>
                                </div>
                              </div>
                            ))}
                            {reviewData.length > 2 && (
                              <Button
                                variant="contained"
                                onClick={handleShowreview}
                              >
                                {showAllReviews ? "Show Less" : "Read More"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="gallery"
                        title={
                          <span
                            onClick={() => handleTabClick("gallery")}
                            style={{
                              color: activeTab === "gallery" ? "blue" : "black",
                            }}
                          >
                            <CollectionsOutlinedIcon /> Gallery
                          </span>
                        }
                      >
                        <div className="tabs-gallery-container">
                          <Container>
                            <Row>
                              {gallery.map((image, idx) => (
                                <Col xs={4} sm={3} md={3} lg={2} key={idx}>
                                  <img
                                    src={image}
                                    alt={`Gallery Image ${idx}`}
                                    style={{
                                      width: "100%",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                      gap: "10px",
                                    }}
                                    onClick={() => handleShowgallery(idx)}
                                  />
                                </Col>
                              ))}
                            </Row>

                            <Modal
                              show={show}
                              onHide={handleClosegallery}
                              size="lg"
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                <Carousel
                                  activeIndex={index}
                                  onSelect={handleSelect}
                                  interval={null}
                                >
                                  {gallery.map((image, idx) => (
                                    <Carousel.Item key={idx}>
                                      <img
                                        className="d-block w-100"
                                        src={image}
                                        alt={`Slide ${idx}`}
                                      />
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClosegallery}
                                >
                                  Close
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </Container>
                        </div>
                      </Tab>

                      <Tab
                        eventKey="thingstodo"
                        title={
                          <span
                            onClick={() => handleTabClick("thingstodo")}
                            style={{
                              color:
                                activeTab === "thingstodo" ? "blue" : "black",
                            }}
                          >
                            <ModeOfTravelIcon /> Local Tours
                          </span>
                        }
                      >
                        <div className="local-tours">
                          <Container
                            style={{
                              height: "auto",
                              overflowX: "scroll",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Row className="flex-nowrap">
                              {places.map((place, index) => (
                                <Col
                                  key={index}
                                  style={{
                                    display: "inline-block",
                                    float: "none",
                                  }}
                                >
                                  <Card
                                    style={{
                                      width: "18rem",
                                      display: "inline-block",
                                      margin: "0 10px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Card.Img
                                      variant="top"
                                      src={place.imageUrl}
                                      style={{ height: "150px" }}
                                    />
                                    <Card.Body>
                                      <Card.Title
                                        style={{
                                          fontFamily: "Open Sans",
                                          animation: "fadeIn 1s",
                                          textShadow:
                                            "2px 2px 4px rgba(0, 0, 0, 0.3)",
                                        }}
                                      >
                                        {place.title}
                                      </Card.Title>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <ReactStars
                                          count={5}
                                          value={place.rating}
                                          size={24}
                                          activeColor="#ffd700"
                                          edit={false}
                                          color={"#fff59d"}
                                        />
                                        <a
                                          style={{
                                            cursor: "pointer",
                                            color: "orange",
                                            textDecoration: "underline",
                                          }}
                                          onClick={() => handleShowtours(index)}
                                        >
                                          {selectedPlaceIndex === index
                                            ? "Show Less "
                                            : "Explore More "}
                                        </a>
                                      </div>
                                      <div
                                        style={{
                                          maxHeight:
                                            selectedPlaceIndex === index
                                              ? "100px"
                                              : "0",
                                          overflow: "hidden",
                                          transition: "max-height 0.5s ease",
                                        }}
                                      >
                                        {selectedPlaceIndex === index && (
                                          <Card.Text
                                            style={{
                                              fontFamily: "Open Sans",
                                              marginTop: "10px",
                                              whiteSpace: "normal",
                                              textShadow:
                                                "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                            }}
                                          >
                                            {place.description} 📜
                                          </Card.Text>
                                        )}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          </Container>

                          <style jsx="true">{`
                            @keyframes fadeIn {
                              from {
                                opacity: 0;
                              }
                              to {
                                opacity: 1;
                              }
                            }
                          `}</style>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>

                  <Container className="mt-4">
                    <Row className="gx-3">
                      {apartments.map((apt, index) => (
                        <Col
                          key={index}
                          xs={12}
                          md={12}
                          lg={12}
                          className="mb-4"
                        >
                          <Card className="h-100">
                            <Row className="p-3">
                              <Col md={5}>
                                <Carousel>
                                  {apt.images.map((image, idx) => (
                                    <Carousel.Item key={idx} interval={10000}>
                                      <img
                                        className="d-block w-100"
                                        src={image}
                                        alt={`Slide ${idx}`}
                                        style={{
                                          height: "250px",
                                          position: "relative",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    </Carousel.Item>
                                  ))}
                                </Carousel>
                              </Col>
                              <Col md={7}>
                                <div className="d-flex flex-column h-100 justify-content-between">
                                  <div>
                                    <Typography
                                      variant="h5"
                                      style={{
                                        fontFamily: "Open Sans",
                                        fontSize: "21px",
                                        fontWeight: "500",
                                        marginTop: "8px",
                                      }}
                                    >
                                      {apt.name}
                                    </Typography>
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        marginTop: "5px",
                                        fontFamily: "Roboto",
                                        width: "auto",
                                      }}
                                    >
                                      {apt.description}
                                    </p>
                                    <div className="d-flex justify-content-center align-items-center mt-3">
                                      <div className="mt-2">
                                        <h5
                                          style={{
                                            color: "palevioletred",
                                            fontFamily: "Roboto",
                                            fontSize: "15px",
                                          }}
                                        >
                                          Apartment
                                        </h5>
                                        <p
                                          style={{
                                            fontFamily: "Roboto",
                                            fontSize: "15px",
                                          }}
                                        >
                                          {apt.discountedPrice}/night
                                        </p>
                                      </div>
                                      <div className="ms-auto">
                                        <Button
                                          variant="outlined"
                                          style={{
                                            backgroundColor: "orange",
                                            color: "black",
                                            // fontSize: "12px",
                                            width: "150px",
                                          }}
                                          className="add-btn"
                                          onClick={() =>
                                            window.open(
                                              "https://bookingengine.stayflexi.com/?hotel_id=29067",
                                              "_blank"
                                            )
                                          }
                                        >
                                          Book now
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </div>
              </div>

              {/* <div className="my-selection-card" style={{ height: sidebarHeight, marginTop: '20px' }}>
        <Card style={{ border: '1px solid #ccc', width: '270px' }}>
          <div style={{ padding: '20px', height: 'auto' }}>
            <Typography variant="h6" style={{ fontSize: '18px', textAlign: 'center', fontFamily: 'Open Sans', fontWeight: '500' }}>My Selection</Typography>
            <div>
              <div style={{ display: 'inline', gap: '100px', marginTop: '20px' }}>
                <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '17px' }}>Tour  Amount: {totalThingAmount} Rs</Typography>
                <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '17px' }}>Room  Amount: {totalRoomAmount} Rs</Typography>
              </div>
              <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '17px' }}>Total Amount: {totalAmount} Rs</Typography>
              <Button variant='danger' style={{ width: '220px', marginTop: '10px' }}>Book now</Button>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '17px' }}>Choose Your Room</Typography>
              <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '15px' }} color="gray"><CheckIcon />Instant Confirmation</Typography>
              <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '15px' }} color="gray"> <CheckIcon />No Booking Fees</Typography>
              <Typography variant="h6" style={{ fontFamily: 'Open Sans', fontSize: '15px' }} color="gray"> <CheckIcon />Booking Only Takes 2 Min</Typography>
            </div>
            <div style={{ width: '100%', justifyContent: 'space-around' }}>
              <img src="https://cdn-icons-png.flaticon.com/128/179/179457.png" style={{ width: '50px', height: 'auto' }} alt="icon" />
              <img src="https://cdn-icons-png.flaticon.com/128/11041/11041016.png" style={{ width: '50px', height: 'auto' }} alt="icon" />
              <img src="https://cdn-icons-png.flaticon.com/128/11041/11041055.png" style={{ width: '50px', height: 'auto' }} alt="icon" />
            </div>
          </div>
        </Card>
      </div> */}
            </div>
          </div>
        </div>
      </div>

      <FooterContainer />
    </section>
  );
};

export default Chennaiprabhusplace;
