import React from "react";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";
import { Typography } from "@mui/material";
import FooterContainer from "../../components/footer/FooterContainer";
import Marinabeach1 from "../../assets/chinnaaasets/marinabeach1.jpg";
import Marinabeach2 from "../../assets/chinnaaasets/Marinabeach2.jpg";
import Marinabeach3 from "../../assets/chinnaaasets/Marinabeach3.jpg";
import Kapaleeshwarar1 from "../../assets/chinnaaasets/kapaleeswarar1.jpg";
import Kapaleeshwarar2 from "../../assets/chinnaaasets/kapaleeswarar2.avif";
import Kapaleeshwarar3 from "../../assets/chinnaaasets/Kapaleeswarar3.jpg";
import museum from "../../assets/chinnaaasets/museum.jpg";
import museum2 from "../../assets/chinnaaasets/museum2.jpg";
import museum3 from "../../assets/chinnaaasets/museum3.jpg";
import museum4 from "../../assets/chinnaaasets/museum4.jpg";
import santhome1 from "../../assets/chinnaaasets/Santhome1.webp";
import santhome2 from "../../assets/chinnaaasets/santhome2.webp";
import santhome3 from "../../assets/chinnaaasets/santhome3.jpg";
import santhome4 from "../../assets/chinnaaasets/santhome4.webp";
const Chennaitour = () => {
  const places = [
    {
      title: "Marina Beach",
      description:
        "Marina Beach, or simply the Marina, is a natural urban beach in Chennai, Tamil Nadu, India, along the Bay of Bengal. The beach runs from near Fort St. George in the north to Foreshore Estate in the south, a distance of 6.0 km, making it the second longest urban beach in the world, after Coxs Bazar Beach.",
      imageUrl: [Marinabeach1, Marinabeach2, Marinabeach3],
      price: "500",
      location:
        "https://www.google.com/maps/place/Nandi+Hills,+Karnataka/@13.7863508,78.3621844,8z/data=!4m6!3m5!1s0x3bb1e445ebfcea17:0x1639f72959196608!8m2!3d13.370154!4d77.6834551!16zL20vMDloMDFo?entry=ttu",
    },
    {
      title: "Kapaleeshwarar Temple",
      description:
        " The Kapaleeshwarar Temple is a Hindu temple dedicated to the god Shiva located in Mylapore, Chennai in the Indian state of Tamil Nadu. The temple was built around the 7th century CE and is an example of South Indian Architecture.",
      imageUrl: [Kapaleeshwarar1, Kapaleeshwarar2, Kapaleeshwarar3],
      price: "600",
      location:
        " https://www.google.com/maps/place/Lalbagh+Botanical+Garden/@12.950743,77.584777,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1676ac832f75:0x23e9d5deaf7f39c0!8m2!3d12.950743!4d77.584777?entry=ttu",
    },
    {
      title: "Government Museum ",
      description:
        "Government Museum, Chennai is a multi-purpose State Government Museum located in Egmore, which is the heart of the city, spreading to an area of 16.25 acres of land. Museum has Sections like Archaeology, Numismatics, Art, Anthropology, Geology, Botany, Zoology and a Childrens Museum. The Galleries are organized in five buildings.",
      imageUrl: [museum, museum2, museum3, museum4],
      price: "700",
    },
    {
      title: "Santhome Cathedral Basilica",
      description:
        "San Thome Church, officially known as St Thomas Cathedral Basilica and National Shrine of Saint Thomas, is a minor basilica of the Catholic Church in India, at the Santhome neighbourhood of Chennai, in Tamil Nadu.",
      imageUrl: [santhome1, santhome2, santhome3, santhome4],
      price: "800",
      location:
        " https://www.google.com/maps/place/Mahatma+Gandhi+Road,+Bengaluru,+Karnataka/@12.9715987,77.5945627,12z/data=!3m1!4b1!4m5!3m4!1s0x3bae1673c80fef03:0x5b3954a808f5c9e7!8m2!3d12.976169!4d77.6017397?entry=ttu",
    },
    // { title: 'Isha Foundation', description: 'Inspired by Englands Windsor Castle, Bangalore Palace is a stunning example of Tudor style architecture. It was constructed in 1878 and features beautiful wooden carvings, lush green gardens, and royal artifacts that offer a glimpse into the regal lifestyle', imageUrl: [], price: '900' },
    // { title: 'Tipu Sultan Palace', description: 'This palace was the summer residence of Tipu Sultan and is an excellent example of Indo-Islamic architecture. Constructed entirely of teak wood, the palace is adorned with intricate carvings and paintings that depict the era of Tipu Sultans reign.', imageUrl: [], price: '1000', location: "https://www.google.com/maps/place/Tipu+Sultan's+Summer+Palace/@12.959315,77.573576,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1676e69f17d9:0x318d3d1e43245e71!8m2!3d12.959315!4d77.573576?entry=ttu" },
    // { title: 'Bike Ride And tour', description: ' This palace was the summer residence of Tipu Sultan and is an excellent example of Indo-Islamic architecture. Constructed entirely of teak wood, the palace is adorned with intricate carvings and paintings that depict the era of Tipu Sultans reign.', imageUrl: [], price: '400' },
    // { title: 'Iskon', description: 'The ISKCON Temple in Bengaluru is one of the largest ISKCON temples in the world. It is dedicated to Lord Krishna and features a blend of modern and traditional architecture. The temple complex includes various amenities, cultural exhibitions, and spiritual activities.', imageUrl: [], price: '300', location: "https://www.google.com/maps/place/ISKCON+Bangalore+-+Sri+Radha+Krishna+Temple/@13.0094072,77.5515616,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1675e9b92e97:0x5e4b89a1e0b7ec3f!8m2!3d13.0094072!4d77.5515616?entry=ttu" },
    // { title: 'UB City Mall', description: 'UB City Mall is one of Bengalurus most prestigious and luxurious shopping destinations. Located in the heart of the city, it offers a high-end shopping experience with a range of international brands, fine dining restaurants, and art galleries.', imageUrl: [], price: '200',location: " https://www.google.com/maps/place/UB+City/@12.9718915,77.5936703,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1673b2c5f2fb:0x176edcda123144e8!8m2!3d12.9718915!4d77.5936703?entry=ttu" },
  ];

  return (
    <>
      <div>
        <h3 style={{ marginLeft: "30px" }}>Chennai places to visit</h3>

        <Container className="mt-4">
          <Row className="gx-3">
            {places.map((place, index) => (
              <Col key={index} xs={12} md={12} lg={12} className="mb-4">
                <Card className="h-100">
                  <Row className="p-3">
                    <Col md={5}>
                      <Carousel>
                        {place.imageUrl.map((image, idx) => (
                          <Carousel.Item key={idx}>
                            <img
                              className="d-block w-100"
                              src={image}
                              alt={`Slide ${idx}`}
                              style={{
                                height: "250px",
                                width: "100px",
                                position: "relative",
                                borderRadius: "10px",
                              }}
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </Col>
                    <Col md={7}>
                      <div className="d-flex flex-column h-100 justify-content-between">
                        <div>
                          <Typography
                            variant="h5"
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "21px",
                              fontWeight: "500",
                              marginTop: "8px",
                            }}
                          >
                            {place.title}
                          </Typography>
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              fontFamily: "Roboto",
                              width: "auto",
                            }}
                          >
                            {place.description}
                          </p>
                          {/* <h5>Rs.{place.price}</h5> */}
                          <h5>
                            Rs.{place.price}
                            <span style={{ fontSize: "15px" }}>
                              /per person
                            </span>
                          </h5>

                          <a
                            style={{ width: "200px", marginTop: "10px" }}
                            className="location-btn"
                            onClick={() =>
                              window.open(place.location, "_blank")
                            }
                          >
                            See Location
                          </a>
                          <div className="d-flex justify-content-center align-items-center mt-3">
                            <div className="ms-auto">
                              <Button
                                style={{ width: "200px" }}
                                className="add-btn"
                                onClick={() =>
                                  window.open(
                                    "https://bookingengine.stayflexi.com/?hotel_id=29067",
                                    "_blank"
                                  )
                                }
                              >
                                Book tour
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <FooterContainer />
      </div>
    </>
  );
};

export default Chennaitour;
