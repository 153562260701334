import React from 'react'
import Footer from '../footer/FooterContainer'
import hostelsignup from '../../assets/hostelsignup.jpeg'
import './HostelSignUp.css'
import { Typography } from '@mui/material'
import { Button } from 'react-bootstrap'
import Rateing from '../Rateing/Rateing'
import FooterContainer from '../footer/FooterContainer'
// import Luggage_Room from '../../assets/Luggage_Room.png'
// import Towels from '../../assets/Towels.png'
const Hostelsignup = () => {
  return (
    <div>
        <div  className=' hostelSignUp-main'>
          <div className='hostelsignup-img'>
          <img src={hostelsignup} alt=''  style={{height:"500px", width:"87%",borderRadius:'20px',marginLeft:'70px'}}/>
          </div>
          <div className='overtext-hostelsignup'>
          <h1  className='overfont-hostelsignup' >A great administrator  <span style={{color:'deeppink'}}> turns dreams into reality</span> and plans into    action.</h1>
          <h6  className='overfont' >Your Peace of Mind, Our Priority.</h6>
          <button className='over-btn'>List Your Property</button>
  
          </div>
          


       
     

        </div>
        <Rateing />

      <FooterContainer/>
    </div>  
  )
}

export default Hostelsignup
