import React from "react";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";
import { Typography } from "@mui/material";
import FooterContainer from "../../components/footer/FooterContainer";
import boathouse from "../../assets/Ootyassets/boathouse.jpg";
import tea from "../../assets/Ootyassets/tea.webp";
import doddabetta from "../../assets/Ootyassets/doddabetta.jpg";
import kettiview from "../../assets/Ootyassets/kettiview.jpg";
import zoo from "../../assets/Ootyassets/zoo.jpg";
import golf from "../../assets/Ootyassets/golf.jpg";
import KamarajDam from "../../assets/Ootyassets/KamarajDam.jpg";
import pykarafalls from "../../assets/Ootyassets/pykarafalls.jpg";
import tea1 from "../../assets/Ootyassets/tea1.jpg";
import painforest from "../../assets/Ootyassets/painforest.jpeg";
import Hillpoint from "../../assets/Ootyassets/Hillpoint.webp";
import lake from "../../assets/Ootyassets/lake.jpg";
const Ootytour = () => {
  const places = [
    {
      title: "Ooty & Coonoor Tour",
      description:
        "Pick up from hotel or common area,Boat house,Tread Garden,Doddabetta Peak,Botanical Gardens,Ketti Valley View,Madras Regimental Center,DSSC Outer View,Sims Pak,Lambs Rock & Dolphins nose,Tea Garden,Coonoor Shoping Center,Catherine falls view ",
      imageUrl: [boathouse, doddabetta, kettiview, tea],
      price: "400",
      time: "9:00 AM-5:00 PM",
    },
    {
      title: "Pykara - Mudumalai",
      description:
        "Pick up from hotel  or common area,Golf club,HPF(Outer view),Pine Forest, Kamaraj Sagar Dam, Devils Down,9th mile shooting spot(Wenlock dowms),Pykara Dam(outer view),Pykara Water falls,Pykara Boat Hours,Mudumalai Wild Life Santuary (Jungle ride forest Dept vehicle extra charges)",
      imageUrl: [pykarafalls, KamarajDam, zoo, golf],
      price: "400",
      time: "9:00 AM-7:30 PM",
    },
    {
      title: "Filmy Chakkar",
      description:
        "Pick up from hotel or common area,Golf Club,HPF(Outer view),Pine Forest,Kamaraj Sagar Dam,Devils Down,9th mile shooting spot(Wenlock dowms),Pykara Dam(outer view),Pykara Water falls,Pykara Boat Hours",
      imageUrl: [painforest, lake, Hillpoint, tea1],
      price: "400",
      time: "9:00 AM-5:00 PM",
    },
  ];
  return (
    <div>
      <h3 style={{ marginLeft: "25px" }}>Ooty places to visit</h3>
      <Container className="mt-4">
        <Row className="gx-3">
          {places.map((place, index) => (
            <Col key={index} xs={12} md={12} lg={12} className="mb-4">
              <Card className="h-100">
                <Row className="p-3">
                  <Col md={5}>
                    <Carousel>
                      {place.imageUrl.map((image, idx) => (
                        <Carousel.Item key={idx}>
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${idx}`}
                            style={{
                              height: "250px",
                              position: "relative",
                              borderRadius: "10px",
                            }}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Col>
                  <Col md={7}>
                    <div className="d-flex flex-column h-100 justify-content-between">
                      <div>
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: "Open Sans",
                            fontSize: "21px",
                            fontWeight: "500",
                            marginTop: "8px",
                          }}
                        >
                          {place.title}
                        </Typography>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            fontFamily: "Roboto",
                            width: "auto",
                          }}
                        >
                          {place.description}
                        </p>
                        {/* <div style={{display:"flex"}}> */}
                        <div>
                          <h5
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                            }}
                          >
                            Rs.{place.price}
                            <span style={{ fontSize: "15px" }}>
                              /per person
                            </span>
                          </h5>
                          <h6
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                            }}
                          >
                            Timeing: {place.time}
                          </h6>
                          <h6
                            style={{
                              fontFamily: "Open Sans",
                              fontSize: "16px",
                            }}
                          >
                            Entrance charges are NOT included{" "}
                          </h6>
                        </div>
                        {/* <a
                            style={{ width: "200px", marginTop: '10px' }}
                            className="location-btn"
                            onClick={() => window.open(place.location, "_blank")}
                          >
                            See Location
                          </a> */}
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          <div className="ms-auto">
                            <Button
                              style={{ width: "200px" }}
                              className="add-btn"
                              onClick={() =>
                                window.open(
                                  "https://bookingengine.stayflexi.com/?hotel_id=29067",
                                  "_blank"
                                )
                              }
                            >
                              Book Tour
                            </Button>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <FooterContainer />
    </div>
  );
};

export default Ootytour;
