import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact/ContactContainer";
import News from "./Pages/Newspage/News";
import Ooty from "./Pages/Ootys/Ooty";
import Bengaluru from "./Pages/Bengaluru/Bengaluru";
import Chennai from "./Pages/Chennai/Chennai";
import NavbarHeader from "./components/Navbar/NavbarHeader";
import Bprabhu from "./Pages/Bengaluru/Bengaluruprabhuplace";
import Cprabhu from "./Pages/Chennai/Chennaiprabhusplace";
import TermsConditions from "./components/Terms&conditions/TermsContainer";
import Privacy from "./components/Privacy/PrivacyContainer";
import HostelSignUp from "./components/HostelSignup/Hostelsignup";
import Career from "./components/Career/CareerContainer";
import Aboutus from "./Pages/About/Aboutus";
import CareerContainer from "./components/Career/CareerContainer";
import PrivacyContainer from "./components/Privacy/PrivacyContainer";
import TermsContainer from "./components/Terms&conditions/TermsContainer";
import ContactContainer from "./Pages/Contact/ContactContainer";
import Bengalurutour from "./Pages/Bengaluru/Bengalurutour";
import Chennaitour from "./Pages/Chennai/Chennaitour";
import Ootytour from "./Pages/Ootys/Ootytour";
import Ootyprabhusplace from "./Pages/Ootys/Ootyprabhusplace";
import Chennaiprabhusplace from "./Pages/Chennai/Chennaiprabhusplace";
import Bengaluruprabhuplace from "./Pages/Bengaluru/Bengaluruprabhuplace";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <NavbarHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/News" element={<News />} />
          <Route path="/Ooty" element={<Ooty />} />
          <Route path="/Bengaluru" element={<Bengaluru />} />
          <Route path="/Chennai" element={<Chennai />} />
          <Route
            path="/Bengaluruprabhuplace"
            element={<Bengaluruprabhuplace />}
          />
          <Route
            path="/Chennaiprabhusplace"
            element={<Chennaiprabhusplace />}
          />
          <Route path="/Ootyprabhusplace" element={<Ootyprabhusplace />} />
          <Route path="/hostelsignup" element={<HostelSignUp />} />
          <Route path="/careers" element={<CareerContainer />} />
          <Route path="/privacy&policys" element={<PrivacyContainer />} />
          <Route path="/terms&conditions" element={<TermsContainer />} />
          <Route path="/contacts" element={<ContactContainer />} />
          <Route path="/Bengalurutour" element={<Bengalurutour />} />
          <Route path="/Chennaitour" element={<Chennaitour />} />
          <Route path="/Ootytour" element={<Ootytour />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
