import React, { useState, useEffect, useRef } from 'react';
import './termsmodule.css'
import { List, Typography } from '@mui/material';
import Termslist from './Termslist';
import Termsection from './Termsection';
import FooterContainer from '../footer/FooterContainer';

const sectionsData = [
  { id: 'Reservation Policy', title: 'Reservation Policy', content:'1.Pay in full amount when you book.' ,p: '2. Guests must be 18 years or older.',list:'3. No foreign national at goSTOPS Alibaug, goSTOPS Gurugram and goSTOPS Bengaluru HSR ', list1: ' We dont have the requisite permissions to host foreigners at these locations.',list2:'4. Rates can change based on availability and season.' ,list3:'5. GST applies to all purchases at goSTOPS.' ,list4:'6. Check your booking voucher to ensure that the date, rate, and room type match what you have booked.' ,list5: ' Choose the right dorm according to your gender: Mixed, Male Only, or Female Only.',list6:'7. Information about your visit to our website, such as pages viewed and links clicked',list7:'8.Private rooms accommodate only 2 people, extra beds are not available.',list8:'9.In order to enhance the safety of our guests, we have implemented industry first smartphone (IOS / Android) operable digital door locks at most locations (except Naggar, Srinagar, Rishikesh Tapovan, Mumbai and Leh), hence the guests are required to possess a smartphone (IOS / Android) while they stay at goSTOPS for accessing their respective room.'},

  { id: 'Cancellation&Changes', title: 'Cancellation&Changes', content: '1.Cancel or modify for free up to 15 days or 360 hours before the standard check-in time',  list: 'Standard check-in is at 1 PM time', list1:'2. No changes allowed after the cut-off date' ,list2:'3. Email at hello@gostops.com to cancel or modify your booking.' ,list3: ' Refunds are processed immediately but might take upto 15 days to reach your bank',list4:'4. No-Shows are charged 100% of the reservation.',list5:'5.No refunds for early departures.' },
  { id: 'Check-In & Check-Out', title: 'Check-In & Check-Out', content: '1.Check-in: 1 PM onwards . Early check-in is free but subject to availability.',list1:'Early check-in availability can only be confirmed on the day of check-in. We cannot confirm early check-in in advance. Please inquire when you arrive at the property.', list2:'PLEASE NOTE: Early Check-in available only after 7AM. For checking-in earlier, you have to book the previous night.'},
  { id: 'Thir', title: 'Third-Party Links', content: 'Our website may contain links to third-party websites that are not under our control. We are not responsible for the privacy practices or content of these websites.' },


];


const TermsContainer = () => {
  const [activeSection, setActiveSection] = useState(sectionsData[0].id);
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
        <Typography variant='h4' style={{marginLeft:'90px',backgroundColor:'transparent',fontFamily:'Open Sans',fontWeight:'700', position:"sticky",height:'40px',width:'fit-content'}}>Terms & conditions</Typography>
        <div className="terms-main-container">
      <div className="terms-list-container">
        <Termslist  sections={sectionsData} activeSection={activeSection} />
      </div>
      <div className="terms-sections-container">
        {sectionsData.map((section, index) => (
          <Termsection
            key={section.id}
            section={section}
            ref={(el) => (sectionRefs.current[index] = el)}
          />
        ))}
      </div>
    </div>
      <FooterContainer />
    </>
  )
}

export default TermsContainer



