import React from 'react';

const Termslist = ({ sections, activeSection }) => {
  return (
    <ul className="privacy-list-group">
      {sections.map(section => (
        <li
          key={section.id}
          className={`privacy-list-group-item ${activeSection === section.id ? 'active' : ''}`}
        >
          {/* {section.title} */}
          <a href={`#${section.id}`}>{section.title}</a>

        </li>
      ))}
    </ul>
  );
};

export default Termslist;
