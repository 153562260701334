import React, { useState } from "react";
import "./About.css";
import { Card } from "@mui/material";
import { Typography } from "@mui/material";
import boathouse from "../../assets/Ootyassets/boathouse.jpg";
import dorm3 from "../../assets/Ootyassets/dorm3.webp";
import icon_hotels from "../../assets/icon_hotels.png";
import icon_value from "../../assets/icon_value.png";
import icon_hotel from "../../assets/icon_hotel.png";

import beach from "../../assets/beach.mp4";
import { Accordion } from "react-bootstrap";
import FooterContainer from "../../components/footer/FooterContainer";

const Aboutus = () => {
  const images = [
    "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    // "https://assets.vogue.in/photos/5f080a5b90d963fb71a4c194/2:3/w_2560%2Cc_limit/Ahilya%2520by%2520the%2520Sea%2520Exterior.jpg",
    dorm3,
    "https://t4.ftcdn.net/jpg/06/25/24/13/360_F_625241335_M22gR0lrBLJp0GwD5xEWgUrZ0QjyrMBy.jpg",
    //  'https://static.readytotrip.com/upload/information_system_24/4/0/9/item_4092125/photo_86648810.jpg',
    boathouse,
    "https://assets.traveltriangle.com/blog/wp-content/uploads/2019/01/places-to-visit-with-friends-new-cover.jpg",
    "https://www.azcentral.com/gcdn/-mm-/1d222a80f73d198dec3d4890315f5337aedb391a/c=0-196-3495-2171/local/-/media/2016/06/10/Phoenix/Phoenix/636011686454381077-ThinkstockPhotos-166085684.jpg?width=660&height=373&fit=crop&format=pjpg&auto=webp",
  ];

  return (
    <>
      <div className="about-heading">
        <video src={beach} autoPlay loop muted>
          {" "}
        </video>
        <Typography className="about-text" variant="h2">
          Meet The World
        </Typography>
        <p className="about-para">
          Prabhu's Place OOty is an Organic Farm and Stay in the center of city
          surrounded by farm. This is a colonial old farm house that maintains
          the old look with comfortable beds and good ambiance. Wake up to the
          smell of fresh vegetable being harvested daily and fresh brewed
          coffee. Along with the sound of church bells.
        </p>
      </div>
      <div className="main-About">
        <h1>The main benefits to choose Prabhu's Place</h1>
      </div>

      <div className="cards-container">
        <div className="single-card">
          <Card>
            <h1>15+</h1>
            <p style={{ fontWeight: "400", fontFamily: "Roboto" }}>
              Over 15 hostels india, each offering a unique experience and
              vibrant atmosphere for travelers. Find your perfect stay with us.
            </p>
          </Card>
        </div>
        <div className="single-card">
          <Card>
            <h1>200+</h1>
            <p style={{ fontWeight: "400", fontFamily: "Roboto" }}>
              Join over 20,000 satisfied travelers who have enjoyed our
              top-rated services, comfort, and affordability across multiple
              locations.
            </p>
          </Card>
        </div>
        <div className="single-card">
          <Card>
            <h1>100+</h1>
            <p style={{ fontWeight: "400", fontFamily: "Roboto" }}>
              Over 100 activities and cultural experiences curated just for you.
              Immerse yourself in local cultures and make lasting memories.
            </p>
          </Card>
        </div>
      </div>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div key={index} className={`gallery-item gallery-item-${index % 2}`}>
            <img src={image} alt={`Gallery ${index}`} />
          </div>
        ))}
      </div>
      <div className="question-main">
        <h1 style={{ fontFamily: "Open Sans" }}>
          Frequently Asked Questions About Hostels
        </h1>

        <div className="question-container">
          <div className="sub-container">
            <Accordion
              style={{
                width: "500px",
                fontFamily: "Open Sans",
                fontWeight: "100",
              }}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How do you choose the right hostel?
                </Accordion.Header>
                <Accordion.Body
                  style={{ fontFamily: "Open Sans", fontWeight: "400" }}
                >
                  Choosing the right hostel depends on your preferences.
                  Consider factors like location, price, amenities, and the vibe
                  of the place to ensure it fits your travel style.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Are there private rooms in hostels?
                </Accordion.Header>
                <Accordion.Body
                  style={{ fontFamily: "Open Sans", fontWeight: "400" }}
                >
                  Yes, many hostels offer private rooms along with dormitories.
                  They are great for those who want the hostel experience with
                  added privacy.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="sub-container">
            <Accordion
              style={{
                width: "500px",
                fontFamily: "Open Sans",
                fontSize: "18px",
              }}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What amenities do hostels provide?
                </Accordion.Header>
                <Accordion.Body
                  style={{ fontFamily: "Open Sans", fontWeight: "400" }}
                >
                  Hostels typically offer basic amenities like Wi-Fi, common
                  areas, kitchens, and sometimes free breakfast. Some even offer
                  tours, events, and laundry facilities.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Is staying in a hostel safe?
                </Accordion.Header>
                <Accordion.Body
                  style={{ fontFamily: "Open Sans", fontWeight: "400" }}
                >
                  Yes, staying in a hostel can be safe if you choose reputable
                  places with good reviews. Many hostels have security measures
                  like lockers, CCTV, and secure entrances.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>

      <div className="cards-container">
        {[
          {
            image: icon_hotels,
            title: "Hostel Territory",
            description:
              "Explore our diverse range of hostel locations, each offering unique experiences tailored to your needs.",
          },
          {
            image: icon_value,
            title: "Hostel Presence",
            description:
              "Experience our extensive network of hostels, ensuring you find the perfect stay wherever you go.",
          },
          {
            image: icon_hotel,
            title: "Hostel Accommodation",
            description:
              "Enjoy comfortable and affordable accommodation options designed for travelers of all types.",
          },
        ].map((item, index) => (
          <div className="single-card" key={index}>
            <Card className="card-content">
              <img src={item.image} alt={item.title} className="card-icon" />
              <Typography variant="h5" className="card-title">
                {item.title}
              </Typography>
              <Typography variant="body1" className="card-description">
                {item.description}
              </Typography>
            </Card>
          </div>
        ))}
      </div>

      <FooterContainer />
    </>
  );
};

export default Aboutus;
