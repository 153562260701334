import React, { useState, useEffect, useRef } from 'react';
import './Privacy.css'
import { Typography } from '@mui/material';
import FooterContainer from '../footer/FooterContainer';
import ListContainer from './ListContainer';
import SectionContainer from './SectionContainer';


const sectionsData = [
  { id: 'Information We Collect', title: 'Information We Collect', content: 'Website and goSTOPS App',p: 'When you interact with our website or use the goSTOPS app, we may collect the following personal information:',list:'1. Name ', list1:'2. Email' ,list2:'3. Phone Number' ,list3:'4. Address' ,list4:'5. Payment Information' ,list5:'This information may be collected through various interactions, such as making a reservation, signing up for our newsletter, or contacting us.',list6:'6. Information about your visit to our website, such as pages viewed and links clicked'},
  { id: 'Sharing Personal Information', title: 'Sharing Personal Information', content: 'We may share your personal information collected through our website and goSTOPS with third parties for these purposes:',  list:'1. To process your reservation ', list1:'2. To provide customer service and support' ,list2:'3. To provide customer service and suppor' ,list3:'4. To comply with legal requirements',list4:'5. To protect our rights and property',p1: 'Regarding the goCREW app, we strictly limit the sharing of personal information to what is necessary for the apps functionality. The information collected through the goCREW app is processed at goSTOPS, and we ensure it is handled with the highest level of security and confidentiality.' },
  { id: 'Security', title: 'Security', content: 'We take reasonable steps to protect the personal information we collect from unauthorised access, disclosure, and misuse. However, no security measures are 100% effective, and we cannot guarantee the security of your personal information.', },
  { id: 'Third-Party Links', title: 'Third-Party Links', content: 'Our website may contain links to third-party websites that are not under our control. We are not responsible for the privacy practices or content of these websites.' },
  { id: 'Childrens Privacy', title: 'Childrens Privacy', content: 'Our website is not intended for children under the age of 18, and we do not knowingly collect personal information from children under the age of 18.' },
  { id: 'Changes to this Privacy Policy', title: 'Changes to this Privacy Policy', content: 'We may update this Privacy Policy from time to time by posting a new version on our website. You should check this page periodically to ensure you are aware of any changes.' },
  { id: 'Contact Us', title: 'Contact Us', content: 'If you have any questions about this Privacy Policy, please contact us at Prabhus place.com .' },




];

function PrivacyContainer() {
  const [activeSection, setActiveSection] = useState(sectionsData[0].id);
  const sectionRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
    <Typography variant='h4' style={{marginLeft:'100px',backgroundColor:'transparent',fontFamily:'Open Sans',fontWeight:'700', position:"sticky"}}>Privacy Policy</Typography>

    <div className="privacy-main-container">
      <div className="privacy-list-container">
        <ListContainer  sections={sectionsData} activeSection={activeSection} />
      </div>
      <div className="privacy-sections-container">
        {sectionsData.map((section, index) => (
          <SectionContainer
            key={section.id}
            section={section}
            ref={(el) => (sectionRefs.current[index] = el)}
          />
        ))}
      </div>
    </div>
    <FooterContainer/>
    </>

  );
}

export default PrivacyContainer;
