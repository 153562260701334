import React, { useState } from 'react';
import './Ooty.css';
import { Carousel, Tab, Tabs } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Button, Card } from '@mui/material';

import { Link } from 'react-router-dom';
import ooty1 from '../../assets/Ootyassets/ooty1.jpg'
import ootyWeather from '../../assets/Ootyassets/ootyWeather.jpg'
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import dorm4 from '../../assets/Ootyassets/dorm4.jpg'
import dorm5 from '../../assets/Ootyassets/dorm5.webp'
import dorm6 from '../../assets/Ootyassets/dorm6.jpg'
import dorm9 from '../../assets/Ootyassets/dorm9.jpg'
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the desired locale
import { Card as BootstrapCard } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import temple from '../../assets/chinnaaasets/chpics/temple.jpg'
import tea from '../../assets/chinnaaasets/chpics/tea.webp'
import train from '../../assets/chinnaaasets/chpics/train.jpg'
import rosegarden from '../../assets/chinnaaasets/chpics/rosegarden.jpg'
import ootygarden from '../../assets/chinnaaasets/chpics/ootygarden.jpg'
import falls from '../../assets/chinnaaasets/chpics/falls.jpg'
import ootylake from '../../assets/chinnaaasets/chpics/ootylake.jpg'
import church from '../../assets/chinnaaasets/chpics/church.jpg'
import Doddabettateles from '../../assets/chinnaaasets/chpics/Doddabettateles.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Col, Row } from "react-bootstrap";
import { Popover } from "@mui/material";

import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import FooterContainer from '../../components/footer/FooterContainer';

const cities = [
  { label: "Bengaluru" },
  { label: "Ooty" },
  { label: "Chennai" },

];

const GOOGLE_MAPS_API_KEY = 'AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };


const Ootys = ({ ...props }) => {
  const [selectedDates, setSelectedDates] = useState([dayjs(), dayjs().add(1, 'day')]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState({ label: '1 Guest', value: 1 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [cityError, setCityError] = useState(false);



  
  const navigate = useNavigate();

  const handleSearch = () => {
    if (selectedCity) {
      navigate(`/${selectedCity.label.toLowerCase()}`);
    } else {
      setCityError(true);
    }  };

  const handleGuestChange = (increment) => {
    setSelectedGuest((prevGuest) => {
      let newGuestCount = prevGuest.value + increment;
      newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
      return { label: `${newGuestCount} Guest${newGuestCount > 1 ? 's' : ''}`, value: newGuestCount };
    });
  };

  const handleGuestClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGuestClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'guest-popover' : undefined;

  const [showCards, setShowCards] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
 
  const toggleCards = () => setShowCards(!showCards);
 
  const slideLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
 
  const slideRight = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, bangaloreImgs.length - 1));
  };

  const bangaloreImgs = [
    { title: 'Botanical Garden ', description: ' Botanical Gardens, established in 1847, are spread over 55 acres and showcase a wide variety of plants, trees, and flowers. ', image: ootygarden },
    { title: 'Ooty Lake', description: 'Ooty Lake is an artificial lake surrounded by eucalyptus trees and greenery. Its a popular spot for boating and picnics. ', image: ootylake },
    { title: 'Pykara Lake and Falls', description: 'Pykara Lake is a scenic lake located about 20 km from Ooty, known for its tranquil surroundings.', image: falls },
    { title: 'Nilgiri Mountain Railway', description: 'The Nilgiri Mountain Railway, also known as the Ooty Toy Train, is a UNESCO World Heritage Site.  ', image: train },


    { title: 'Elk Hill Murugan Temple', description: 'The Elk Hill Murugan Temple is dedicated to Lord Murugan and offers a peaceful atmosphere and panoramic views of Ooty.', image: temple },
    { title: 'st. stephen church ooty', description: 'St. Stephens Church, built in 1829, is one of the oldest churches in the Nilgiris.', image: church },
    { title: 'Ooty Tea Factory,Museum', description: 'The Tea Factory and Museum offers an insight into the tea-making process. ', image: tea },
    { title: 'Rose Garden', description: 'The Ooty Rose Garden, also known as the Jayalalithaa Rose Garden, is one of the largest rose gardens in India.', image: rosegarden },
    { title: 'Doddabetta Peak', description: 'Doddabetta Peak is the highest point in the Nilgiri Mountains, standing at 2,637 meters above sea level. ', image: Doddabettateles },


  ]
    ;
  const
    [activeTab, setActiveTab] =
      useState
        (
          "weather"
        );
  // State to track the active tab
  const
    handleTabClick
      = (tabKey) => {
        setActiveTab
          (tabKey);
      };
  const [showLess, setshowLess] = useState(true);


  const str = "One of the main attractions in Ooty is the Ooty Lake, a tranquil spot perfect for boating and picnics. The Botanical Gardens, established in 1847, showcase an impressive array of exotic and indigenous plants, including a 20-million-year-old fossilized tree. The Nilgiri Mountain Railway, a UNESCO World Heritage Site, offers a scenic train ride through lush hills and tea estates, providing breathtaking views and an unforgettable experience.Ooty's delightful blend of natural beauty, cultural heritage, and outdoor activities make it a must-visit destination for travelers seeking a refreshing escape from the hustle and bustle of city life. Whether you're exploring the vibrant local markets, sipping on freshly brewed tea, or hiking through its scenic trails, Ooty promises an enchanting and rejuvenating experience."
  var resultStr = showLess ? str.slice(0, 500) : str;

  const changeShow = () => {
    setshowLess(!showLess);
  };

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }



  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll(!showAll);
  };


  return (
    <section>
      <div >
        <div className='ooty-main'>
          <img src={ooty1} ></img>
          <div className='ooty-overlay-text'>
            <h1 className='ooty-overlay-font' >Ooty</h1>
          </div>
        </div>

        
         <div className="responsive-wrapper">
      <Card id="search-card-grid" className="responsive-card">
        <Row className="align-items-center" style={{ padding: '23px' }}>
          <Col className="d-flex align-items-center" xs="auto">
            <Autocomplete
              id="city-search"
              options={cities}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Where do you want to go"
                  variant="outlined"
                  fullWidth
                  style={{ minWidth: 250, marginBottom: 0 }}
                  error={cityError}helperText={cityError ? "City is required" : ""}
               
                  />
              )}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
                setCityError(false); // Reset error when city is selected

              }}
              value={selectedCity}
            />
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DateRangePicker
                localeText={{ start: 'Check-in', end: 'Check-out' }}
                value={selectedDates}
                onChange={(newValue) => {
                  setSelectedDates(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 150 }}
                    />
                    <TextField
                      {...endProps}
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 150 }}
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Select Guests"
                variant="outlined"
                fullWidth
                value={selectedGuest.label}
                onClick={handleGuestClick}
                style={{ minWidth: 150, marginBottom: 0 }}
                readOnly
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleGuestClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button variant="outline-secondary" onClick={() => handleGuestChange(-1)}>-</Button>
                  <span style={{ margin: '0 10px' }}>{selectedGuest.value}</span>
                  <Button variant="outline-secondary" onClick={() => handleGuestChange(1)}>+</Button>
                </div>
              </Popover>
            </div>
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <Button variant='primary' style={{ backgroundColor: 'blue', color: 'white' }} onClick={handleSearch}>
              Let's Go
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
        <div className='ooty-section'>
          <div className='ooty-contnt'>
           <div className='container'>

            <Typography variant='h4' style={{ fontFamily: "Open Sans", fontSize: '30px', fontWeight: '500',width:'auto' }}>Ooty in India</Typography>
            <p  className='row justify-content-center ooty-main-paragraph'>
              Ooty, known as the "Queen of Hill Stations," is a beautiful hill town in Tamil Nadu, India. Nestled in the Nilgiri Hills, it offers lush tea gardens, serene lakes, and a cool, refreshing climate. Major attractions include the picturesque Ooty Lake, the verdant Botanical Gardens, and the scenic Nilgiri Mountain Railway. Ooty's charming landscapes and tranquil ambiance make it a perfect getaway for nature lovers and adventure seekers. This enchanting destination promises a rejuvenating escape from urban life. </p>
          </div>
          </div>

          <div>
      {!showCards && (
        <div className="d-flex justify-content-center">
          <Button variant="primary" style={{ textAlign: 'center', color: "palevioletred" }} onClick={toggleCards}>
            Read More <KeyboardArrowDownIcon />
          </Button>
        </div>
      )}
 
      {showCards && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant='h4' style={{ textAlign: 'center', marginBottom: "20px", fontSize: '30px', fontWeight: '500', fontFamily: 'Open Sans' }}>
            Reasons to visit Bengaluru
          </Typography>
 
          <div className='d-flex justify-content-center align-items-center' style={{ position: 'relative', width: '100%', overflow: 'hidden', overflowX: 'scroll' }}>
            {currentIndex > 0 && (
              <Button
                variant="text"
                onClick={slideLeft}
                style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'palevioletred' }}
              >
                <ArrowBackIosIcon style={{ color: 'black', fontSize: '30px', boxShadow: 'none' }} />
              </Button>
            )}
 
            <div className='explore-cards'>
              {bangaloreImgs.slice(currentIndex, currentIndex + 4).map((card, index) => (
                <div key={index} style={{ display: 'inline-block', padding: '10px' }}>
                  <BootstrapCard style={{ width: '18rem', height: 'auto' }}>
                    <BootstrapCard.Img variant="top" src={card.image} alt={`Image for ${card.title}`} style={{ height: '200px', objectFit: 'cover' }} />
                    <BootstrapCard.Body>
                      <BootstrapCard.Title>{card.title}</BootstrapCard.Title>
                      <BootstrapCard.Text className='ooty-desctext'>{card.description}</BootstrapCard.Text>
                    </BootstrapCard.Body>
                  </BootstrapCard>
                </div>
              ))}
            </div>
 
            {currentIndex < bangaloreImgs.length - 4 && (
              <Button
                variant="text"
                onClick={slideRight}
                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'palevioletred' }}
              >
                <ArrowForwardIosIcon style={{ color: 'black', fontSize: '30px', boxShadow: 'none' }} />
              </Button>
            )}
          </div>
 
          <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
            <Button variant="primary" style={{ marginTop: '50px', textAlign: 'center', color: "palevioletred" }} onClick={toggleCards}>
              Read Less <KeyboardArrowUpIcon />
            </Button>
          </div>
        </div>
      )}
    </div>
          
<div className='container ooty-containers'>
      <div className='row justify-content-center' style={{ marginTop: '40px' }}>
        <div>
          <Link to="/Oprabhu" style={{ textDecoration: 'none' }}>
            <Card className='cardlist'>
              <div className='card-div row'>
                <div className='col-md-6'>
                  <Carousel style={{ borderRadius: '10px' }}>
                    <Carousel.Item interval={3000} style={{ borderRadius: '10px' }}>
                      <img src={dorm4} text="First slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={dorm5} text="Second slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={dorm9} text="Third slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={dorm6} text="Fourth slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div className='col-md-6'>
                  <div className='ooty-room-details'>

                  <Typography variant='h5' style={{ fontFamily: 'Open Sans', fontWeight: '500', fontSize: '23px' }}>Prabhu's Place in Ooty</Typography>
                  <p style={{ fontSize: '16px', marginTop: '10px', fontFamily: 'Roboto' }}>
                    Fully furnished 2 bed 2 bath apartment with cooking amenities including a microwave oven, Fridge, utensils, a washer and a space to dry your clothes in the balcony.
                  </p>
                  </div>

                  <div className='ooty-cards'>
                    <div style={{ display: 'flex', gap: '50px', marginTop: '50px' }}>
                      <div>
                        <h5 style={{ marginTop: '5px', color: 'palevioletred', fontFamily: 'Roboto' }}>Apartment</h5>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <h6 style={{ fontFamily: 'Roboto', fontSize: '15px' }}><del>7500</del>/night</h6>
                          <h6 style={{ fontFamily: 'Roboto', fontSize: '15px' }}>6500/night</h6>
                        </div>
                      </div>
                      <div>
                        <h5 style={{ marginTop: '5px', color: 'palevioletred', fontFamily: 'Roboto' }}>Dorms</h5>
                        <h6 style={{ fontFamily: 'Roboto', fontSize: '15px' }}>Available</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='ootytabs-content'>
          <Tabs activeKey={activeTab} onSelect={handleTabClick} className="mb-3">
            <Tab eventKey="weather" title={
              <span style={{ color: activeTab === 'weather' ? 'blue' : 'black' }}>
                <ThunderstormIcon /> Weather
              </span>
            }>
              <Typography variant='h6' style={{ fontWeight: '400', fontFamily: 'Open Sans' }}>Weather in Ooty</Typography>
              <img className='tabs-weather' src={ootyWeather} style={{ width: '100%', height: 'auto' }} />
            </Tab>
            <Tab eventKey="location" title={
              <span style={{ color: activeTab === 'location' ? 'blue' : 'black' }}>
                <FmdGoodIcon /> Location
              </span>
            }>
              <Typography variant='h6' style={{ fontWeight: '400', fontFamily: 'Open Sans' }}>Ooty Location</Typography>
              <iframe
                width="100%"
                height="auto"
                className='tabs-location'
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                style={{ width: '100%', height: 'auto' }}
                src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=ooty,Thamil%20nadu+(Pradhus%20place)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
        </div>
      </div>
      <FooterContainer />

    </section>
  )
}

export default Ootys