import React from 'react'
import './Home.css'
import Header from '../../components/Header/Header'
import ExploreRoom from '../../components/ExploreRoom/ExploreRoom'

import Footer from '../../components/footer/FooterContainer'
import FooterContainer from '../../components/footer/FooterContainer'


const Home = () => {
  return (
    <div>
        {/* <Imageslider/>  */}
        <Header/>
        {/* <ExploreRoom/> */}
        {/* <Rateing/> */}
        <FooterContainer/>
    </div>
  )
}

export default Home