// import React from 'react'

// const Bengalurutour = () => {
//   return (
//     <> <div>Bengalurutour</div>
//     <h1>hiii</h1>
//     </>

//   )
// }

// export default Bengalurutour
import React from "react";
import nightlife from "../../assets/bangassets/nightlife.jpg";
import iskon from "../../assets/bangassets/iskon.webp";
import tippupalace from "../../assets/bangassets/tippupalace.jpg";
import palace from "../../assets/bangassets/palace.jpg";
import lalbag1 from "../../assets/bangassets/lalbag1.jpg";
import bikeride from "../../assets/bangassets/bikeride.jpg";
import ubcitymall from "../../assets/bangassets/ubcitymall.webp";
import nightlife1 from "../../assets/bangassets/nightlife1.webp";
import NandiHills from "../../assets/bangassets/NandiHills.webp";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";
import { Typography } from "@mui/material";
import FooterContainer from "../../components/footer/FooterContainer";
import nadihills2 from "../../assets/bangassets/nadihills2.jpg";
import nadihills1 from "../../assets/bangassets/nadihills1.jpg";
import nadihills3 from "../../assets/bangassets/nadihills3.webp";
import nadihills4 from "../../assets/bangassets/nadihills4.jpg";
import nadihills5 from "../../assets/bangassets/nadihills5.jpg";
import nadihills6 from "../../assets/bangassets/nadihills6.jpg";
import lalag2 from "../../assets/bangassets/lalbag2.webp";
import lalag3 from "../../assets/bangassets/lalbag3.webp";
import lalag4 from "../../assets/bangassets/lalbag4.jpg";
import mgroad1 from "../../assets/bangassets/mgroad1.avif";
import mgroad2 from "../../assets/bangassets/mgroad2.jpg";
import mgroad3 from "../../assets/bangassets/mgroad3.webp";
import Palace1 from "../../assets/bangassets/Palace1.jpg";
import Palace2 from "../../assets/bangassets/Palace2.jpg";
import palace3 from "../../assets/bangassets/Palace3.avif";
import palace4 from "../../assets/bangassets/Palace4.webp";
import iskon1 from "../../assets/bangassets/iskon1.jpg";
import iskon2 from "../../assets/bangassets/Iskon2.jpg";
import iskon3 from "../../assets/bangassets/iskon3.jpg";
import isha1 from "../../assets/bangassets/isha1.jpg";
import isha2 from "../../assets/bangassets/isha2.avif";
import isha3 from "../../assets/bangassets/isha3.jpg";
import isha4 from "../../assets/bangassets/isha4.png";
const Bengalurutour = () => {
  const places = [
    {
      title: "Nandi Hills",
      description:
        "Nandi Hills is a popular weekend getaway located about 60 kilometers from Bengaluru. It is known for its scenic beauty, trekking trails, and historical significance. The hill station offers panoramic views, cool weather, and is a perfect spot for adventure activities and relaxation.",
      imageUrl: [
        NandiHills,
        nadihills1,
        nadihills2,
        nadihills3,
        nadihills4,
        nadihills5,
        nadihills6,
      ],
      price: "500",
      location:
        "https://www.google.com/maps/place/Nandi+Hills,+Karnataka/@13.7863508,78.3621844,8z/data=!4m6!3m5!1s0x3bb1e445ebfcea17:0x1639f72959196608!8m2!3d13.370154!4d77.6834551!16zL20vMDloMDFo?entry=ttu",
    },
    {
      title: "Botanical garden",
      description:
        "Lalbagh is a renowned botanical garden established during the 18th century by Hyder Ali and later expanded by his son, Tipu Sultan. It is known for its diverse collection of plants, glasshouse inspired by Londons Crystal Palace, and the annual flower shows that attract numerous visitors.",
      imageUrl: [lalbag1, lalag2, lalag3, lalag4],
      price: "600",
      location:
        " https://www.google.com/maps/place/Lalbagh+Botanical+Garden/@12.950743,77.584777,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1676ac832f75:0x23e9d5deaf7f39c0!8m2!3d12.950743!4d77.584777?entry=ttu",
    },
    // { title: 'NightLife', description: 'onic streets are at the heart of Bengalurus nightlife, lined with numerous pubs, bars, and clubs. Popular spots include Pecos, The 13th Floor, and The Biere Club, where you can enjoy live music, DJ nights, and a diverse range of beverages.', imageUrl: [nightlife], price: '700' },
    {
      title: "MG Road",
      description:
        "Mahatma Gandhi Road, commonly known as MG Road, is one of the most prominent shopping and commercial hubs in Bengaluru. It is lined with a variety of shops, restaurants, and entertainment options, making it a favorite spot for both locals and tourists.hese are among the most popular shopping and entertainment hubs in Bengaluru.",
      imageUrl: [nightlife1, mgroad1, mgroad2, mgroad3],
      price: "800",
      location:
        " https://www.google.com/maps/place/Mahatma+Gandhi+Road,+Bengaluru,+Karnataka/@12.9715987,77.5945627,12z/data=!3m1!4b1!4m5!3m4!1s0x3bae1673c80fef03:0x5b3954a808f5c9e7!8m2!3d12.976169!4d77.6017397?entry=ttu",
    },
    {
      title: "Isha Foundation",
      description:
        "Inspired by Englands Windsor Castle, Bangalore Palace is a stunning example of Tudor style architecture. It was constructed in 1878 and features beautiful wooden carvings, lush green gardens, and royal artifacts that offer a glimpse into the regal lifestyle",
      imageUrl: [isha1, isha2, isha3, isha4],
      price: "900",
    },
    {
      title: "Tipu Sultan Palace",
      description:
        "This palace was the summer residence of Tipu Sultan and is an excellent example of Indo-Islamic architecture. Constructed entirely of teak wood, the palace is adorned with intricate carvings and paintings that depict the era of Tipu Sultans reign.",
      imageUrl: [tippupalace, Palace1, Palace2, palace3, palace4],
      price: "1000",
      location:
        "https://www.google.com/maps/place/Tipu+Sultan's+Summer+Palace/@12.959315,77.573576,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1676e69f17d9:0x318d3d1e43245e71!8m2!3d12.959315!4d77.573576?entry=ttu",
    },
    {
      title: "Bike Ride And tour",
      description:
        " This palace was the summer residence of Tipu Sultan and is an excellent example of Indo-Islamic architecture. Constructed entirely of teak wood, the palace is adorned with intricate carvings and paintings that depict the era of Tipu Sultans reign.",
      imageUrl: [bikeride],
      price: "400",
    },
    {
      title: "Iskon",
      description:
        "The ISKCON Temple in Bengaluru is one of the largest ISKCON temples in the world. It is dedicated to Lord Krishna and features a blend of modern and traditional architecture. The temple complex includes various amenities, cultural exhibitions, and spiritual activities.",
      imageUrl: [iskon, iskon1, iskon2, iskon3],
      price: "300",
      location:
        "https://www.google.com/maps/place/ISKCON+Bangalore+-+Sri+Radha+Krishna+Temple/@13.0094072,77.5515616,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1675e9b92e97:0x5e4b89a1e0b7ec3f!8m2!3d13.0094072!4d77.5515616?entry=ttu",
    },
    {
      title: "UB City Mall",
      description:
        "UB City Mall is one of Bengalurus most prestigious and luxurious shopping destinations. Located in the heart of the city, it offers a high-end shopping experience with a range of international brands, fine dining restaurants, and art galleries.",
      imageUrl: [ubcitymall],
      price: "200",
      location:
        " https://www.google.com/maps/place/UB+City/@12.9718915,77.5936703,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae1673b2c5f2fb:0x176edcda123144e8!8m2!3d12.9718915!4d77.5936703?entry=ttu",
    },
  ];

  return (
    <div>
      <h3 style={{ marginLeft: "30px" }}>bangalore places to visit</h3>

      <Container className="mt-4">
        <Row className="gx-3">
          {places.map((place, index) => (
            <Col key={index} xs={12} md={12} lg={12} className="mb-4">
              <Card className="h-100">
                <Row className="p-3">
                  <Col md={5}>
                    <Carousel>
                      {place.imageUrl.map((image, idx) => (
                        <Carousel.Item key={idx}>
                          <img
                            className="d-block w-100"
                            src={image}
                            alt={`Slide ${idx}`}
                            style={{
                              height: "250px",
                              position: "relative",
                              borderRadius: "10px",
                            }}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </Col>
                  <Col md={7}>
                    <div className="d-flex flex-column h-100 justify-content-between">
                      <div>
                        <Typography
                          variant="h5"
                          style={{
                            fontFamily: "Open Sans",
                            fontSize: "21px",
                            fontWeight: "500",
                            marginTop: "8px",
                          }}
                        >
                          {place.title}
                        </Typography>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            fontFamily: "Roboto",
                            width: "auto",
                          }}
                        >
                          {place.description}
                        </p>
                        {/* <h5>Rs.{place.price}</h5> */}
                        <h5
                          style={{ fontFamily: "Open Sans", fontSize: "16px" }}
                        >
                          Rs.{place.price}
                          <span style={{ fontSize: "15px" }}>/per person</span>
                        </h5>

                        <a
                          style={{ width: "200px", marginTop: "10px" }}
                          className="location-btn"
                          onClick={() => window.open(place.location, "_blank")}
                        >
                          See Location
                        </a>
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          <div className="ms-auto">
                            <Button
                              style={{ width: "200px" }}
                              className="add-btn"
                              onClick={() =>
                                window.open(
                                  "https://bookingengine.stayflexi.com/?hotel_id=29067",
                                  "_blank"
                                )
                              }
                            >
                              Book tour
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <FooterContainer />
    </div>
  );
};

export default Bengalurutour;
