import React, { useState, useEffect } from 'react';
import './Bengaluru.css'
import { Carousel } from 'react-bootstrap';
import bedroom from '../../assets/bangassets/bedroom.jpeg'
import bedroom2 from '../../assets/bangassets/bedroom2.jpeg'
import dining from '../../assets/bangassets/dining.jpeg'
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'
import { Popover } from "@mui/material";

import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Button, Card } from '@mui/material';
import { debounce } from '@mui/material/utils';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import { Link } from 'react-router-dom';
import Weather from '../../assets/bangassets/Weather.jpg'
import Bengalurus from '../../assets/bangassets/Bengalurus.jpg'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { Col, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Card as BootstrapCard } from 'react-bootstrap';

import nightlife from '../../assets/bangassets/nightlife.jpg'
import iskon from '../../assets/bangassets/iskon.webp'
import tippupalace from '../../assets/bangassets/tippupalace.jpg'
import palace from '../../assets/bangassets/palace.jpg'
import lalbag1 from '../../assets/bangassets/lalbag1.jpg'

import ubcitymall from '../../assets/bangassets/ubcitymall.webp'
import nightlife1 from '../../assets/bangassets/nightlife1.webp'
import NandiHills from '../../assets/bangassets/NandiHills.webp'
import bikeride from '../../assets/bangassets/bikeride.jpg'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FooterContainer from '../../components/footer/FooterContainer';
const GOOGLE_MAPS_API_KEY = 'AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y';
dayjs.locale('en-gb');

const cities = [
  { label: "Bengaluru" },
  { label: "Ooty" },
  { label: "Chennai" },

];


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function Bengaluru({ ...props }) {
  
  

  const [selectedDates, setSelectedDates] = useState([dayjs(), dayjs().add(1, 'day')]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState({ label: '1 Guest', value: 1 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [cityError, setCityError] = useState(false);

  const navigate = useNavigate();

  const handleSearch = () => {
    if (selectedCity) {
      navigate(`/${selectedCity.label.toLowerCase()}`);
    } else {
      setCityError(true);
    }  };

  const handleGuestChange = (increment) => {
    setSelectedGuest((prevGuest) => {
      let newGuestCount = prevGuest.value + increment;
      newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
      return { label: `${newGuestCount} Guest${newGuestCount > 1 ? 's' : ''}`, value: newGuestCount };
    });
  };

  const handleGuestClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGuestClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'guest-popover' : undefined;

  




  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);


  
  const [showCards, setShowCards] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const toggleCards = () => setShowCards(!showCards);

  const slideLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const slideRight = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, bangaloreImgs.length - 1));
  };

  const bangaloreImgs = [
    { title: 'Nandi Hills', description: 'Nothing is more relaxing and romantic than taking a wine tour at the Nandi Hills  tour at the Nandi Hills', image: NandiHills },
    { title: 'Botanical garden', description: 'Lalbagh a centre for botanical artwork and conservation of plants worth experiencing.', image: lalbag1 },
    { title: 'NightLife', description: 'Existence of clubs,pubs and restaurants make the nightlife of Silicon Valley worth experiencing.', image: nightlife },
    { title: 'MG Road', description: '  These are among the most popular shopping and entertainment hubs in Bengaluru', image: nightlife1 },
    { title: 'Mysore Palace', description: 'This majestic palace, inspired by Englands Windsor Castle, offers a glimpse.', image: palace },
    { title: 'Tipu Sultan Palace', description: 'Tipu Sultans Summer Palace is a must-visit for anyone interested in the history and architecture.', image: tippupalace },
    { title: 'Bike Ride And tour', description: 'Located about 60 kilometers from Bengaluru, Nandi Hills is a famous weekend getaway known .', image: bikeride },
    { title: 'Iskon', description: 'The International Society for Krishna Consciousness temple is one of the largest ISKCON.', image: iskon },
    { title: 'UB City Mall', description: 'UB City Mall is one of Bengalurus most prestigious and luxurious shopping destinations.', image: ubcitymall },


  ]
    ;
  const
    [activeTab, setActiveTab] =
      useState
        (
          "weather"
        );
  // State to track the active tab 
  const
    handleTabClick
      = (tabKey) => {
        setActiveTab
          (tabKey);
      };

  return (
    <section >
      <div >
        <div className='bng-main'>
          <img src={Bengalurus} alt='' />
          <div className='bengaluru-overlay-text'>
            <h1 className='bengaluru-overlay-font' >Bengaluru</h1>
          </div>
        </div>

        <div className="responsive-wrapper">
      <Card id="search-card-grid" className="responsive-card">
        <Row className="align-items-center" style={{ padding: '23px' }}>
          <Col className="d-flex align-items-center" xs="auto">
            <Autocomplete
              id="city-search"
              options={cities}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Where do you want to go"
                  variant="outlined"
                  fullWidth
                  style={{ minWidth: 250, marginBottom: 0 }}
                  error={cityError}helperText={cityError ? "City is required" : ""}

                />
              )}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
                setCityError(false); // Reset error when city is selected

              }}
              value={selectedCity}
            />
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DateRangePicker
                localeText={{ start: 'Check-in', end: 'Check-out' }}
                value={selectedDates}
                onChange={(newValue) => {
                  setSelectedDates(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 150 }}
                    />
                    <TextField
                      {...endProps}
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 150 }}
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Select Guests"
                variant="outlined"
                fullWidth
                value={selectedGuest.label}
                onClick={handleGuestClick}
                style={{ minWidth: 150, marginBottom: 0 }}
                readOnly
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleGuestClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button variant="outline-secondary" onClick={() => handleGuestChange(-1)}>-</Button>
                  <span style={{ margin: '0 10px' }}>{selectedGuest.value}</span>
                  <Button variant="outline-secondary" onClick={() => handleGuestChange(1)}>+</Button>
                </div>
              </Popover>
            </div>
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <Button variant='primary' style={{ backgroundColor: 'blue', color: 'white' }} onClick={handleSearch}>
              Let's Go
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
       
        <div className='bengaluru-section'>
          <div className='bengaluru-maincontnt' >
            <div className='container'>

            <Typography variant='h4' style={{ fontSize: '30px', fontWeight: '500', fontFamily: 'Open Sans',width:'auto' }}>Bengaluru in India</Typography>
            <div >
              <p className='row justify-content-center bengaluru-main-paragraph' >   Bengaluru (also called Bangalore) is the capital of India's southern Karnataka state. The center of India's high-tech industry, the city is also known for its parks and nightlife. By Cubbon Park, Vidhana Soudha is a Neo-Dravidian legislative building. Former royal residences include 19th-century Bangalore Palace, modeled after England’s Windsor Castle, and Tipu Sultan’s Summer Palace, an 18th-century teak structure.</p>
            </div>
            </div>


            
            <div>
      {!showCards && (
        <div className="d-flex justify-content-center">
          <Button variant="primary" style={{ textAlign: 'center', color: "palevioletred" }} onClick={toggleCards}>
            Read More <KeyboardArrowDownIcon />
          </Button>
        </div>
      )}

      {showCards && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant='h4' style={{ textAlign: 'center', marginBottom: "20px", fontSize: '30px', fontWeight: '500', fontFamily: 'Open Sans' }}>
            Reasons to visit Bengaluru
          </Typography>

          <div className='d-flex justify-content-center align-items-center' style={{ position: 'relative', width: '100%', overflow: 'hidden', overflowX: 'scroll' }}>
            {currentIndex > 0 && (
              <Button
                variant="text"
                onClick={slideLeft}
                style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'palevioletred' }}
              >
                <ArrowBackIosIcon style={{ color: 'black', fontSize: '30px', boxShadow: 'none' }} />
              </Button>
            )}

            
            
            
            <div className='explore-cards'>
              {bangaloreImgs.slice(currentIndex, currentIndex + 4).map((card, index) => (
                <div key={index} style={{ display: 'inline-block', padding: '10px' }}>
                  <BootstrapCard style={{ width: '18rem', height: 'auto' }}>
                    <BootstrapCard.Img variant="top" src={card.image} alt={`Image for ${card.title}`} style={{ height: '200px', objectFit: 'cover' }} />
                    <BootstrapCard.Body>
                      <BootstrapCard.Title>{card.title}</BootstrapCard.Title>
                      <BootstrapCard.Text className='bengaluru-cards-text'>{card.description}</BootstrapCard.Text>
                    </BootstrapCard.Body>
                  </BootstrapCard>
                </div>
              ))}
            </div>

            {currentIndex < bangaloreImgs.length - 4 && (
              <Button
                variant="text"
                onClick={slideRight}
                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'palevioletred' }}
              >
                <ArrowForwardIosIcon style={{ color: 'black', fontSize: '30px', boxShadow: 'none' }} />
              </Button>
            )}
          </div>

          <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
            <Button variant="primary" style={{ marginTop: '50px', textAlign: 'center', color: "palevioletred" }} onClick={toggleCards}>
              Read Less <KeyboardArrowUpIcon />
            </Button>
          </div>
        </div>
      )}
    </div>

          </div>
         
           <div className='container bengaluru-main-roomcards'>
      <div className='row justify-content-center' style={{ marginTop: '40px' }}>
        <Link to="/Bprabhu" style={{ textDecoration: 'none' }}>
          <Card className='cardlist'>
            <div className='card-div row'>
              <div className='col-md-6'>
                <Carousel style={{ width: '100%', borderRadius: '10px' }}>
                  <Carousel.Item interval={3000}>
                    <img src={bedroom} text="First slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                  </Carousel.Item>
                  <Carousel.Item interval={3000}>
                    <img src={bedroom2} text="Second slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                  </Carousel.Item>
                  <Carousel.Item interval={3000}>
                    <img src={dining} text="Third slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className='col-md-6'>
                <Typography variant='h5' style={{ fontFamily: 'Open Sans', fontWeight: '500', fontSize: '23px' }}>Prabhu's Place in Bengaluru</Typography>
                <p style={{ fontSize: '16px', marginTop: '10px', fontFamily: 'Roboto' }}>
                  Fully furnished 2 bed 2 bath apartment with cooking amenities including a microwave oven, Fridge, utensils, a washer, and a space to dry your clothes in the balcony.
                </p>
                <div className='cards'>
                  <div style={{ display: 'flex', gap: '15px', marginTop: '50px' }}>
                    <div>
                      <h5 style={{ marginTop: '5px', color: 'palevioletred', fontFamily: 'Roboto' }}>Apartment</h5>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <p style={{ fontFamily: 'Roboto', fontSize: '15px' }}><del>7500</del>/night</p>
                        <p style={{ fontFamily: 'Roboto', fontSize: '15px' }}>6500/night</p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '30px' }}>
                    <h5 style={{ marginTop: '5px', color: 'palevioletred', fontFamily: 'Roboto' }}>Dorms</h5>
                    <p style={{ fontFamily: 'Roboto', fontSize: '15px' }}>No Privates Available</p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Link>
      </div>
      <div className='row justify-content-center'>
        <div className='bengalurutabs-content'>
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabClick}
            className="mb-3"
            justify
          >
            <Tab eventKey="weather" title={
              <span style={{ color: activeTab === 'weather' ? 'blue' : 'black' }}>
                <ThunderstormIcon /> Weather
              </span>
            }>
              <Typography variant='h6' style={{ fontWeight: '400', fontFamily: 'Open Sans' }}>Weather in Bengaluru</Typography>
              <img src={Weather} style={{ width: '100%', height: 'auto' }} />
            </Tab>
            <Tab eventKey="location" title={
              <span style={{ color: activeTab === 'location' ? 'blue' : 'black' }}>
                <FmdGoodIcon /> Location
              </span>
            }>
              <Typography variant='h6' style={{ fontWeight: '400', fontFamily: 'Open Sans' }}>Bengaluru Location</Typography>
              <iframe
                width="100%"
                height="200"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                style={{ width: '100%', height: 'auto' }}
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Majestic,Bengaluru,Karnataka+(Bengaluru)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>

        </div>
      </div>

      <FooterContainer />

    </section>
  )
}
export default Bengaluru;