import React, { useState } from 'react';
import './Chennai.css';
import { Carousel, Navbar, ProgressBar, Tab, Tabs } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Button, Card } from '@mui/material';
import chennaipic from '../../assets/chinnaaasets/chpics/chennaipic.webp'
import bedroom3 from '../../assets/chinnaaasets/chpics/bedroom3.jpeg'
import bedroom from '../../assets/chinnaaasets/chpics/bedroom.webp'
import bedroom2 from '../../assets/chinnaaasets/chpics/bedroom2.jpeg'
import bedroom4 from '../../assets/chinnaaasets/chpics/bedroom4.webp'
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import { Link } from 'react-router-dom';
import chennai1 from '../../assets/chinnaaasets/chpics/chennai1.jpeg'
import Weatherchennai from '../../assets/chinnaaasets/chpics/Weatherchennai.jpg'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import beach from '../../assets/chinnaaasets/chpics/beach.jpg'
import beachchennai from '../../assets/chennaipic.jpeg'
import { Card as BootstrapCard } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import birla from '../../assets/chinnaaasets/chpics/birla.jpg'
import world from '../../assets/chinnaaasets/chpics/world.jpg'
import chennaichurch from '../../assets/chinnaaasets/chpics/chennaichurch.jpg'
import chennaitemple from '../../assets/chinnaaasets/chpics/chennaitemple.jpg'
import mahabalipuram from '../../assets/chinnaaasets/chpics/mahabalipuram.jpg'
import musium from '../../assets/chinnaaasets/chpics/musium.jpg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'dayjs/locale/en-gb'; // Import the desired locale
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Row } from "react-bootstrap";
import dayjs from 'dayjs';
import { Popover } from "@mui/material";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import FooterContainer from '../../components/footer/FooterContainer';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
 
const cities = [
  { label: "Bengaluru" },
  { label: "Ooty" },
  { label: "Chennai" },

];

const GOOGLE_MAPS_API_KEY = 'AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}




const autocompleteService = { current: null };

function Chennai() {
  
  const [selectedDates, setSelectedDates] = useState([dayjs(), dayjs().add(1, 'day')]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState({ label: '1 Guest', value: 1 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [cityError, setCityError] = useState(false);

  const navigate = useNavigate();

  const handleSearch = () => {
    if (selectedCity) {
      navigate(`/${selectedCity.label.toLowerCase()}`);
    } else {
      setCityError(true);
    }  };

  const handleGuestChange = (increment) => {
    setSelectedGuest((prevGuest) => {
      let newGuestCount = prevGuest.value + increment;
      newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
      return { label: `${newGuestCount} Guest${newGuestCount > 1 ? 's' : ''}`, value: newGuestCount };
    });
  };

  const handleGuestClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGuestClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'guest-popover' : undefined;

  const bangaloreImgs = [
    { title: ' Marina Beach ', description: ' Marina Beach is the longest urban beach in India and the second longest in the world, stretching over 13 kilometers.  sunrise. ', image: beach },
    { title: '. Elliot’s Beach ', description: 'Also known as Besant Nagar Beach, Elliot’s Beach is a quieter and more serene alternative to Marina Beach. ', image: beachchennai },
    { title: 'Kapaleeshwarar Temple', description: 'Located in Mylapore, this ancient Hindu temple is dedicated to Lord Shiva. ', image: chennaitemple },
    { title: 'Mahabalipuram', description: 'Located about 60 km from Chennai, this UNESCO World Heritage Site is famous for its rock-cut temples and sculptures.  ', image: mahabalipuram },


    { title: 'San Thome Basilica', description: 'This Roman Catholic basilica was built over the tomb of St. Thomas the Apostle. ', image: chennaichurch },
    { title: 'Government Museum', description: 'Located in Egmore, this museum is the second oldest in India. It has an extensive collection of artifacts,etc.. ', image: musium },
    { title: 'Birla Planetarium', description: ' Located in Kotturpuram, the Birla Planetarium offers an immersive experience of the universe with its state-of-the-art projections and interactive exhibits.', image: birla },
    { title: ' MGM Dizzee World', description: 'A popular amusement park located on the East Coast Road, MGM Dizzee World offers a variety of rides and entertainment .', image: world },


  ]
    ;
    const [showCards, setShowCards] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
 
  const toggleCards = () => setShowCards(!showCards);
 
  const slideLeft = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
 
  const slideRight = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, bangaloreImgs.length - 1));
  };
  const
    [activeTab, setActiveTab] =
      useState
        (
          "weather"
        );
  const
    handleTabClick
      = (tabKey) => {
        setActiveTab
          (tabKey);
      };

  const [showLess, setshowLess] = useState(true);
  

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }


  const Weather = () => {
    window.open(
      'https://www.google.com/search?q=weather+in+chennai&sca_esv=45a32e260ac88b18&biw=1488&bih=742&ei=tQ9XZrUE8tWx4w-viYOYCA&oq=weather+in+bangalore&gs_lp=Egxnd3Mtd2l6LXNlcnAiFHdlYXRoZXIgaW4gYmFuZ2Fsb3JlKgIIAzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzIKEAAYsAMY1gQYRzINEAAYgAQYsAMYQxiKBTINEAAYgAQYsAMYQxiKBUjIHFAAWABwBXgBkAEAmAEAoAEAqgEAuAEByAEAmAIFoAJKmAMAiAYBkAYKkgcBNaAHAA&sclient=gws-wiz-serp')
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);


  const [showAll, setShowAll] = useState(false);

  

  return (
    <section>
      <div >
        <div className='chennai-main'>
          <img src={chennai1} alt='' />
          <div className='chennai-overlay-text'>
            <h1 className='chennai-overlay-font' >Chennai</h1>
          </div>

        </div>


        <div className="responsive-wrapper">
      <Card id="search-card-grid" className="responsive-card">
        <Row className="align-items-center" style={{ padding: '23px' }}>
          <Col className="d-flex align-items-center" xs="auto">
            <Autocomplete
              id="city-search"
              options={cities}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Where do you want to go"
                  variant="outlined"
                  fullWidth
                  style={{ minWidth: 250, marginBottom: 0 }}
                  error={cityError}helperText={cityError ? "City is required" : ""}

                />
              )}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
                setCityError(false); // Reset error when city is selected

              }}
              value={selectedCity}
            />
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <DateRangePicker
                localeText={{ start: 'Check-in', end: 'Check-out' }}
                value={selectedDates}
                onChange={(newValue) => {
                  setSelectedDates(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 150 }}
                    />
                    <TextField
                      {...endProps}
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 150 }}
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Select Guests"
                variant="outlined"
                fullWidth
                value={selectedGuest.label}
                onClick={handleGuestClick}
                style={{ minWidth: 150, marginBottom: 0 }}
                readOnly
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleGuestClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                  <Button variant="outline-secondary" onClick={() => handleGuestChange(-1)}>-</Button>
                  <span style={{ margin: '0 10px' }}>{selectedGuest.value}</span>
                  <Button variant="outline-secondary" onClick={() => handleGuestChange(1)}>+</Button>
                </div>
              </Popover>
            </div>
          </Col>
          <Col className="d-flex align-items-center" xs="auto">
            <Button variant='primary' style={{ backgroundColor: 'blue', color: 'white' }} onClick={handleSearch}>
              Let's Go
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
        <div className='chennai-section'>
          <div className='chennai-contnt'>
            <div className='container'>

            <Typography variant='h4' style={{ fontFamily: "Open Sans", fontSize: '30px', fontWeight: '500',width:'auto' }}>Chennai In India </Typography>
            <p className='row justify-content-center chennai-main-paragraph'>
            Chennai is a vibrant city that harmoniously blends tradition and modernity. Its rich cultural heritage, economic significance, educational institutions, and medical facilities make it a dynamic and essential part of India's landscape. Whether you are visiting for its historical sites, cultural experiences, or business opportunities, Chennai offers something for everyone.
            </p>
            </div>

          </div>

          
          <div>
      {!showCards && (
        <div className="d-flex justify-content-center">
          <Button variant="primary" style={{ textAlign: 'center', color: "palevioletred" }} onClick={toggleCards}>
            Read More <KeyboardArrowDownIcon />
          </Button>
        </div>
      )}
 
      {showCards && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant='h4' style={{ textAlign: 'center', marginBottom: "20px", fontSize: '30px', fontWeight: '500', fontFamily: 'Open Sans' }}>
            Reasons to visit Chennai
          </Typography>
 
          <div className='d-flex justify-content-center align-items-center' style={{ position: 'relative', width: '100%', overflow: 'hidden', overflowX: 'scroll' }}>
            {currentIndex > 0 && (
              <Button
                variant="text"
                onClick={slideLeft}
                style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'palevioletred' }}
              >
                <ArrowBackIosIcon style={{ color: 'black', fontSize: '30px', boxShadow: 'none' }} />
              </Button>
            )}
 
            <div className='explore-cards'>
              {bangaloreImgs.slice(currentIndex, currentIndex + 4).map((card, index) => (
                <div key={index} style={{ display: 'inline-block', padding: '10px' }}>
                  <BootstrapCard style={{ width: '18rem', height: 'auto' }}>
                    <BootstrapCard.Img variant="top" src={card.image} alt={`Image for ${card.title}`} style={{ height: '200px', objectFit: 'cover' }} />
                    <BootstrapCard.Body>
                      <BootstrapCard.Title>{card.title}</BootstrapCard.Title>
                      <BootstrapCard.Text className='chennai-desctext'>{card.description}</BootstrapCard.Text>
                    </BootstrapCard.Body>
                  </BootstrapCard>
                </div>
              ))}
            </div>
 
            {currentIndex < bangaloreImgs.length - 4 && (
              <Button
                variant="text"
                onClick={slideRight}
                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, backgroundColor: 'palevioletred' }}
              >
                <ArrowForwardIosIcon style={{ color: 'black', fontSize: '30px', boxShadow: 'none' }} />
              </Button>
            )}
          </div>
 
          <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
            <Button variant="primary" style={{ marginTop: '50px', textAlign: 'center', color: "palevioletred" }} onClick={toggleCards}>
              Read Less <KeyboardArrowUpIcon />
            </Button>
          </div>
        </div>
      )}
    </div>

          
           <div className='container chennai-room-cards'>
      <div className='row justify-content-center' style={{ marginTop: '40px' }}>
        <div>
          <Link to="/Cprabhu" style={{ textDecoration: 'none' }}>
            <Card className='cardlist'>
              <div className='card-div row' style={{ padding: '20px' }}>
                <div className='col-md-6'>
                  <Carousel style={{ width: '100%', borderRadius: '10px' }}>
                    <Carousel.Item interval={3000} style={{ borderRadius: '10px' }}>
                      <img src={chennaipic} text="First slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={bedroom} text="Second slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={bedroom3} text="Third slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={bedroom2} text="Fourth slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                    <Carousel.Item interval={3000}>
                      <img src={bedroom4} text="Fifth slide" alt='' style={{ width: '100%', height: '250px', borderRadius: '10px' }} />
                    </Carousel.Item>
                  </Carousel>
                </div>
                <div className='col-md-6'>
                  <Typography variant='h5' style={{ fontFamily: 'Open Sans', fontWeight: '500', fontSize: '23px' }}>Prabhu's Place in Chennai</Typography>
                  <p style={{ fontSize: '16px', marginTop: '10px', fontFamily: 'Roboto' }}>
                    Fully furnished 2 bed 2 bath apartment with cooking amenities including a microwave oven, Fridge, utensils, a washer, and a space to dry your clothes in the balcony.
                  </p>
                  <div className='cards'>
                    <div style={{ display: 'flex', gap: '15px', marginTop: '30px' }}>
                      <div>
                        <h5 style={{ marginTop: '5px', color: 'palevioletred', fontFamily: 'Roboto' }}>Apartment</h5>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          <p style={{ fontFamily: 'Roboto', fontSize: '15px' }}><del>7500</del>/night</p>
                          <p style={{ fontFamily: 'Roboto', fontSize: '15px' }}>6500/night</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <h5 style={{ marginTop: '5px', color: 'palevioletred', fontFamily: 'Roboto' }}>Dorms</h5>
                      <p style={{ fontFamily: 'Roboto', fontSize: '15px' }}>No Privates Available</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Link>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='chennaitabs-content'>
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabClick}
            className="mb-3"
            justify
          >
            <Tab eventKey="weather" title={
              <span style={{ color: activeTab === 'weather' ? 'blue' : 'black' }}>
                <ThunderstormIcon /> Weather
              </span>
            }>
              <Typography variant='h6' style={{ fontWeight: '400', fontFamily: 'Open Sans' }}>Weather in Chennai</Typography>
              <img className='tabs-weather' src={Weatherchennai} style={{ width: '100%', height: 'auto' }} />
            </Tab>
            <Tab eventKey="location" title={
              <span style={{ color: activeTab === 'location' ? 'blue' : 'black' }}>
                <FmdGoodIcon /> Location
              </span>
            }>
              <Typography variant='h6' style={{ fontWeight: '400', fontFamily: 'Open Sans' }}>Chennai Central</Typography>
              <iframe
              width='100px'
                height="200"
                className='tabs-location'
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                style={{ width: '100%', height: 'auto' }}
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Chennai%20Central%20Railway%20Station+(World%20of%20Hostels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
        </div>
      </div>
      <FooterContainer />

    </section>
  )
}

export default Chennai