import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { Grid, Typography } from "@mui/material";
import logo2 from "../../assets/logo2.png";
function NavbarHeader() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const [Dropdown, setDropdown] = useState(false);
  const togglebuttons = () => {
    console.log("menu button");
    setDropdown(!Dropdown);
  };
  const menubuttonRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      menubuttonRef.current &&
      !menubuttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };
  useEffect(() => {
    if (Dropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [Dropdown]);
  return (
    <>
      <nav className="navbar">
        <Link
          to="/"
          style={{ textDecoration: "none", fontFamily: "Open Sans" }}
        >
          <h2 style={{ fontFamily: "Open Sans" }}>Prabhus place</h2>
        </Link>
        {/* <Link to="/" className='navbar-logo'><img  src={logo2}/> </Link> */}
        <Grid className="navbar-icons">
          <PersonIcon />
          <div className="navbar-main-container">
            <button className="navbar-menu-buttons" onClick={togglebuttons}>
              <MenuIcon />
            </button>
            {Dropdown && (
              <div className="navbar-dropdown-menu" ref={menubuttonRef}>
                <Link className="A" to="/">
                  Home
                </Link>
                <Link className="A" to="/aboutus">
                  About Us
                </Link>
                <Link className="A" to="/Contacts">
                  Contact Us
                </Link>
              </div>
            )}
          </div>
        </Grid>
      </nav>
    </>
  );
}

export default NavbarHeader;
