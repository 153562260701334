import React, { useState } from "react";
import "./Header.css";
import mainbanner from "../../assets/mainbanner.jpg";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const cities = [
  { label: "Bengaluruprabhuplace" },
  { label: "Ootyprabhusplace" },
  { label: "Chennaiprabhusplace" },
];

const Header = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityError, setCityError] = useState(false);
  const navigate = useNavigate();

  const handleSearch = () => {
    if (selectedCity) {
      navigate(`/${selectedCity.label.toLowerCase()}`);
    } else {
      setCityError(true);
    }
  };

  const handleBookNow = () => {
    if (selectedCity) {
      window.open(
        "https://bookingengine.stayflexi.com/?hotel_id=29067",
        "_blank"
      );
    } else {
      setCityError(true);
    }
  };

  return (
    <section className="header">
      <Grid sx={{ marginLeft: "50px" }}>
        <div className="image-container">
          <img src={mainbanner} alt="" />
          <div className="overlay-text">
            <h1 className="overlay-font">Where comfort meets adventure.</h1>
          </div>
        </div>

        <div className="responsive-wrapper">
          <Card id="search-card-grid-header" className="responsive-card-header">
            <Row className="align-items-center">
              <Col className="d-flex align-items-center">
                <Autocomplete
                  id="city-search"
                  options={cities}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Where do you want to go"
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: 250, marginBottom: 0 }}
                      error={cityError}
                      helperText={cityError ? "City is required" : ""}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSelectedCity(newValue);
                    setCityError(false);
                  }}
                  value={selectedCity}
                />
              </Col>

              <Col
                className="d-flex align-items-center"
                style={{ gap: "10px", marginTop: "10px" }}
              >
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "orange",
                    color: "black",
                    // fontSize: "12px",
                    width: "130px",
                  }}
                  onClick={handleSearch}
                >
                  Explore More
                </Button>
                <Button
                  variant="outlined"
                  style={{ backgroundColor: "orange", color: "black" }}
                  onClick={handleBookNow}
                >
                  Book Now
                </Button>
              </Col>
              {/* <Col className="d-flex align-items-center">
               
                
              </Col> */}
            </Row>
          </Card>
        </div>
      </Grid>
    </section>
  );
};

export default Header;
