// // import React, { useEffect, useState } from "react";
// // import "./Oprabhu.css";
// // import "bootstrap/dist/css/bootstrap.min.css";
// // import TextField from "@mui/material/TextField";
// // import Autocomplete from "@mui/material/Autocomplete";
// // import { debounce } from "@mui/material/utils";
// // import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
// // import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
// // import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
// // import dorm1 from "../../assets/Ootyassets/dorm1.jpg";
// // import dorm3 from "../../assets/Ootyassets/dorm3.webp";
// // import dorm4 from "../../assets/Ootyassets/dorm4.jpg";
// // import dorm5 from "../../assets/Ootyassets/dorm5.webp";
// // import dorm8 from "../../assets/Ootyassets/dorm8.jpg";
// // import dorm7 from "../../assets/Ootyassets/dorm7.jpg";
// // import dorm9 from "../../assets/Ootyassets/dorm9.jpg";
// // import ReactStars from "react-rating-stars-component";

// // import privateroom3 from "../../assets/Ootyassets/privateroom3.jpeg";
// // import privateroom4 from "../../assets/Ootyassets/privateroom4.jpeg";
// // import privateroom5 from "../../assets/Ootyassets/privateroom5.jpeg";

// // import PersonIcon from "@mui/icons-material/Person";

// // import { Carousel, ProgressBar } from "react-bootstrap";

// // import LocationOnIcon from "@mui/icons-material/LocationOn";
// // import { Alert, Typography } from "@mui/material";
// // import image1 from "../../assets/Ootyassets/image1.jpeg";

// // import Tab from "react-bootstrap/Tab";
// // import Tabs from "react-bootstrap/Tabs";

// // import InfoIcon from "@mui/icons-material/Info";
// // import FmdGoodIcon from "@mui/icons-material/FmdGood";
// // import RateReviewIcon from "@mui/icons-material/RateReview";
// // import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// // import CheckIcon from "@mui/icons-material/Check";
// // import wifi1 from "../../assets/wifi1.png";
// // import publictoilet from "../../assets/publictoilet.png";
// // import fire from "../../assets/fire.png";
// // import parking from "../../assets/parking.png";
// // import ac from "../../assets/ac.png";
// // import cafe from "../../assets/cafe.png";

// // import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
// // import dayjs from "dayjs";
// // import { Popover } from "@mui/material";
// // import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
// // import { useNavigate } from "react-router-dom";
// // import FooterContainer from "../../components/footer/FooterContainer";
// // import temple from "../../assets/chinnaaasets/chpics/temple.jpg";
// // import tea from "../../assets/chinnaaasets/chpics/tea.webp";
// // import train from "../../assets/chinnaaasets/chpics/train.jpg";
// // import rosegarden from "../../assets/chinnaaasets/chpics/rosegarden.jpg";
// // import ootygarden from "../../assets/chinnaaasets/chpics/ootygarden.jpg";
// // import falls from "../../assets/chinnaaasets/chpics/falls.jpg";
// // import ootylake from "../../assets/chinnaaasets/chpics/ootylake.jpg";
// // import church from "../../assets/chinnaaasets/chpics/church.jpg";
// // import Doddabettateles from "../../assets/chinnaaasets/chpics/Doddabettateles.jpg";
// // import { Card, Button, Container, Row, Col } from "react-bootstrap";
// // import "bootstrap/dist/css/bootstrap.min.css";
// // import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";

// // const GOOGLE_MAPS_API_KEY = "AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y";

// // // const cities = [
// // //   { label: "Bengaluru" },
// // //   { label: "Ooty" },
// // //   { label: "Chennai" },
// // // ];
// // const cities = [
// //   { label: "Bprabhu" },
// //   { label: "Oprabhu" },
// //   { label: "Cprabhu" },
// // ];

// // function loadScript(src, position, id) {
// //   if (!position) {
// //     return;
// //   }

// //   const script = document.createElement("script");
// //   script.setAttribute("async", "");
// //   script.setAttribute("id", id);
// //   script.src = src;
// //   position.appendChild(script);
// // }

// // const autocompleteService = { current: null };

// // const Oprabhu = () => {
// //   // const [selectedDates, setSelectedDates] = useState([
// //   //   dayjs(),
// //   //   dayjs().add(1, "day"),
// //   // ]);

// //   // React.useEffect(() => {
// //   const storedDates = localStorage.getItem("selectedDates");

// //   const dateRangeArray = JSON.parse(storedDates);

// //   const startDateString = dateRangeArray[0];
// //   const endDateString = dateRangeArray[1];

// //   const sdate = new Date(startDateString);
// //   const edate = new Date(endDateString);

// //   const [selectionRange, setSelectionRange] = useState({
// //     startDate: new Date(),
// //     endDate: new Date(),
// //     key: "selection",
// //   });

// //   // alert(sdate);
// //   // setSelectionRange(storedDates);
// //   // }, []);

// //   const handleSelect2 = (ranges) => {
// //     setSelectionRange(ranges.selection);
// //     localStorage.setItem("selectedDates", JSON.stringify(ranges));

// //     //alert(localStorage.getItem("selectedDates"));
// //   };

// //   const [selectedCity, setSelectedCity] = useState(null);
// //   const [selectedGuest, setSelectedGuest] = useState({
// //     label: "1 Guest",
// //     value: 1,
// //   });
// //   const [anchorEl, setAnchorEl] = useState(null);
// //   const [cityError, setCityError] = useState(false);

// //   const navigate = useNavigate();

// //   const handleSearch = () => {
// //     if (selectedCity) {
// //       navigate(`/${selectedCity.label.toLowerCase()}`);
// //     } else {
// //       setCityError(true);
// //     }
// //   };
// //   const handleGuestChange = (increment) => {
// //     setSelectedGuest((prevGuest) => {
// //       let newGuestCount = prevGuest.value + increment;
// //       newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
// //       return {
// //         label: `${newGuestCount} Guest${newGuestCount > 1 ? "s" : ""}`,
// //         value: newGuestCount,
// //       };
// //     });
// //   };

// //   const handleGuestClick = (event) => {
// //     setAnchorEl(event.currentTarget);
// //   };

// //   const handleGuestClose = () => {
// //     setAnchorEl(null);
// //   };

// //   const open = Boolean(anchorEl);
// //   const id = open ? "guest-popover" : undefined;

// //   const ootyapartments = [
// //     {
// //       name: "Private Room",
// //       images: [privateroom3, privateroom5, privateroom4],
// //       description:
// //         "Nice sized private room comes with king size bed and an attached bath some rooms are with view of the hills and wake up to church bells.",
// //       discountedPrice: "800",
// //     },

// //     {
// //       name: "Mix Dorm One with ensuite",
// //       images: [dorm1, dorm3, image1, dorm5],
// //       description:
// //         "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
// //       discountedPrice: "600",
// //     },
// //     {
// //       name: "Mix Dorm Two with ensuite",
// //       images: [image1, dorm9, dorm8, dorm5],
// //       description:
// //         "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
// //       discountedPrice: "500",
// //     },
// //     {
// //       name: "Mix Dorm Three with ensuite",
// //       images: [dorm3, dorm7, dorm9, image1],
// //       description:
// //         "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
// //       discountedPrice: "1000",
// //     },
// //   ];
// //   const [key, setKey] = useState("description");
// //   useEffect(() => {
// //     window.scrollTo(0, 0);
// //   }, [key]);

// //   const selection = {
// //     title: "My Selection",
// //   };

// //   const reviewData = [
// //     {
// //       name: "ashokreddy",
// //       gender: "Male",
// //       age: "20-26",
// //       status: "Novice Nomed",
// //       reviews: 2,
// //       rating: 6.1,
// //       feedback:
// //         "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
// //     },
// //     {
// //       name: "rajakullayappa",
// //       gender: "Male",
// //       age: "20-26",
// //       status: "Novice Nomed",
// //       reviews: 5,
// //       rating: 7.5,
// //       feedback:
// //         "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
// //     },
// //     {
// //       name: "Viratkohli",
// //       gender: "Male",
// //       age: "30-36",
// //       status: "Novice Nomed",
// //       reviews: 1,
// //       rating: 10,
// //       feedback:
// //         "An example of what a hostel should be. The hospital and friendliness of the staff made me feel very comfortable and relaxed.",
// //     },
// //   ];

// //   const places = [
// //     {
// //       title: "Botanical Garden ",
// //       description:
// //         " Botanical Gardens, established in 1847, are spread over 55 acres and showcase a wide variety of plants, trees, and flowers. ",
// //       imageUrl: ootygarden,
// //       price: "500",
// //     },
// //     {
// //       title: "Ooty Lake",
// //       description:
// //         "Ooty Lake is an artificial lake surrounded by eucalyptus trees and greenery. Its a popular spot for boating and picnics. ",
// //       imageUrl: ootylake,
// //       price: "500",
// //     },
// //     {
// //       title: "Pykara Lake and Falls",
// //       description:
// //         "Pykara Lake, with its serene waters, lush surroundings, and the charm of its nearby waterfalls, offers a perfect getaway for those looking  Nilgiris.",
// //       imageUrl: falls,
// //       price: "500",
// //     },
// //     {
// //       title: "Nilgiri Mountain Railway",
// //       description:
// //         "The Nilgiri Mountain Railway, also known as the Ooty Toy Train, is a UNESCO World Heritage Site.  ",
// //       imageUrl: train,
// //       price: "500",
// //     },

// //     {
// //       title: "Elk Hill Murugan Temple",
// //       description:
// //         "The Elk Hill Murugan Temple is dedicated to Lord Murugan and offers a panoramic views of Ooty.",
// //       imageUrl: temple,
// //       price: "500",
// //     },
// //     {
// //       title: "st. stephen church ooty",
// //       description:
// //         "St. Stephens Church, built in 1829, is one of the oldest churches in the Nilgiris.",
// //       imageUrl: church,
// //       price: "500",
// //     },
// //     {
// //       title: "Ooty Tea Factory,Museum",
// //       description:
// //         "The Tea Factory and Museum offers an insight into the tea-making process. ",
// //       imageUrl: tea,
// //       price: "500",
// //     },
// //     {
// //       title: "Rose Garden",
// //       description:
// //         "The Ooty Rose Garden, also known as the Jayalalithaa Rose Garden, is one of the largest rose gardens in India.",
// //       imageUrl: rosegarden,
// //       price: "500",
// //     },
// //     {
// //       title: "Doddabetta Peak",
// //       description:
// //         "Doddabetta Peak is the highest point in the Nilgiri Mountains, standing at 2,637 meters above sea level. ",
// //       imageUrl: Doddabettateles,
// //       price: "500",
// //     },
// //   ];
// //   //
// //   // State for bookings of activities (things)
// //   const [thingBookings, setThingBookings] = useState(
// //     places.map(() => ({ isBooked: false, quantity: 0 }))
// //   );

// //   // State for bookings of rooms
// //   const [roomBookings, setRoomBookings] = useState(
// //     ootyapartments.map(() => ({ isBooked: false, quantity: 0, price: 0 }))
// //   );

// //   // Handler to book an activity (thing)
// //   const handleThingBookNow = (index) => {
// //     const newThingBookings = [...thingBookings];
// //     newThingBookings[index].isBooked = true;
// //     newThingBookings[index].quantity = 1;
// //     setThingBookings(newThingBookings);
// //   };

// //   // Handler to increment the quantity of an activity (thing)
// //   const handleThingIncrement = (index) => {
// //     const newThingBookings = [...thingBookings];
// //     newThingBookings[index].quantity += 1;
// //     setThingBookings(newThingBookings);
// //   };

// //   // Handler to decrement the quantity of an activity (thing)
// //   const handleThingDecrement = (index) => {
// //     const newThingBookings = [...thingBookings];
// //     if (newThingBookings[index].quantity > 0) {
// //       newThingBookings[index].quantity -= 1;
// //       if (newThingBookings[index].quantity === 0) {
// //         newThingBookings[index].isBooked = false;
// //       }
// //       setThingBookings(newThingBookings);
// //     }
// //   };

// //   // Handler to add a room booking
// //   const handleAddRoom = (index) => {
// //     const newRoomBookings = [...roomBookings];
// //     newRoomBookings[index].isBooked = true;
// //     newRoomBookings[index].quantity = 1;
// //     newRoomBookings[index].price = ootyapartments[index].discountedPrice;
// //     setRoomBookings(newRoomBookings);
// //   };

// //   // Handler to increment the quantity of a room booking
// //   const handleRoomIncrement = (index) => {
// //     const newRoomBookings = [...roomBookings];
// //     newRoomBookings[index].quantity += 1;
// //     setRoomBookings(newRoomBookings);
// //   };

// //   // Handler to decrement the quantity of a room booking
// //   const handleRoomDecrement = (index) => {
// //     const newRoomBookings = [...roomBookings];
// //     if (newRoomBookings[index].quantity > 0) {
// //       newRoomBookings[index].quantity -= 1;
// //       if (newRoomBookings[index].quantity === 0) {
// //         newRoomBookings[index].isBooked = false;
// //         newRoomBookings[index].price = 0;
// //       }
// //       setRoomBookings(newRoomBookings);
// //     }
// //   };

// //   // Calculate total amount for things bookings
// //   const totalThingAmount = thingBookings.reduce(
// //     (acc, booking, index) => acc + booking.quantity * places[index].price,
// //     0
// //   );

// //   // Calculate total amount for room bookings
// //   const totalRoomAmount = roomBookings.reduce(
// //     (acc, booking) => acc + booking.quantity * booking.price,
// //     0
// //   );

// //   // Calculate total amount for both things and rooms
// //   const totalAmount = totalThingAmount + totalRoomAmount;

// //   //
// //   const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight - 340); // Window height minus 50px

// //   useEffect(() => {
// //     const handleResize = () => setSidebarHeight(window.innerHeight - 340);
// //     window.addEventListener("resize", handleResize);
// //     return () => window.removeEventListener("resize", handleResize);
// //   }, []);

// //   const data = [
// //     {
// //       div: 1,
// //       imgsrc: dorm1,
// //     },
// //     ,
// //     {
// //       div: 3,
// //       imgsrc: dorm3,
// //     },
// //     {
// //       div: 4,
// //       imgsrc: dorm4,
// //     },
// //     {
// //       div: 5,
// //       imgsrc: dorm5,
// //     },
// //     {
// //       div: 6,
// //       imgsrc: dorm7,
// //     },
// //     {
// //       div: 5,
// //       imgsrc: dorm8,
// //     },
// //     {
// //       div: 6,
// //       imgsrc: dorm9,
// //     },
// //   ];

// //   const [activeTab, setActiveTab] = useState("description");
// //   const handleTabClick = (tabKey) => {
// //     setActiveTab(tabKey);
// //   };
// //   const [model, setModel] = useState(false);
// //   const [tempimgsrc, settempimgsrc] = useState("");
// //   const getImg = (imgsrc) => {
// //     settempimgsrc(imgsrc);
// //     setModel(true);
// //   };
// //   useEffect(() => {
// //     let handler = () => {
// //       setModel(false);
// //     };
// //     document.addEventListener("mousedown", handler);
// //   });
// //   const [showLess, setshowLess] = useState(true);
// //   const str =
// //     "This budget friendly place offers both private rooms and dorms for the comfort of both individuals and groups.  Dorms have 2 bunk beds and spacious and can accommodate an extra bed  for groups wanting to share the same room. Delicious homely food made from freshly grown organic vegetables on request.Located just minutes away from Ooty Botanical gardens - a charming farm house Budget friendly with options for both dormitory and private rooms Surrounded by an organic farm and join a guided tour of the farm on request";
// //   var resultStr = showLess ? str.slice(0, 500) : str;
// //   const changeShow = () => {
// //     setshowLess(!showLess);
// //   };

// //   const [value, setValue] = React.useState(null);
// //   const [inputValue, setInputValue] = React.useState("");
// //   const [options, setOptions] = React.useState([]);
// //   const loaded = React.useRef(false);

// //   if (typeof window !== "undefined" && !loaded.current) {
// //     if (!document.querySelector("#google-maps")) {
// //       loadScript(
// //         `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
// //         document.querySelector("head"),
// //         "google-maps"
// //       );
// //     }

// //     loaded.current = true;
// //   }

// //   const fetch = React.useMemo(
// //     () =>
// //       debounce((request, callback) => {
// //         autocompleteService.current.getPlacePredictions(request, callback);
// //       }, 400),
// //     []
// //   );

// //   React.useEffect(() => {
// //     let active = true;

// //     if (!autocompleteService.current && window.google) {
// //       autocompleteService.current =
// //         new window.google.maps.places.AutocompleteService();
// //     }
// //     if (!autocompleteService.current) {
// //       return undefined;
// //     }

// //     if (inputValue === "") {
// //       setOptions(value ? [value] : []);
// //       return undefined;
// //     }

// //     fetch({ input: inputValue }, (results) => {
// //       if (active) {
// //         let newOptions = [];

// //         if (value) {
// //           newOptions = [value];
// //         }

// //         if (results) {
// //           newOptions = [...newOptions, ...results];
// //         }

// //         setOptions(newOptions);
// //       }
// //     });

// //     return () => {
// //       active = false;
// //     };
// //   }, [value, inputValue, fetch]);
// //   const [activeKey, setActiveKey] = useState("description");

// //   const handleSelect = (key) => {
// //     if (key === activeKey) {
// //       return;
// //     }
// //     setActiveKey(key);
// //   };

// //   return (
// //     <section>
// //       <div className="oprabhu-main">
// //         <img src={dorm4} alt="" />
// //       </div>

// //       <div className="responsive-wrapper">
// //         <Card id="search-card-grid" className="responsive-card">
// //           <Row className="align-items-center" style={{ padding: "23px" }}>
// //             <Col className="d-flex align-items-center" xs="auto">
// //               <Autocomplete
// //                 id="city-search"
// //                 options={cities}
// //                 getOptionLabel={(option) => option.label}
// //                 renderInput={(params) => (
// //                   <TextField
// //                     {...params}
// //                     label="Where do you want to go"
// //                     variant="outlined"
// //                     fullWidth
// //                     style={{ minWidth: 250, marginBottom: 0 }}
// //                     error={cityError}
// //                     helperText={cityError ? "City is required" : ""}
// //                   />
// //                 )}
// //                 onChange={(event, newValue) => {
// //                   setSelectedCity(newValue);
// //                   setCityError(false); // Reset error when city is selected
// //                 }}
// //                 value={selectedCity}
// //               />
// //             </Col>
// //             <Col className="d-flex align-items-center" xs="auto">
// //               <LocalizationProvider
// //                 dateAdapter={AdapterDayjs}
// //                 adapterLocale="en-gb"
// //               >
// //                 <DateRangePicker
// //                   // localeText={{ start: "Check-in", end: "Check-out" }}
// //                   // value={selectedDates}
// //                   // onChange={(newValue) => {
// //                   //   setSelectedDates(newValue);
// //                   // }}
// //                   ranges={[selectionRange]}
// //                   onChange={handleSelect}
// //                   renderInput={(startProps, endProps) => (
// //                     <>
// //                       <TextField
// //                         {...startProps}
// //                         variant="outlined"
// //                         fullWidth
// //                         style={{ minWidth: 150 }}
// //                       />
// //                       <TextField
// //                         {...endProps}
// //                         variant="outlined"
// //                         fullWidth
// //                         style={{ minWidth: 150 }}
// //                       />
// //                     </>
// //                   )}
// //                 />
// //               </LocalizationProvider>
// //             </Col>
// //             <Col className="d-flex align-items-center" xs="auto">
// //               <div style={{ display: "flex", alignItems: "center" }}>
// //                 <TextField
// //                   label="Select Guests"
// //                   variant="outlined"
// //                   fullWidth
// //                   value={selectedGuest.label}
// //                   onClick={handleGuestClick}
// //                   style={{ minWidth: 150, marginBottom: 0 }}
// //                   readOnly
// //                 />
// //                 <Popover
// //                   id={id}
// //                   open={open}
// //                   anchorEl={anchorEl}
// //                   onClose={handleGuestClose}
// //                   anchorOrigin={{
// //                     vertical: "bottom",
// //                     horizontal: "center",
// //                   }}
// //                   transformOrigin={{
// //                     vertical: "top",
// //                     horizontal: "center",
// //                   }}
// //                 >
// //                   <div
// //                     style={{
// //                       padding: "10px",
// //                       display: "flex",
// //                       alignItems: "center",
// //                     }}
// //                   >
// //                     <Button
// //                       variant="outline-secondary"
// //                       onClick={() => handleGuestChange(-1)}
// //                     >
// //                       -
// //                     </Button>
// //                     <span style={{ margin: "0 10px" }}>
// //                       {selectedGuest.value}
// //                     </span>
// //                     <Button
// //                       variant="outline-secondary"
// //                       onClick={() => handleGuestChange(1)}
// //                     >
// //                       +
// //                     </Button>
// //                   </div>
// //                 </Popover>
// //               </div>
// //             </Col>
// //             <Col className="d-flex align-items-center" xs="auto">
// //               <Button
// //                 variant="primary"
// //                 style={{ backgroundColor: "blue", color: "white" }}
// //                 onClick={handleSearch}
// //               >
// //                 Let's Go
// //               </Button>
// //             </Col>
// //           </Row>
// //         </Card>
// //       </div>

// //       <div className="container  opcontnt">
// //         <div className=" row justify-content-center  oprabhu-contnt">
// //           <Container style={{ marginTop: "10px" }}>
// //             <div>
// //               <Typography
// //                 as="h5"
// //                 style={{
// //                   fontFamily: "Open Sans",
// //                   fontSize: "30px",
// //                   fontWeight: "400",
// //                 }}
// //               >
// //                 Prabhu's place in Ooty
// //               </Typography>
// //               <Typography
// //                 as="h6"
// //                 style={{
// //                   fontFamily: "Roboto",
// //                   fontSize: "18px",
// //                   fontWeight: "400",
// //                 }}
// //               >
// //                 <LocationOnIcon style={{ fontSize: "30px" }} /> ooty, Tamil
// //                 Nadu, India
// //               </Typography>
// //             </div>
// //           </Container>
// //           <div className="oprabhu-main-container">
// //             <div
// //               style={{ height: "auto", width: "auto", boxSizing: "border-box" }}
// //             >
// //               <div className="oprabhu-main-rooms-container">
// //                 <div className="tabs-container">
// //                   <Tabs
// //                     id="controlled-tab-example"
// //                     activeKey={activeKey}
// //                     onSelect={handleSelect}
// //                     className="mb-3"
// //                     style={{ overflowX: "auto" }}

// //                     //   justify
// //                   >
// //                     <Tab
// //                       eventKey="description"
// //                       title={
// //                         <span
// //                           onClick={() => handleTabClick("description")}
// //                           style={{
// //                             color:
// //                               activeTab === "description" ? "blue" : "black",
// //                           }}
// //                         >
// //                           <InfoIcon />
// //                           Description
// //                         </span>
// //                       }
// //                     >
// //                       <div className="oprabhu-tabs-description">
// //                         <p style={{ fontFamily: "Roboto", fontSize: "16px" }}>
// //                           {resultStr}
// //                         </p>
// //                         <Button variant="text" onClick={changeShow}>
// //                           {showLess ? "Show more" : "Show Less"}
// //                         </Button>
// //                       </div>
// //                     </Tab>
// //                     <Tab
// //                       eventKey="Ameinites"
// //                       title={
// //                         <span
// //                           onClick={() => handleTabClick("Ameinites")}
// //                           style={{
// //                             color: activeTab === "Ameinites" ? "blue" : "black",
// //                           }}
// //                         >
// //                           <LocalActivityOutlinedIcon /> Ameinites
// //                         </span>
// //                       }
// //                     >
// //                       <div className="facilities">
// //                         <div style={{ display: "flex" }}>
// //                           <div className="amenites">
// //                             <div className="image-wrapper">
// //                               <img
// //                                 className="facilities-ooty"
// //                                 src={parking}
// //                                 alt=""
// //                               />
// //                               <div className="hover-text">
// //                                 Parking Available
// //                               </div>
// //                             </div>
// //                             <h6 className="facilities-ooty-text">parking</h6>
// //                           </div>
// //                           <div className="amenites">
// //                             <div className="image-wrapper">
// //                               <img
// //                                 className="facilities-ooty"
// //                                 src={wifi1}
// //                                 alt=""
// //                               />
// //                               <div className="hover-text">Wifi Available</div>
// //                             </div>
// //                             <h6 className="facilities-ooty-text">wifi</h6>
// //                           </div>

// //                           <div className="amenites">
// //                             <div className="image-wrapper">
// //                               <img
// //                                 className="facilities-ooty"
// //                                 src={ac}
// //                                 alt=""
// //                               />
// //                               <div className="hover-text">Ac Available</div>
// //                             </div>
// //                             <h6 className="facilities-ooty-text">Ac</h6>
// //                           </div>

// //                           <div className="amenites">
// //                             <div className="image-wrapper">
// //                               <img
// //                                 className="facilities-ooty"
// //                                 src={fire}
// //                                 alt=""
// //                               />
// //                               <div className="hover-text">
// //                                 Bone Fire Available
// //                               </div>
// //                             </div>
// //                             <h6 className="facilities-ooty-text">Bone Fire</h6>
// //                           </div>
// //                           <div className="amenites">
// //                             <div className="image-wrapper">
// //                               <img
// //                                 className="facilities-ooty"
// //                                 src={cafe}
// //                                 alt=""
// //                               />
// //                               <div className="hover-text">Cafe Available</div>
// //                             </div>
// //                             <h6 className="facilities-ooty-text">Cafe</h6>
// //                           </div>
// //                           <div className="amenites">
// //                             <div className="image-wrapper">
// //                               <img
// //                                 className="facilities-ooty"
// //                                 src={publictoilet}
// //                                 alt=""
// //                               />
// //                               <div className="hover-text">
// //                                 Commonwashroom Available
// //                               </div>
// //                             </div>
// //                             <h6 className="facilities-ooty-text">washroom</h6>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     </Tab>
// //                     <Tab
// //                       eventKey="location"
// //                       title={
// //                         <span
// //                           onClick={() => handleTabClick("location")}
// //                           style={{
// //                             color: activeTab === "location" ? "blue" : "black",
// //                           }}
// //                         >
// //                           <FmdGoodIcon /> Location
// //                         </span>
// //                       }
// //                     >
// //                       <Typography
// //                         variant="h5"
// //                         style={{ fontFamily: "Open Sans" }}
// //                       >
// //                         Prabhus place Location
// //                       </Typography>
// //                       <iframe
// //                         width="80%"
// //                         height="400"
// //                         frameborder="0"
// //                         scrolling="no"
// //                         marginheight="0"
// //                         marginwidth="0"
// //                         src="
// //                           https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1/42%20Tonga%20Road,%20Bandishola,%20Ooty,%20Tamil%20Nadu,%20India,%20Ooty,%20India+(World%20Of%20Hostels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
// //                       >
// //                         <a href="https://www.gps.ie/">gpstrackers</a>
// //                       </iframe>
// //                     </Tab>

// //                     <Tab
// //                       eventKey="review"
// //                       title={
// //                         <span
// //                           style={{
// //                             color: activeTab === "review" ? "blue" : "black",
// //                           }}
// //                         >
// //                           <RateReviewIcon /> Review
// //                         </span>
// //                       }
// //                     >
// //                       <Typography
// //                         as="h6"
// //                         style={{
// //                           backgroundColor: "tomato",
// //                           margin: "20px",
// //                           fontFamily: "Roboto",
// //                         }}
// //                       >
// //                         ⭐ 100% genuine Review from real hostel travelers like
// //                         you!
// //                       </Typography>
// //                       <div
// //                         style={{ display: "flex", gap: "30px", margin: "30px" }}
// //                       >
// //                         <div>
// //                           <h1
// //                             style={{
// //                               backgroundColor: "tomato",
// //                               color: "white",
// //                             }}
// //                           >
// //                             6.9
// //                           </h1>
// //                         </div>
// //                         <div>
// //                           <h5 style={{ fontFamily: "Roboto" }}>Good</h5>
// //                           <h6 style={{ fontFamily: "Roboto" }}>
// //                             29 Total Reviews
// //                           </h6>
// //                         </div>
// //                       </div>
// //                       <Row style={{ margin: "10px" }}>
// //                         <Col>
// //                           {[
// //                             { label: "Security", value: 85 },
// //                             { label: "Staff", value: 90 },
// //                             { label: "Cleanliness", value: 80 },
// //                             { label: "Value For Money", value: 80 },
// //                           ].map((item, index) => (
// //                             <div key={index} style={{ marginBottom: "10px" }}>
// //                               <div
// //                                 style={{
// //                                   display: "flex",
// //                                   justifyContent: "space-between",
// //                                 }}
// //                               >
// //                                 <Typography
// //                                   as="h6"
// //                                   style={{
// //                                     fontSize: "15px",
// //                                     fontFamily: "Roboto",
// //                                   }}
// //                                 >
// //                                   {item.label}
// //                                 </Typography>
// //                                 <Typography
// //                                   as="h6"
// //                                   style={{
// //                                     fontSize: "15px",
// //                                     fontFamily: "Roboto",
// //                                   }}
// //                                 >
// //                                   {item.value / 10}
// //                                 </Typography>
// //                               </div>
// //                               <ProgressBar
// //                                 variant="danger"
// //                                 now={item.value}
// //                                 style={{ height: "5px" }}
// //                               />
// //                             </div>
// //                           ))}
// //                         </Col>
// //                         <Col>
// //                           {[
// //                             { label: "Location", value: 80 },
// //                             { label: "Atmosphere", value: 85 },
// //                             { label: "Facilities", value: 85 },
// //                           ].map((item, index) => (
// //                             <div key={index} style={{ marginBottom: "10px" }}>
// //                               <div
// //                                 style={{
// //                                   display: "flex",
// //                                   justifyContent: "space-between",
// //                                 }}
// //                               >
// //                                 <Typography
// //                                   as="h6"
// //                                   style={{
// //                                     fontSize: "15px",
// //                                     fontFamily: "Roboto",
// //                                   }}
// //                                 >
// //                                   {item.label}
// //                                 </Typography>
// //                                 <Typography
// //                                   as="h6"
// //                                   style={{
// //                                     fontSize: "15px",
// //                                     fontFamily: "Roboto",
// //                                   }}
// //                                 >
// //                                   {item.value / 10}
// //                                 </Typography>
// //                               </div>
// //                               <ProgressBar
// //                                 variant="danger"
// //                                 now={item.value}
// //                                 style={{ height: "5px" }}
// //                               />
// //                             </div>
// //                           ))}
// //                         </Col>
// //                       </Row>
// //                       {reviewData.map((review, index) => (
// //                         <div
// //                           key={index}
// //                           style={{
// //                             marginLeft: "30px",
// //                             marginTop: "30px",
// //                             display: "flex",
// //                           }}
// //                         >
// //                           <div>
// //                             <PersonIcon />
// //                             <Typography
// //                               as="h6"
// //                               style={{ fontFamily: "Roboto", fontSize: "18px" }}
// //                             >
// //                               {review.name}
// //                             </Typography>
// //                             <Typography
// //                               as="h6"
// //                               style={{ color: "gray", fontSize: "14px" }}
// //                             >
// //                               {review.gender}, {review.age}
// //                             </Typography>
// //                             <Typography
// //                               as="h6"
// //                               style={{ color: "gray", fontSize: "14px" }}
// //                             >
// //                               {review.status}
// //                             </Typography>
// //                             <Typography
// //                               as="h6"
// //                               style={{ color: "orange", fontSize: "14px" }}
// //                             >
// //                               {review.reviews} reviews
// //                             </Typography>
// //                           </div>
// //                           <div
// //                             style={{
// //                               marginLeft: "60px",
// //                               textAlign: "start",
// //                               marginTop: "10px",
// //                             }}
// //                           >
// //                             <div style={{ display: "flex", gap: "15px" }}>
// //                               <h4
// //                                 style={{
// //                                   backgroundColor: "tomato",
// //                                   color: "white",
// //                                   borderRadius: "5px",
// //                                 }}
// //                               >
// //                                 {review.rating}
// //                               </h4>
// //                               <h6 style={{ color: "tomato" }}>
// //                                 {review.rating >= 8
// //                                   ? "Very Good"
// //                                   : review.rating >= 5
// //                                   ? "Good"
// //                                   : "Average"}
// //                               </h6>
// //                             </div>
// //                             <p
// //                               style={{ fontFamily: "Roboto", fontSize: "17px" }}
// //                             >
// //                               {review.feedback}
// //                             </p>
// //                           </div>
// //                         </div>
// //                       ))}
// //                     </Tab>
// //                     <Tab
// //                       eventKey="gallery"
// //                       title={
// //                         <span
// //                           onClick={() => handleTabClick("gallery")}
// //                           style={{
// //                             color: activeTab === "gallery" ? "blue" : "black",
// //                           }}
// //                         >
// //                           {" "}
// //                           <CollectionsOutlinedIcon /> Gallery
// //                         </span>
// //                       }
// //                     >
// //                       <div className={model ? "model open" : "model"}>
// //                         <img src={tempimgsrc} alt="" />
// //                       </div>
// //                       <div className="gallery">
// //                         {data.map((items, index) => {
// //                           return (
// //                             <div
// //                               className="pics"
// //                               key={index}
// //                               onClick={() => getImg(items.imgsrc)}
// //                             >
// //                               <img
// //                                 src={items.imgsrc}
// //                                 style={{ width: "100%" }}
// //                                 alt=""
// //                               />
// //                             </div>
// //                           );
// //                         })}
// //                       </div>
// //                     </Tab>
// //                     <Tab
// //                       eventKey="thingstodo"
// //                       title={
// //                         <span
// //                           onClick={() => handleTabClick("thingstodo")}
// //                           style={{
// //                             color:
// //                               activeTab === "thingstodo" ? "blue" : "black",
// //                           }}
// //                         >
// //                           <ModeOfTravelIcon /> Tours
// //                         </span>
// //                       }
// //                     >
// //                       <div>
// //                         <Container
// //                           style={{
// //                             height: "auto",
// //                             overflowX: "scroll",
// //                             whiteSpace: "nowrap",
// //                           }}
// //                         >
// //                           <Row className="flex-nowrap">
// //                             {places.map((place, index) => (
// //                               <Col
// //                                 key={index}
// //                                 style={{
// //                                   display: "inline-block",
// //                                   float: "none",
// //                                 }}
// //                               >
// //                                 <Card
// //                                   style={{
// //                                     width: "18rem",
// //                                     display: "inline-block",
// //                                     margin: "0 10px",
// //                                   }}
// //                                 >
// //                                   <Card.Img
// //                                     variant="top"
// //                                     src={place.imageUrl}
// //                                     style={{ height: "150px" }}
// //                                   />
// //                                   <Card.Body>
// //                                     <Card.Title
// //                                       style={{ fontFamily: "Open Sans" }}
// //                                     >
// //                                       {place.title}
// //                                     </Card.Title>
// //                                     <Card.Text
// //                                       style={{ fontFamily: "Open Sans" }}
// //                                     >
// //                                       Price: ${place.price}
// //                                     </Card.Text>
// //                                     <div
// //                                       style={{
// //                                         display: "flex",
// //                                         justifyContent: "space-evenly",
// //                                         gap: "40px",
// //                                       }}
// //                                     >
// //                                       <ReactStars
// //                                         count={5}
// //                                         value={place.rating}
// //                                         size={24}
// //                                         activeColor="#006400"
// //                                         edit={false}
// //                                       />
// //                                       {thingBookings[index].isBooked ? (
// //                                         <div>
// //                                           <div className="d-flex align-items-center">
// //                                             <Button
// //                                               variant="secondary"
// //                                               onClick={() =>
// //                                                 handleThingDecrement(index)
// //                                               }
// //                                             >
// //                                               -
// //                                             </Button>
// //                                             <span className="mx-3">
// //                                               {thingBookings[index].quantity}
// //                                             </span>
// //                                             <Button
// //                                               variant="secondary"
// //                                               onClick={() =>
// //                                                 handleThingIncrement(index)
// //                                               }
// //                                             >
// //                                               +
// //                                             </Button>
// //                                           </div>
// //                                         </div>
// //                                       ) : (
// //                                         <Button
// //                                           variant="warning"
// //                                           onClick={() =>
// //                                             handleThingBookNow(index)
// //                                           }
// //                                         >
// //                                           Book Tour
// //                                         </Button>
// //                                       )}
// //                                     </div>
// //                                   </Card.Body>
// //                                 </Card>
// //                               </Col>
// //                             ))}
// //                           </Row>
// //                         </Container>
// //                       </div>
// //                     </Tab>
// //                   </Tabs>
// //                 </div>

// //                 <Container className="mt-4">
// //                   <Row className="gx-3">
// //                     {ootyapartments.map((apt, index) => (
// //                       <Col key={index} xs={12} md={12} lg={12} className="mb-4">
// //                         <Card className="h-100">
// //                           <Row className="p-3">
// //                             <Col md={5}>
// //                               <Carousel>
// //                                 {apt.images.map((image, idx) => (
// //                                   <Carousel.Item key={idx} interval={10000}>
// //                                     <img
// //                                       className="d-block w-100"
// //                                       src={image}
// //                                       alt={`Slide ${idx}`}
// //                                       style={{
// //                                         height: "250px",
// //                                         position: "relative",
// //                                         borderRadius: "10px",
// //                                       }}
// //                                     />
// //                                   </Carousel.Item>
// //                                 ))}
// //                               </Carousel>
// //                             </Col>
// //                             <Col md={7}>
// //                               <div className="d-flex flex-column h-100 justify-content-between">
// //                                 <div>
// //                                   <Typography
// //                                     variant="h5"
// //                                     style={{
// //                                       fontFamily: "Open Sans",
// //                                       fontSize: "21px",
// //                                       fontWeight: "500",
// //                                       marginTop: "8px",
// //                                     }}
// //                                   >
// //                                     {apt.name}
// //                                   </Typography>
// //                                   <p
// //                                     style={{
// //                                       fontSize: "16px",
// //                                       marginTop: "5px",
// //                                       fontFamily: "Roboto",
// //                                       width: "auto",
// //                                     }}
// //                                   >
// //                                     {apt.description}
// //                                   </p>
// //                                   <div className="d-flex justify-content-center align-items-center mt-3">
// //                                     <div className="mt-2">
// //                                       <h5
// //                                         style={{
// //                                           color: "palevioletred",
// //                                           fontFamily: "Roboto",
// //                                           fontSize: "15px",
// //                                         }}
// //                                       >
// //                                         Apartment
// //                                       </h5>
// //                                       <p
// //                                         style={{
// //                                           fontFamily: "Roboto",
// //                                           fontSize: "15px",
// //                                         }}
// //                                       >
// //                                         {apt.discountedPrice}/night
// //                                       </p>
// //                                     </div>
// //                                     <div className="ms-auto">
// //                                       {/* {roomBookings[index].quantity === 0 ? ( */}
// //                                       <button
// //                                         style={{ width: "200px" }}
// //                                         className="add-btn"
// //                                         onClick={() =>
// //                                           window.open(
// //                                             "https://bookingengine.stayflexi.com/?hotel_id=29067",
// //                                             "_blank"
// //                                           )
// //                                         }
// //                                       >
// //                                         Book now
// //                                       </button>
// //                                       {/* ) : ( */}
// //                                       {/* <div className="bng-counter-cnts">
// //                                           <button
// //                                             className="decrement-btn"
// //                                             onClick={() =>
// //                                               handleRoomDecrement(index)
// //                                             }
// //                                           >
// //                                             -
// //                                           </button>
// //                                           <span className="count-dis">
// //                                             <PersonOutlinedIcon
// //                                               style={{ marginBottom: "5px" }}
// //                                             />
// //                                             {roomBookings[index].quantity}
// //                                           </span>
// //                                           <button
// //                                             className="increment-btn"
// //                                             onClick={() =>
// //                                               handleRoomIncrement(index)
// //                                             }
// //                                           >
// //                                             +
// //                                           </button>
// //                                         </div> */}
// //                                       {/* )} */}
// //                                     </div>
// //                                   </div>
// //                                 </div>
// //                               </div>
// //                             </Col>
// //                           </Row>
// //                         </Card>
// //                       </Col>
// //                     ))}
// //                   </Row>
// //                 </Container>
// //               </div>
// //             </div>

// //             {/* <div
// //               className="my-selection-card"
// //               style={{ height: "auto", marginTop: "20px" }}
// //             >
// //               <Card style={{ border: "1px solid #ccc", width: "270px" }}>
// //                 <div style={{ padding: "20px", height: "auto" }}>
// //                   <Typography
// //                     variant="h6"
// //                     style={{
// //                       fontSize: "18px",
// //                       textAlign: "center",
// //                       fontFamily: "Open Sans",
// //                       fontWeight: "500",
// //                     }}
// //                   >
// //                     My Selection
// //                   </Typography>
// //                   <div>
// //                     <div
// //                       style={{
// //                         display: "inline",
// //                         gap: "70px",
// //                         marginTop: "20px",
// //                       }}
// //                     >
// //                       <Typography
// //                         variant="h6"
// //                         style={{ fontFamily: "Open Sans", fontSize: "17px" }}
// //                       >
// //                         Tour Amount: {totalThingAmount} Rs
// //                       </Typography>
// //                       <Typography
// //                         variant="h6"
// //                         style={{ fontFamily: "Open Sans", fontSize: "17px" }}
// //                       >
// //                         Room Amount: {totalRoomAmount} Rs
// //                       </Typography>
// //                     </div>
// //                     <Typography
// //                       variant="h6"
// //                       style={{ fontFamily: "Open Sans", fontSize: "17px" }}
// //                     >
// //                       Total Amount: {totalAmount} Rs
// //                     </Typography>
// //                     <Button
// //                       variant="danger"
// //                       style={{ width: "220px", marginTop: "10px" }}
// //                     >
// //                       Booking now
// //                     </Button>
// //                   </div>
// //                   <div style={{ marginTop: "20px" }}>
// //                     <Typography
// //                       variant="h6"
// //                       style={{ fontFamily: "Open Sans", fontSize: "17px" }}
// //                     >
// //                       Choose Your Room
// //                     </Typography>
// //                     <Typography
// //                       variant="h6"
// //                       style={{ fontFamily: "Open Sans", fontSize: "15px" }}
// //                       color="gray"
// //                     >
// //                       <CheckIcon />
// //                       Instant Confirmation
// //                     </Typography>
// //                     <Typography
// //                       variant="h6"
// //                       style={{ fontFamily: "Open Sans", fontSize: "15px" }}
// //                       color="gray"
// //                     >
// //                       {" "}
// //                       <CheckIcon />
// //                       No Booking Fees
// //                     </Typography>
// //                     <Typography
// //                       variant="h6"
// //                       style={{ fontFamily: "Open Sans", fontSize: "15px" }}
// //                       color="gray"
// //                     >
// //                       {" "}
// //                       <CheckIcon />
// //                       Booking Only Takes 2 Min
// //                     </Typography>
// //                   </div>
// //                   <div
// //                     style={{ width: "100%", justifyContent: "space-around" }}
// //                   >
// //                     <img
// //                       src="https://cdn-icons-png.flaticon.com/128/179/179457.png"
// //                       style={{ width: "50px", height: "auto" }}
// //                       alt="icon"
// //                     />
// //                     <img
// //                       src="https://cdn-icons-png.flaticon.com/128/11041/11041016.png"
// //                       style={{ width: "50px", height: "auto" }}
// //                       alt="icon"
// //                     />
// //                     <img
// //                       src="https://cdn-icons-png.flaticon.com/128/11041/11041055.png"
// //                       style={{ width: "50px", height: "auto" }}
// //                       alt="icon"
// //                     />
// //                   </div>
// //                 </div>
// //               </Card>
// //             </div> */}
// //           </div>
// //         </div>
// //       </div>

// //       <FooterContainer />
// //     </section>
// //   );
// // };

// // export default Oprabhu;
// import React, { useEffect, useState } from "react";
// import "./Oprabhu.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// import { debounce } from "@mui/material/utils";
// import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
// import dorm1 from "../../assets/Ootyassets/dorm1.jpg";
// import dorm3 from "../../assets/Ootyassets/dorm3.webp";
// import dorm4 from "../../assets/Ootyassets/dorm4.jpg";
// import dorm5 from "../../assets/Ootyassets/dorm5.webp";
// import dorm8 from "../../assets/Ootyassets/dorm8.jpg";
// import dorm7 from "../../assets/Ootyassets/dorm7.jpg";
// import dorm9 from "../../assets/Ootyassets/dorm9.jpg";
// import ReactStars from "react-rating-stars-component";

// import privateroom3 from "../../assets/Ootyassets/privateroom3.jpeg";
// import privateroom4 from "../../assets/Ootyassets/privateroom4.jpeg";
// import privateroom5 from "../../assets/Ootyassets/privateroom5.jpeg";

// import PersonIcon from "@mui/icons-material/Person";

// import { Carousel, ProgressBar } from "react-bootstrap";

// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { Typography } from "@mui/material";
// import image1 from "../../assets/Ootyassets/image1.jpeg";

// import Tab from "react-bootstrap/Tab";
// import Tabs from "react-bootstrap/Tabs";

// import InfoIcon from "@mui/icons-material/Info";
// import FmdGoodIcon from "@mui/icons-material/FmdGood";
// import RateReviewIcon from "@mui/icons-material/RateReview";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CheckIcon from "@mui/icons-material/Check";
// import wifi1 from "../../assets/wifi1.png";
// import publictoilet from "../../assets/publictoilet.png";
// import fire from "../../assets/fire.png";
// import parking from "../../assets/parking.png";
// import ac from "../../assets/ac.png";
// import cafe from "../../assets/cafe.png";
// import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
// import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
// import dayjs from "dayjs";
// import { Popover } from "@mui/material";
// import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
// import { useNavigate } from "react-router-dom";
// import FooterContainer from "../../components/footer/FooterContainer";
// import temple from "../../assets/chinnaaasets/chpics/temple.jpg";
// import tea from "../../assets/chinnaaasets/chpics/tea.webp";
// import train from "../../assets/chinnaaasets/chpics/train.jpg";
// import rosegarden from "../../assets/chinnaaasets/chpics/rosegarden.jpg";
// import ootygarden from "../../assets/chinnaaasets/chpics/ootygarden.jpg";
// import falls from "../../assets/chinnaaasets/chpics/falls.jpg";
// import ootylake from "../../assets/chinnaaasets/chpics/ootylake.jpg";
// import church from "../../assets/chinnaaasets/chpics/church.jpg";
// import Doddabettateles from "../../assets/chinnaaasets/chpics/Doddabettateles.jpg";
// import { Card, Button, Container, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const GOOGLE_MAPS_API_KEY = "AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y";

// // const cities = [
// //   { label: "Bengaluru" },
// //   { label: "Ooty" },
// //   { label: "Chennai" },

// // ];
// const cities = [
//   { label: "Bengaluruprabhuplace" },
//   { label: "Ootyprabhusplace" },
//   { label: "Chennaiprabhusplace" },
// ];
// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement("script");
//   script.setAttribute("async", "");
//   script.setAttribute("id", id);
//   script.src = src;
//   position.appendChild(script);
// }

// const autocompleteService = { current: null };

// const Ootyprabhusplace = () => {
//   const [selectedDates, setSelectedDates] = useState([
//     dayjs(),
//     dayjs().add(1, "day"),
//   ]);
//   const [selectedCity, setSelectedCity] = useState(null);
//   const [selectedGuest, setSelectedGuest] = useState({
//     label: "1 Guest",
//     value: 1,
//   });
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [cityError, setCityError] = useState(false);

//   const navigate = useNavigate();

//   const handleSearch = () => {
//     if (selectedCity) {
//       navigate(`/${selectedCity.label.toLowerCase()}`);
//     } else {
//       setCityError(true);
//     }
//   };
//   const handleGuestChange = (increment) => {
//     setSelectedGuest((prevGuest) => {
//       let newGuestCount = prevGuest.value + increment;
//       newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
//       return {
//         label: `${newGuestCount} Guest${newGuestCount > 1 ? "s" : ""}`,
//         value: newGuestCount,
//       };
//     });
//   };

//   const handleGuestClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleGuestClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "guest-popover" : undefined;

//   const ootyapartments = [
//     {
//       name: "Private Room",
//       images: [privateroom3, privateroom5, privateroom4],
//       description:
//         "Nice sized private room comes with king size bed and an attached bath some rooms are with view of the hills and wake up to church bells.",
//       discountedPrice: "800",
//     },

//     {
//       name: "Mix Dorm One with ensuite",
//       images: [dorm1, dorm3, image1, dorm5],
//       description:
//         "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
//       discountedPrice: "600",
//     },
//     {
//       name: "Mix Dorm Two with ensuite",
//       images: [image1, dorm9, dorm8, dorm5],
//       description:
//         "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
//       discountedPrice: "500",
//     },
//     {
//       name: "Mix Dorm Three with ensuite",
//       images: [dorm3, dorm7, dorm9, image1],
//       description:
//         "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
//       discountedPrice: "1000",
//     },
//   ];
//   const [key, setKey] = useState("description");
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [key]);

//   const selection = {
//     title: "My Selection",
//   };

//   const reviewData = [
//     {
//       name: "ashokreddy",
//       gender: "Male",
//       age: "20-26",
//       status: "Novice Nomed",
//       reviews: 2,
//       rating: 6.1,
//       feedback:
//         "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
//     },
//     {
//       name: "rajakullayappa",
//       gender: "Male",
//       age: "20-26",
//       status: "Novice Nomed",
//       reviews: 5,
//       rating: 7.5,
//       feedback:
//         "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
//     },
//     {
//       name: "Viratkohli",
//       gender: "Male",
//       age: "30-36",
//       status: "Novice Nomed",
//       reviews: 1,
//       rating: 10,
//       feedback:
//         "An example of what a hostel should be. The hospital and friendliness of the staff made me feel very comfortable and relaxed.",
//     },
//   ];

//   const places = [
//     {
//       title: "Botanical Garden ",
//       description:
//         " Botanical Gardens, established in 1847, are spread over 55 acres and showcase a wide variety of plants, trees, and flowers. ",
//       imageUrl: ootygarden,
//       price: "500",
//     },
//     {
//       title: "Ooty Lake",
//       description:
//         "Ooty Lake is an artificial lake surrounded by eucalyptus trees and greenery. Its a popular spot for boating and picnics. ",
//       imageUrl: ootylake,
//       price: "500",
//     },
//     {
//       title: "Pykara Lake and Falls",
//       description:
//         "Pykara Lake, with its serene waters, lush surroundings, and the charm of its nearby waterfalls, offers a perfect getaway for those looking  Nilgiris.",
//       imageUrl: falls,
//       price: "500",
//     },
//     {
//       title: "Nilgiri Mountain Railway",
//       description:
//         "The Nilgiri Mountain Railway, also known as the Ooty Toy Train, is a UNESCO World Heritage Site.  ",
//       imageUrl: train,
//       price: "500",
//     },

//     {
//       title: "Elk Hill Murugan Temple",
//       description:
//         "The Elk Hill Murugan Temple is dedicated to Lord Murugan and offers a panoramic views of Ooty.",
//       imageUrl: temple,
//       price: "500",
//     },
//     {
//       title: "st. stephen church ooty",
//       description:
//         "St. Stephens Church, built in 1829, is one of the oldest churches in the Nilgiris.",
//       imageUrl: church,
//       price: "500",
//     },
//     {
//       title: "Ooty Tea Factory,Museum",
//       description:
//         "The Tea Factory and Museum offers an insight into the tea-making process. ",
//       imageUrl: tea,
//       price: "500",
//     },
//     {
//       title: "Rose Garden",
//       description:
//         "The Ooty Rose Garden, also known as the Jayalalithaa Rose Garden, is one of the largest rose gardens in India.",
//       imageUrl: rosegarden,
//       price: "500",
//     },
//     {
//       title: "Doddabetta Peak",
//       description:
//         "Doddabetta Peak is the highest point in the Nilgiri Mountains, standing at 2,637 meters above sea level. ",
//       imageUrl: Doddabettateles,
//       price: "500",
//     },
//   ];
//   //
//   // State for bookings of activities (things)
//   const [thingBookings, setThingBookings] = useState(
//     places.map(() => ({ isBooked: false, quantity: 0 }))
//   );

//   // State for bookings of rooms
//   const [roomBookings, setRoomBookings] = useState(
//     ootyapartments.map(() => ({ isBooked: false, quantity: 0, price: 0 }))
//   );

//   // Handler to book an activity (thing)
//   const handleThingBookNow = (index) => {
//     const newThingBookings = [...thingBookings];
//     newThingBookings[index].isBooked = true;
//     newThingBookings[index].quantity = 1;
//     setThingBookings(newThingBookings);
//   };

//   // Handler to increment the quantity of an activity (thing)
//   const handleThingIncrement = (index) => {
//     const newThingBookings = [...thingBookings];
//     newThingBookings[index].quantity += 1;
//     setThingBookings(newThingBookings);
//   };

//   // Handler to decrement the quantity of an activity (thing)
//   const handleThingDecrement = (index) => {
//     const newThingBookings = [...thingBookings];
//     if (newThingBookings[index].quantity > 0) {
//       newThingBookings[index].quantity -= 1;
//       if (newThingBookings[index].quantity === 0) {
//         newThingBookings[index].isBooked = false;
//       }
//       setThingBookings(newThingBookings);
//     }
//   };

//   // Handler to add a room booking
//   const handleAddRoom = (index) => {
//     const newRoomBookings = [...roomBookings];
//     newRoomBookings[index].isBooked = true;
//     newRoomBookings[index].quantity = 1;
//     newRoomBookings[index].price = ootyapartments[index].discountedPrice;
//     setRoomBookings(newRoomBookings);
//   };

//   // Handler to increment the quantity of a room booking
//   const handleRoomIncrement = (index) => {
//     const newRoomBookings = [...roomBookings];
//     newRoomBookings[index].quantity += 1;
//     setRoomBookings(newRoomBookings);
//   };

//   // Handler to decrement the quantity of a room booking
//   const handleRoomDecrement = (index) => {
//     const newRoomBookings = [...roomBookings];
//     if (newRoomBookings[index].quantity > 0) {
//       newRoomBookings[index].quantity -= 1;
//       if (newRoomBookings[index].quantity === 0) {
//         newRoomBookings[index].isBooked = false;
//         newRoomBookings[index].price = 0;
//       }
//       setRoomBookings(newRoomBookings);
//     }
//   };

//   // Calculate total amount for things bookings
//   const totalThingAmount = thingBookings.reduce(
//     (acc, booking, index) => acc + booking.quantity * places[index].price,
//     0
//   );

//   // Calculate total amount for room bookings
//   const totalRoomAmount = roomBookings.reduce(
//     (acc, booking) => acc + booking.quantity * booking.price,
//     0
//   );

//   // Calculate total amount for both things and rooms
//   const totalAmount = totalThingAmount + totalRoomAmount;

//   //
//   const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight - 320); // Window height minus 50px

//   useEffect(() => {
//     const handleResize = () => setSidebarHeight(window.innerHeight - 320);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const data = [
//     {
//       div: 1,
//       imgsrc: dorm1,
//     },
//     ,
//     {
//       div: 3,
//       imgsrc: dorm3,
//     },
//     {
//       div: 4,
//       imgsrc: dorm4,
//     },
//     {
//       div: 5,
//       imgsrc: dorm5,
//     },
//     {
//       div: 6,
//       imgsrc: dorm7,
//     },
//     {
//       div: 5,
//       imgsrc: dorm8,
//     },
//     {
//       div: 6,
//       imgsrc: dorm9,
//     },
//   ];

//   const [activeTab, setActiveTab] = useState("description");
//   const handleTabClick = (tabKey) => {
//     setActiveTab(tabKey);
//   };
//   const [model, setModel] = useState(false);
//   const [tempimgsrc, settempimgsrc] = useState("");
//   const getImg = (imgsrc) => {
//     settempimgsrc(imgsrc);
//     setModel(true);
//   };
//   useEffect(() => {
//     let handler = () => {
//       setModel(false);
//     };
//     document.addEventListener("mousedown", handler);
//   });
//   const [showLess, setshowLess] = useState(true);
//   const str =
//     "This budget friendly place offers both private rooms and dorms for the comfort of both individuals and groups.  Dorms have 2 bunk beds and spacious and can accommodate an extra bed  for groups wanting to share the same room. Delicious homely food made from freshly grown organic vegetables on request.Located just minutes away from Ooty Botanical gardens - a charming farm house Budget friendly with options for both dormitory and private rooms Surrounded by an organic farm and join a guided tour of the farm on request";
//   var resultStr = showLess ? str.slice(0, 500) : str;
//   const changeShow = () => {
//     setshowLess(!showLess);
//   };

//   const [value, setValue] = React.useState(null);
//   const [inputValue, setInputValue] = React.useState("");
//   const [options, setOptions] = React.useState([]);
//   const loaded = React.useRef(false);

//   if (typeof window !== "undefined" && !loaded.current) {
//     if (!document.querySelector("#google-maps")) {
//       loadScript(
//         `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
//         document.querySelector("head"),
//         "google-maps"
//       );
//     }

//     loaded.current = true;
//   }

//   const fetch = React.useMemo(
//     () =>
//       debounce((request, callback) => {
//         autocompleteService.current.getPlacePredictions(request, callback);
//       }, 400),
//     []
//   );

//   React.useEffect(() => {
//     let active = true;

//     if (!autocompleteService.current && window.google) {
//       autocompleteService.current =
//         new window.google.maps.places.AutocompleteService();
//     }
//     if (!autocompleteService.current) {
//       return undefined;
//     }

//     if (inputValue === "") {
//       setOptions(value ? [value] : []);
//       return undefined;
//     }

//     fetch({ input: inputValue }, (results) => {
//       if (active) {
//         let newOptions = [];

//         if (value) {
//           newOptions = [value];
//         }

//         if (results) {
//           newOptions = [...newOptions, ...results];
//         }

//         setOptions(newOptions);
//       }
//     });

//     return () => {
//       active = false;
//     };
//   }, [value, inputValue, fetch]);
//   const [activeKey, setActiveKey] = useState("description");

//   const handleSelect = (key) => {
//     if (key === activeKey) {
//       return;
//     }
//     setActiveKey(key);
//   };

//   return (
//     <section>
//       <div className="oprabhu-main">
//         <img src={dorm4} alt="" />
//       </div>

//       <div className="responsive-wrapper">
//         <Card id="search-card-grid-oprabhu" className="responsive-card">
//           <Row className="align-items-center" style={{ padding: "23px" }}>
//             <Col className="d-flex align-items-center" xs="auto">
//               <Autocomplete
//                 id="city-search"
//                 options={cities}
//                 getOptionLabel={(option) => option.label}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label="Where do you want to go"
//                     variant="outlined"
//                     fullWidth
//                     style={{ minWidth: 250, marginBottom: 0 }}
//                     error={cityError}
//                     helperText={cityError ? "City is required" : ""}
//                   />
//                 )}
//                 onChange={(event, newValue) => {
//                   setSelectedCity(newValue);
//                   setCityError(false); // Reset error when city is selected
//                 }}
//                 value={selectedCity}
//               />
//             </Col>
//             {/* <Col className="d-flex align-items-center" xs="auto">
//               <LocalizationProvider
//                 dateAdapter={AdapterDayjs}
//                 adapterLocale="en-gb"
//               >
//                 <DateRangePicker
//                   localeText={{ start: "Check-in", end: "Check-out" }}
//                   value={selectedDates}
//                   onChange={(newValue) => {
//                     setSelectedDates(newValue);
//                   }}
//                   renderInput={(startProps, endProps) => (
//                     <>
//                       <TextField
//                         {...startProps}
//                         variant="outlined"
//                         fullWidth
//                         style={{ minWidth: 150 }}
//                       />
//                       <TextField
//                         {...endProps}
//                         variant="outlined"
//                         fullWidth
//                         style={{ minWidth: 150 }}
//                       />
//                     </>
//                   )}
//                 />
//               </LocalizationProvider>
//             </Col> */}
//             {/* <Col className="d-flex align-items-center" xs="auto">
//               <div style={{ display: "flex", alignItems: "center" }}>
//                 <TextField
//                   label="Select Guests"
//                   variant="outlined"
//                   fullWidth
//                   value={selectedGuest.label}
//                   onClick={handleGuestClick}
//                   style={{ minWidth: 150, marginBottom: 0 }}
//                   readOnly
//                 />
//                 <Popover
//                   id={id}
//                   open={open}
//                   anchorEl={anchorEl}
//                   onClose={handleGuestClose}
//                   anchorOrigin={{
//                     vertical: "bottom",
//                     horizontal: "center",
//                   }}
//                   transformOrigin={{
//                     vertical: "top",
//                     horizontal: "center",
//                   }}
//                 >
//                   <div
//                     style={{
//                       padding: "10px",
//                       display: "flex",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Button
//                       variant="outline-secondary"
//                       onClick={() => handleGuestChange(-1)}
//                     >
//                       -
//                     </Button>
//                     <span style={{ margin: "0 10px" }}>
//                       {selectedGuest.value}
//                     </span>
//                     <Button
//                       variant="outline-secondary"
//                       onClick={() => handleGuestChange(1)}
//                     >
//                       +
//                     </Button>
//                   </div>
//                 </Popover>
//               </div>
//             </Col> */}
//             <Col className="d-flex align-items-center" xs="auto">
//               <Button
//                 variant="primary"
//                 style={{ backgroundColor: "blue", color: "white" }}
//                 onClick={handleSearch}
//               >
//                 Let's Go
//               </Button>
//             </Col>
//           </Row>
//         </Card>
//       </div>

//       <div className="container  opcontnt">
//         <div className=" row justify-content-center  oprabhu-contnt">
//           <Container style={{ marginTop: "10px" }}>
//             <div>
//               <Typography
//                 as="h5"
//                 style={{
//                   fontFamily: "Open Sans",
//                   fontSize: "30px",
//                   fontWeight: "400",
//                 }}
//               >
//                 Prabhu's place in Ooty
//               </Typography>
//               <Typography
//                 as="h6"
//                 style={{
//                   fontFamily: "Roboto",
//                   fontSize: "18px",
//                   fontWeight: "400",
//                 }}
//               >
//                 <LocationOnIcon style={{ fontSize: "30px" }} /> ooty, Tamil
//                 Nadu, India
//               </Typography>
//             </div>
//           </Container>
//           <div className="oprabhu-main-container">
//             <div
//               style={{ height: "auto", width: "auto", boxSizing: "border-box" }}
//             >
//               <div className="oprabhu-main-rooms-container">
//                 <div className="tabs-container">
//                   <Tabs
//                     id="controlled-tab-example"
//                     activeKey={activeKey}
//                     onSelect={handleSelect}
//                     className="mb-3"
//                     style={{ overflowX: "auto" }}

//                     //   justify
//                   >
//                     <Tab
//                       eventKey="description"
//                       title={
//                         <span
//                           onClick={() => handleTabClick("description")}
//                           style={{
//                             color:
//                               activeTab === "description" ? "blue" : "black",
//                           }}
//                         >
//                           <InfoIcon />
//                           Description
//                         </span>
//                       }
//                     >
//                       <div className="oprabhu-tabs-description">
//                         <p style={{ fontFamily: "Roboto", fontSize: "16px" }}>
//                           {resultStr}
//                         </p>
//                         <Button variant="text" onClick={changeShow}>
//                           {showLess ? "Show more" : "Show Less"}
//                         </Button>
//                       </div>
//                     </Tab>
//                     <Tab
//                       eventKey="Ameinites"
//                       title={
//                         <span
//                           onClick={() => handleTabClick("Ameinites")}
//                           style={{
//                             color: activeTab === "Ameinites" ? "blue" : "black",
//                           }}
//                         >
//                           <LocalActivityOutlinedIcon /> Ameinites
//                         </span>
//                       }
//                     >
//                       <div className="facilities">
//                         <div style={{ display: "flex" }}>
//                           <div className="amenites">
//                             <div className="image-wrapper">
//                               <img
//                                 className="facilities-ooty"
//                                 src={parking}
//                                 alt=""
//                               />
//                               <div className="hover-text">
//                                 Parking Available
//                               </div>
//                             </div>
//                             <h6 className="facilities-ooty-text">parking</h6>
//                           </div>
//                           <div className="amenites">
//                             <div className="image-wrapper">
//                               <img
//                                 className="facilities-ooty"
//                                 src={wifi1}
//                                 alt=""
//                               />
//                               <div className="hover-text">Wifi Available</div>
//                             </div>
//                             <h6 className="facilities-ooty-text">wifi</h6>
//                           </div>

//                           <div className="amenites">
//                             <div className="image-wrapper">
//                               <img
//                                 className="facilities-ooty"
//                                 src={ac}
//                                 alt=""
//                               />
//                               <div className="hover-text">Ac Available</div>
//                             </div>
//                             <h6 className="facilities-ooty-text">Ac</h6>
//                           </div>

//                           <div className="amenites">
//                             <div className="image-wrapper">
//                               <img
//                                 className="facilities-ooty"
//                                 src={fire}
//                                 alt=""
//                               />
//                               <div className="hover-text">
//                                 Bone Fire Available
//                               </div>
//                             </div>
//                             <h6 className="facilities-ooty-text">Bone Fire</h6>
//                           </div>
//                           <div className="amenites">
//                             <div className="image-wrapper">
//                               <img
//                                 className="facilities-ooty"
//                                 src={cafe}
//                                 alt=""
//                               />
//                               <div className="hover-text">Cafe Available</div>
//                             </div>
//                             <h6 className="facilities-ooty-text">Cafe</h6>
//                           </div>
//                           <div className="amenites">
//                             <div className="image-wrapper">
//                               <img
//                                 className="facilities-ooty"
//                                 src={publictoilet}
//                                 alt=""
//                               />
//                               <div className="hover-text">
//                                 Commonwashroom Available
//                               </div>
//                             </div>
//                             <h6 className="facilities-ooty-text">washroom</h6>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                     <Tab
//                       eventKey="location"
//                       title={
//                         <span
//                           onClick={() => handleTabClick("location")}
//                           style={{
//                             color: activeTab === "location" ? "blue" : "black",
//                           }}
//                         >
//                           <FmdGoodIcon /> Location
//                         </span>
//                       }
//                     >
//                       <Typography
//                         variant="h5"
//                         style={{ fontFamily: "Open Sans" }}
//                       >
//                         Prabhus place Location
//                       </Typography>
//                       <iframe
//                         width="80%"
//                         height="400"
//                         frameborder="0"
//                         scrolling="no"
//                         marginheight="0"
//                         marginwidth="0"
//                         src="
//                           https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1/42%20Tonga%20Road,%20Bandishola,%20Ooty,%20Tamil%20Nadu,%20India,%20Ooty,%20India+(World%20Of%20Hostels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
//                       >
//                         <a href="https://www.gps.ie/">gpstrackers</a>
//                       </iframe>
//                     </Tab>

//                     <Tab
//                       eventKey="review"
//                       title={
//                         <span
//                           onClick={() => handleTabClick("review")}
//                           style={{
//                             color: activeTab === "review" ? "blue" : "black",
//                           }}
//                         >
//                           <RateReviewIcon /> Review
//                         </span>
//                       }
//                     >
//                       <Typography
//                         as="h6"
//                         style={{
//                           backgroundColor: "tomato",
//                           margin: "20px",
//                           fontFamily: "Roboto",
//                         }}
//                       >
//                         ⭐ 100% genuine Review from real hostel travelers like
//                         you!
//                       </Typography>
//                       <div
//                         style={{ display: "flex", gap: "30px", margin: "30px" }}
//                       >
//                         <div>
//                           <h1
//                             style={{
//                               backgroundColor: "tomato",
//                               color: "white",
//                             }}
//                           >
//                             6.9
//                           </h1>
//                         </div>
//                         <div>
//                           <h5 style={{ fontFamily: "Roboto" }}>Good</h5>
//                           <h6 style={{ fontFamily: "Roboto" }}>
//                             29 Total Reviews
//                           </h6>
//                         </div>
//                       </div>
//                       <Row style={{ margin: "10px" }}>
//                         <Col>
//                           {[
//                             { label: "Security", value: 85 },
//                             { label: "Staff", value: 90 },
//                             { label: "Cleanliness", value: 80 },
//                             { label: "Value For Money", value: 80 },
//                           ].map((item, index) => (
//                             <div key={index} style={{ marginBottom: "10px" }}>
//                               <div
//                                 style={{
//                                   display: "flex",
//                                   justifyContent: "space-between",
//                                 }}
//                               >
//                                 <Typography
//                                   as="h6"
//                                   style={{
//                                     fontSize: "15px",
//                                     fontFamily: "Roboto",
//                                   }}
//                                 >
//                                   {item.label}
//                                 </Typography>
//                                 <Typography
//                                   as="h6"
//                                   style={{
//                                     fontSize: "15px",
//                                     fontFamily: "Roboto",
//                                   }}
//                                 >
//                                   {item.value / 10}
//                                 </Typography>
//                               </div>
//                               <ProgressBar
//                                 variant="danger"
//                                 now={item.value}
//                                 style={{ height: "5px" }}
//                               />
//                             </div>
//                           ))}
//                         </Col>
//                         <Col>
//                           {[
//                             { label: "Location", value: 80 },
//                             { label: "Atmosphere", value: 85 },
//                             { label: "Facilities", value: 85 },
//                           ].map((item, index) => (
//                             <div key={index} style={{ marginBottom: "10px" }}>
//                               <div
//                                 style={{
//                                   display: "flex",
//                                   justifyContent: "space-between",
//                                 }}
//                               >
//                                 <Typography
//                                   as="h6"
//                                   style={{
//                                     fontSize: "15px",
//                                     fontFamily: "Roboto",
//                                   }}
//                                 >
//                                   {item.label}
//                                 </Typography>
//                                 <Typography
//                                   as="h6"
//                                   style={{
//                                     fontSize: "15px",
//                                     fontFamily: "Roboto",
//                                   }}
//                                 >
//                                   {item.value / 10}
//                                 </Typography>
//                               </div>
//                               <ProgressBar
//                                 variant="danger"
//                                 now={item.value}
//                                 style={{ height: "5px" }}
//                               />
//                             </div>
//                           ))}
//                         </Col>
//                       </Row>
//                       {reviewData.map((review, index) => (
//                         <div
//                           key={index}
//                           style={{
//                             marginLeft: "30px",
//                             marginTop: "30px",
//                             display: "flex",
//                           }}
//                         >
//                           <div>
//                             <PersonIcon />
//                             <Typography
//                               as="h6"
//                               style={{ fontFamily: "Roboto", fontSize: "18px" }}
//                             >
//                               {review.name}
//                             </Typography>
//                             <Typography
//                               as="h6"
//                               style={{ color: "gray", fontSize: "14px" }}
//                             >
//                               {review.gender}, {review.age}
//                             </Typography>
//                             <Typography
//                               as="h6"
//                               style={{ color: "gray", fontSize: "14px" }}
//                             >
//                               {review.status}
//                             </Typography>
//                             <Typography
//                               as="h6"
//                               style={{ color: "orange", fontSize: "14px" }}
//                             >
//                               {review.reviews} reviews
//                             </Typography>
//                           </div>
//                           <div
//                             style={{
//                               marginLeft: "60px",
//                               textAlign: "start",
//                               marginTop: "10px",
//                             }}
//                           >
//                             <div style={{ display: "flex", gap: "15px" }}>
//                               <h4
//                                 style={{
//                                   backgroundColor: "tomato",
//                                   color: "white",
//                                   borderRadius: "5px",
//                                 }}
//                               >
//                                 {review.rating}
//                               </h4>
//                               <h6 style={{ color: "tomato" }}>
//                                 {review.rating >= 8
//                                   ? "Very Good"
//                                   : review.rating >= 5
//                                   ? "Good"
//                                   : "Average"}
//                               </h6>
//                             </div>
//                             <p
//                               style={{ fontFamily: "Roboto", fontSize: "17px" }}
//                             >
//                               {review.feedback}
//                             </p>
//                           </div>
//                         </div>
//                       ))}
//                     </Tab>
//                     <Tab
//                       eventKey="gallery"
//                       title={
//                         <span
//                           onClick={() => handleTabClick("gallery")}
//                           style={{
//                             color: activeTab === "gallery" ? "blue" : "black",
//                           }}
//                         >
//                           {" "}
//                           <CollectionsOutlinedIcon /> Gallery
//                         </span>
//                       }
//                     >
//                       <div className={model ? "model open" : "model"}>
//                         <img src={tempimgsrc} alt="" />
//                       </div>
//                       <div className="gallery">
//                         {data.map((items, index) => {
//                           return (
//                             <div
//                               className="pics"
//                               key={index}
//                               onClick={() => getImg(items.imgsrc)}
//                             >
//                               <img
//                                 src={items.imgsrc}
//                                 style={{ width: "100%" }}
//                                 alt=""
//                               />
//                             </div>
//                           );
//                         })}
//                       </div>
//                     </Tab>
//                     <Tab
//                       eventKey="thingstodo"
//                       title={
//                         <span
//                           onClick={() => handleTabClick("thingstodo")}
//                           style={{
//                             color:
//                               activeTab === "thingstodo" ? "blue" : "black",
//                           }}
//                         >
//                           <ModeOfTravelIcon /> Trip Places
//                         </span>
//                       }
//                     >
//                       <div>
//                         <Container
//                           style={{
//                             height: "auto",
//                             overflowX: "scroll",
//                             whiteSpace: "nowrap",
//                           }}
//                         >
//                           <Row className="flex-nowrap">
//                             {places.map((place, index) => (
//                               <Col
//                                 key={index}
//                                 style={{
//                                   display: "inline-block",
//                                   float: "none",
//                                 }}
//                               >
//                                 <Card
//                                   style={{
//                                     width: "18rem",
//                                     display: "inline-block",
//                                     margin: "0 10px",
//                                   }}
//                                 >
//                                   <Card.Img
//                                     variant="top"
//                                     src={place.imageUrl}
//                                     style={{ height: "150px" }}
//                                   />
//                                   <Card.Body>
//                                     <Card.Title
//                                       style={{ fontFamily: "Open Sans" }}
//                                     >
//                                       {place.title}
//                                     </Card.Title>
//                                     <Card.Text
//                                       style={{ fontFamily: "Open Sans" }}
//                                     >
//                                       Price: ${place.price}
//                                     </Card.Text>
//                                     <div
//                                       style={{
//                                         display: "flex",
//                                         justifyContent: "space-evenly",
//                                         gap: "40px",
//                                       }}
//                                     >
//                                       <ReactStars
//                                         count={5}
//                                         value={place.rating}
//                                         size={24}
//                                         activeColor="#006400"
//                                         edit={false}
//                                       />
//                                       {thingBookings[index].isBooked ? (
//                                         <div>
//                                           <div className="d-flex align-items-center">
//                                             <Button
//                                               variant="secondary"
//                                               onClick={() =>
//                                                 handleThingDecrement(index)
//                                               }
//                                             >
//                                               -
//                                             </Button>
//                                             <span className="mx-3">
//                                               {thingBookings[index].quantity}
//                                             </span>
//                                             <Button
//                                               variant="secondary"
//                                               onClick={() =>
//                                                 handleThingIncrement(index)
//                                               }
//                                             >
//                                               +
//                                             </Button>
//                                           </div>
//                                         </div>
//                                       ) : (
//                                         <Button
//                                           variant="warning"
//                                           onClick={() =>
//                                             handleThingBookNow(index)
//                                           }
//                                         >
//                                           Trip Now
//                                         </Button>
//                                       )}
//                                     </div>
//                                   </Card.Body>
//                                 </Card>
//                               </Col>
//                             ))}
//                           </Row>
//                         </Container>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                 </div>

//                 <Container className="mt-4">
//                   <Row className="gx-3">
//                     {ootyapartments.map((apt, index) => (
//                       <Col key={index} xs={12} md={12} lg={12} className="mb-4">
//                         <Card className="h-100">
//                           <Row className="p-3">
//                             <Col md={5}>
//                               <Carousel>
//                                 {apt.images.map((image, idx) => (
//                                   <Carousel.Item key={idx} interval={10000}>
//                                     <img
//                                       className="d-block w-100"
//                                       src={image}
//                                       alt={`Slide ${idx}`}
//                                       style={{
//                                         height: "250px",
//                                         position: "relative",
//                                         borderRadius: "10px",
//                                       }}
//                                     />
//                                   </Carousel.Item>
//                                 ))}
//                               </Carousel>
//                             </Col>
//                             <Col md={7}>
//                               <div className="d-flex flex-column h-100 justify-content-between">
//                                 <div>
//                                   <Typography
//                                     variant="h5"
//                                     style={{
//                                       fontFamily: "Open Sans",
//                                       fontSize: "21px",
//                                       fontWeight: "500",
//                                       marginTop: "8px",
//                                     }}
//                                   >
//                                     {apt.name}
//                                   </Typography>
//                                   <p
//                                     style={{
//                                       fontSize: "16px",
//                                       marginTop: "5px",
//                                       fontFamily: "Roboto",
//                                       width: "auto",
//                                     }}
//                                   >
//                                     {apt.description}
//                                   </p>
//                                   <div className="d-flex justify-content-center align-items-center mt-3">
//                                     <div className="mt-2">
//                                       <h5
//                                         style={{
//                                           color: "palevioletred",
//                                           fontFamily: "Roboto",
//                                           fontSize: "15px",
//                                         }}
//                                       >
//                                         Apartment
//                                       </h5>
//                                       <p
//                                         style={{
//                                           fontFamily: "Roboto",
//                                           fontSize: "15px",
//                                         }}
//                                       >
//                                         {apt.discountedPrice}/night
//                                       </p>
//                                     </div>

//                                     <div className="ms-auto">
//                                       {/* {roomBookings[index].quantity === 0 ? (
//     <button className="add-btn" onClick={() => handleAddRoom(index)}>+ Add</button>
//   ) : (
//     <div className="bng-counter-cnts">
//       <button className="decrement-btn" onClick={() => handleRoomDecrement(index)}>-</button>
//       <span className="count-dis">
//         <PersonOutlinedIcon style={{ marginBottom: "5px" }}/>
//         {roomBookings[index].quantity}
//       </span>
//       <button className="increment-btn" onClick={() => handleRoomIncrement(index)}>+</button>
//     </div>
//   )} */}

//                                       <button
//                                         style={{ width: "150px" }}
//                                         className="add-btn"
//                                         onClick={() =>
//                                           window.open(
//                                             "https://bookingengine.stayflexi.com/?hotel_id=29067",
//                                             "_blank"
//                                           )
//                                         }
//                                       >
//                                         Book now
//                                       </button>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </Col>
//                           </Row>
//                         </Card>
//                       </Col>
//                     ))}
//                   </Row>
//                 </Container>
//               </div>
//             </div>

//             {/* <div
//               className="my-selection-card"
//               style={{ height: sidebarHeight, marginTop: "20px" }}
//             >
//               <Card style={{ border: "1px solid #ccc", width: "270px" }}>
//                 <div style={{ padding: "20px", height: "auto" }}>
//                   <Typography
//                     variant="h6"
//                     style={{
//                       fontSize: "18px",
//                       textAlign: "center",
//                       fontFamily: "Open Sans",
//                       fontWeight: "500",
//                     }}
//                   >
//                     My Selection
//                   </Typography>
//                   <div>
//                     <div
//                       style={{
//                         display: "inline",
//                         gap: "70px",
//                         marginTop: "20px",
//                       }}
//                     >
//                       <Typography
//                         variant="h6"
//                         style={{ fontFamily: "Open Sans", fontSize: "17px" }}
//                       >
//                         Tripe Amount: {totalThingAmount} Rs
//                       </Typography>
//                       <Typography
//                         variant="h6"
//                         style={{ fontFamily: "Open Sans", fontSize: "17px" }}
//                       >
//                         Room Amount: {totalRoomAmount} Rs
//                       </Typography>
//                     </div>
//                     <Typography
//                       variant="h6"
//                       style={{ fontFamily: "Open Sans", fontSize: "17px" }}
//                     >
//                       Total Amount: {totalAmount} Rs
//                     </Typography>
//                     <Button
//                       variant="danger"
//                       style={{ width: "220px", marginTop: "10px" }}
//                     >
//                       Book now
//                     </Button>
//                   </div>
//                   <div style={{ marginTop: "20px" }}>
//                     <Typography
//                       variant="h6"
//                       style={{ fontFamily: "Open Sans", fontSize: "17px" }}
//                     >
//                       Choose Your Room
//                     </Typography>
//                     <Typography
//                       variant="h6"
//                       style={{ fontFamily: "Open Sans", fontSize: "15px" }}
//                       color="gray"
//                     >
//                       <CheckIcon />
//                       Instant Confirmation
//                     </Typography>
//                     <Typography
//                       variant="h6"
//                       style={{ fontFamily: "Open Sans", fontSize: "15px" }}
//                       color="gray"
//                     >
//                       {" "}
//                       <CheckIcon />
//                       No Booking Fees
//                     </Typography>
//                     <Typography
//                       variant="h6"
//                       style={{ fontFamily: "Open Sans", fontSize: "15px" }}
//                       color="gray"
//                     >
//                       {" "}
//                       <CheckIcon />
//                       Booking Only Takes 2 Min
//                     </Typography>
//                   </div>
//                   <div
//                     style={{ width: "100%", justifyContent: "space-around" }}
//                   >
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/128/179/179457.png"
//                       style={{ width: "50px", height: "auto" }}
//                       alt="icon"
//                     />
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/128/11041/11041016.png"
//                       style={{ width: "50px", height: "auto" }}
//                       alt="icon"
//                     />
//                     <img
//                       src="https://cdn-icons-png.flaticon.com/128/11041/11041055.png"
//                       style={{ width: "50px", height: "auto" }}
//                       alt="icon"
//                     />
//                   </div>
//                 </div>
//               </Card>
//             </div> */}
//           </div>
//         </div>
//       </div>

//       <FooterContainer />
//     </section>
//   );
// };

// export default Ootyprabhusplace;

import React, { useEffect, useState } from "react";
import "./Oprabhu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "@mui/material/utils";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dorm1 from "../../assets/Ootyassets/dorm1.jpg";
import dorm3 from "../../assets/Ootyassets/dorm3.webp";
import dorm4 from "../../assets/Ootyassets/dorm4.jpg";
import dorm5 from "../../assets/Ootyassets/dorm5.webp";
import dorm8 from "../../assets/Ootyassets/dorm8.jpg";
import dorm7 from "../../assets/Ootyassets/dorm7.jpg";
import dorm9 from "../../assets/Ootyassets/dorm9.jpg";
import ReactStars from "react-rating-stars-component";
import privateroom3 from "../../assets/Ootyassets/privateroom3.jpeg";
import privateroom4 from "../../assets/Ootyassets/privateroom4.jpeg";
import privateroom5 from "../../assets/Ootyassets/privateroom5.jpeg";
import boathouse from "../../assets/Ootyassets/boathouse.jpg";
import doddabetta from "../../assets/Ootyassets/doddabetta.jpg";
import kettiview from "../../assets/Ootyassets/kettiview.jpg";
// import tea from "../../assets/Ootyassets/tea.webp";
import pykarafalls from "../../assets/Ootyassets/pykarafalls.jpg";
import KamarajDam from "../../assets/Ootyassets/KamarajDam.jpg";
import zoo from "../../assets/Ootyassets/zoo.jpg";
import golf from "../../assets/Ootyassets/golf.jpg";
import tea1 from "../../assets/Ootyassets/tea1.jpg";
import painforest from "../../assets/Ootyassets/painforest.jpeg";
import Hillpoint from "../../assets/Ootyassets/Hillpoint.webp";
import lake from "../../assets/Ootyassets/lake.jpg";
import { Modal } from "react-bootstrap";

import PersonIcon from "@mui/icons-material/Person";

import { Carousel, ProgressBar } from "react-bootstrap";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Typography } from "@mui/material";
import image1 from "../../assets/Ootyassets/image1.jpeg";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import InfoIcon from "@mui/icons-material/Info";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import RateReviewIcon from "@mui/icons-material/RateReview";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CheckIcon from "@mui/icons-material/Check";
import wifi1 from "../../assets/wifi1.png";
import publictoilet from "../../assets/publictoilet.png";
import fire from "../../assets/fire.png";
import parking from "../../assets/parking.png";
import ac from "../../assets/ac.png";
import cafe from "../../assets/cafe.png";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import dayjs from "dayjs";
import { Popover } from "@mui/material";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";
import { useNavigate } from "react-router-dom";
import FooterContainer from "../../components/footer/FooterContainer";
import temple from "../../assets/chinnaaasets/chpics/temple.jpg";
import tea from "../../assets/chinnaaasets/chpics/tea.webp";
import train from "../../assets/chinnaaasets/chpics/train.jpg";
import rosegarden from "../../assets/chinnaaasets/chpics/rosegarden.jpg";
import ootygarden from "../../assets/chinnaaasets/chpics/ootygarden.jpg";
import falls from "../../assets/chinnaaasets/chpics/falls.jpg";
import ootylake from "../../assets/chinnaaasets/chpics/ootylake.jpg";
import church from "../../assets/chinnaaasets/chpics/church.jpg";
import Doddabettateles from "../../assets/chinnaaasets/chpics/Doddabettateles.jpg";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const GOOGLE_MAPS_API_KEY = "AIzaSyCAJYvfbH3B_JqrT1CvpJNHRULeTHwwI7Y";

const cities = [
  { label: "Bprabhu" },
  { label: "Oprabhu" },
  { label: "Cprabhu" },
];
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const Ootyprabhusplace = () => {
  const [selectedDates, setSelectedDates] = useState([
    dayjs(),
    dayjs().add(1, "day"),
  ]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState({
    label: "1 Guest",
    value: 1,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [cityError, setCityError] = useState(false);

  const navigate = useNavigate();
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleShowreview = () => {
    setShowAllReviews(!showAllReviews);
  };

  const handleSearch = () => {
    if (selectedCity) {
      navigate(`/${selectedCity.label.toLowerCase()}`);
    } else {
      setCityError(true);
    }
  };
  const handleGuestChange = (increment) => {
    setSelectedGuest((prevGuest) => {
      let newGuestCount = prevGuest.value + increment;
      newGuestCount = Math.max(1, newGuestCount); // Ensure at least 1 guest
      return {
        label: `${newGuestCount} Guest${newGuestCount > 1 ? "s" : ""}`,
        value: newGuestCount,
      };
    });
  };

  const handleGuestClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGuestClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "guest-popover" : undefined;

  const ootyapartments = [
    {
      name: "Private Room",
      images: [privateroom3, privateroom5, privateroom4],
      description:
        "Nice sized private room comes with king size bed and an attached bath some rooms are with view of the hills and wake up to church bells.",
      discountedPrice: "800",
    },

    {
      name: "Mix Dorm One with ensuite",
      images: [dorm1, dorm3, image1, dorm5],
      description:
        "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
      discountedPrice: "600",
    },
    {
      name: "Mix Dorm Two with ensuite",
      images: [image1, dorm9, dorm8, dorm5],
      description:
        "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
      discountedPrice: "500",
    },
    {
      name: "Mix Dorm Three with ensuite",
      images: [dorm3, dorm7, dorm9, image1],
      description:
        "Mixed dorms are fitted with 3, 5, 8 bunk beds and its own private bath",
      discountedPrice: "1000",
    },
  ];
  const [key, setKey] = useState("description");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [key]);

  const selection = {
    title: "My Selection",
  };

  const reviewData = [
    {
      name: "ashokreddy",
      gender: "Male",
      age: "20-26",
      status: "Novice Nomed",
      reviews: 2,
      rating: 6.1,
      feedback:
        "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
    },
    {
      name: "rajakullayappa",
      gender: "Male",
      age: "20-26",
      status: "Novice Nomed",
      reviews: 5,
      rating: 7.5,
      feedback:
        "It was a nice experience. Dorms were clean. Bonfire and games were there but location was not that good.",
    },
    {
      name: "Viratkohli",
      gender: "Male",
      age: "30-36",
      status: "Novice Nomed",
      reviews: 1,
      rating: 10,
      feedback:
        "An example of what a hostel should be. The hospital and friendliness of the staff made me feel very comfortable and relaxed.",
    },
  ];
  const limitedReviewData = showAllReviews
    ? reviewData
    : reviewData.slice(0, 0);
  const [selectedPlaceIndex, setSelectedPlaceIndex] = useState(null);

  const handleShowtours = (index) => {
    setSelectedPlaceIndex(selectedPlaceIndex === index ? null : index);
  };

  const places = [
    {
      title: "Ooty & Coonoor Tour ",
      description:
        "Pick up from hotel or common area,Boat house,Tread Garden,Doddabetta Peak,Botanical Gardens,Ketti Valley View,Madras Regimental Center,DSSC Outer View,Sims Pak,Lambs Rock & Dolphins nose,Tea Garden,Coonoor Shoping Center,Catherine falls view ",
      imageUrl: [boathouse],
      price: "500",
    },
    {
      title: "Pykara - Mudumalai",
      description:
        "Pick up from hotel  or common area,Golf club,HPF(Outer view),Pine Forest, Kamaraj Sagar Dam, Devils Down,9th mile shooting spot(Wenlock dowms),Pykara Dam(outer view),Pykara Water falls,Pykara Boat Hours,Mudumalai Wild Life Santuary (Jungle ride forest Dept vehicle extra charges)",
      imageUrl: [pykarafalls],
      price: "500",
    },
    {
      title: "Filmy Chakkar",
      description:
        "Pick up from hotel or common area,Golf Club,HPF(Outer view),Pine Forest,Kamaraj Sagar Dam,Devils Down,9th mile shooting spot(Wenlock dowms),Pykara Dam(outer view),Pykara Water falls,Pykara Boat Hours",
      imageUrl: [painforest],
      price: "500",
    },
    // {
    //   title: "Nilgiri Mountain Railway",
    //   description:
    //     "The Nilgiri Mountain Railway, also known as the Ooty Toy Train, is a UNESCO World Heritage Site.  ",
    //   imageUrl: train,
    //   price: "500",
    // },

    // {
    //   title: "Elk Hill Murugan Temple",
    //   description:
    //     "The Elk Hill Murugan Temple is dedicated to Lord Murugan and offers a panoramic views of Ooty.",
    //   imageUrl: temple,
    //   price: "500",
    // },
    // {
    //   title: "st. stephen church ooty",
    //   description:
    //     "St. Stephens Church, built in 1829, is one of the oldest churches in the Nilgiris.",
    //   imageUrl: church,
    //   price: "500",
    // },
    // {
    //   title: "Ooty Tea Factory,Museum",
    //   description:
    //     "The Tea Factory and Museum offers an insight into the tea-making process. ",
    //   imageUrl: tea,
    //   price: "500",
    // },
    // {
    //   title: "Rose Garden",
    //   description:
    //     "The Ooty Rose Garden, also known as the Jayalalithaa Rose Garden, is one of the largest rose gardens in India.",
    //   imageUrl: rosegarden,
    //   price: "500",
    // },
    // {
    //   title: "Doddabetta Peak",
    //   description:
    //     "Doddabetta Peak is the highest point in the Nilgiri Mountains, standing at 2,637 meters above sea level. ",
    //   imageUrl: Doddabettateles,
    //   price: "500",
    // },
  ];
  // State for bookings of activities (things)
  const [thingBookings, setThingBookings] = useState(
    places.map(() => ({ isBooked: false, quantity: 0 }))
  );

  // State for bookings of rooms
  const [roomBookings, setRoomBookings] = useState(
    ootyapartments.map(() => ({ isBooked: false, quantity: 0, price: 0 }))
  );

  // Handler to book an activity (thing)
  const handleThingBookNow = (index) => {
    const newThingBookings = [...thingBookings];
    newThingBookings[index].isBooked = true;
    newThingBookings[index].quantity = 1;
    setThingBookings(newThingBookings);
  };

  // Handler to increment the quantity of an activity (thing)
  const handleThingIncrement = (index) => {
    const newThingBookings = [...thingBookings];
    newThingBookings[index].quantity += 1;
    setThingBookings(newThingBookings);
  };

  // Handler to decrement the quantity of an activity (thing)
  const handleThingDecrement = (index) => {
    const newThingBookings = [...thingBookings];
    if (newThingBookings[index].quantity > 0) {
      newThingBookings[index].quantity -= 1;
      if (newThingBookings[index].quantity === 0) {
        newThingBookings[index].isBooked = false;
      }
      setThingBookings(newThingBookings);
    }
  };

  // Handler to add a room booking
  const handleAddRoom = (index) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index].isBooked = true;
    newRoomBookings[index].quantity = 1;
    newRoomBookings[index].price = ootyapartments[index].discountedPrice;
    setRoomBookings(newRoomBookings);
  };

  // Handler to increment the quantity of a room booking
  const handleRoomIncrement = (index) => {
    const newRoomBookings = [...roomBookings];
    newRoomBookings[index].quantity += 1;
    setRoomBookings(newRoomBookings);
  };

  // Handler to decrement the quantity of a room booking
  const handleRoomDecrement = (index) => {
    const newRoomBookings = [...roomBookings];
    if (newRoomBookings[index].quantity > 0) {
      newRoomBookings[index].quantity -= 1;
      if (newRoomBookings[index].quantity === 0) {
        newRoomBookings[index].isBooked = false;
        newRoomBookings[index].price = 0;
      }
      setRoomBookings(newRoomBookings);
    }
  };

  // Calculate total amount for things bookings
  const totalThingAmount = thingBookings.reduce(
    (acc, booking, index) => acc + booking.quantity * places[index].price,
    0
  );

  // Calculate total amount for room bookings
  const totalRoomAmount = roomBookings.reduce(
    (acc, booking) => acc + booking.quantity * booking.price,
    0
  );

  // Calculate total amount for both things and rooms
  const totalAmount = totalThingAmount + totalRoomAmount;

  //
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight - 320); // Window height minus 50px

  useEffect(() => {
    const handleResize = () => setSidebarHeight(window.innerHeight - 320);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = [
    {
      div: 1,
      imgsrc: dorm1,
    },
    ,
    {
      div: 3,
      imgsrc: dorm3,
    },
    {
      div: 4,
      imgsrc: dorm4,
    },
    {
      div: 5,
      imgsrc: dorm5,
    },
    {
      div: 6,
      imgsrc: dorm7,
    },
    {
      div: 5,
      imgsrc: dorm8,
    },
    {
      div: 6,
      imgsrc: dorm9,
    },
  ];
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const gallery = [dorm1, dorm3, dorm4, dorm5, dorm7, dorm8, dorm9];

  const handleClosegallery = () => setShow(false);
  const handleShowgallery = (selectedIndex) => {
    setIndex(selectedIndex);
    setShow(true);
  };

  //  const handleSelect = (selectedIndex) => {
  //    setIndex(selectedIndex);
  //  };
  const amenities = [
    { img: parking, text: "Parking Available", label: "Parking" },
    { img: wifi1, text: "Wifi Available", label: "Wifi" },
    { img: ac, text: "Ac Available", label: "Ac" },
    { img: fire, text: "Bone Fire", label: "Bone Fire" },
    { img: cafe, text: "Cafe Available", label: "Cafe" },
    { img: publictoilet, text: "Commonwashroom Available", label: "Washroom" },
  ];

  const [activeTab, setActiveTab] = useState("description");
  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };
  const [model, setModel] = useState(false);
  const [tempimgsrc, settempimgsrc] = useState("");
  const getImg = (imgsrc) => {
    settempimgsrc(imgsrc);
    setModel(true);
  };
  useEffect(() => {
    let handler = () => {
      setModel(false);
    };
    document.addEventListener("mousedown", handler);
  });
  const [showLess, setshowLess] = useState(true);
  const str =
    "This budget friendly place offers both private rooms and dorms for the comfort of both individuals and groups.  Dorms have 2 bunk beds and spacious and can accommodate an extra bed  for groups wanting to share the same room. Delicious homely food made from freshly grown organic vegetables on request.Located just minutes away from Ooty Botanical gardens - a charming farm house Budget friendly with options for both dormitory and private rooms Surrounded by an organic farm and join a guided tour of the farm on request";
  var resultStr = showLess ? str.slice(0, 500) : str;
  const changeShow = () => {
    setshowLess(!showLess);
  };

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  const [activeKey, setActiveKey] = useState("description");

  const handleSelect = (key) => {
    if (key === activeKey) {
      return;
    }
    setActiveKey(key);
  };

  return (
    <section>
      <div className="oprabhu-main">
        <img src={dorm4} alt="" />
      </div>

      <div className="container  opcontnt">
        <div className=" row justify-content-center  oprabhu-contnt">
          <Container style={{ marginTop: "10px" }}>
            <div>
              <Typography
                as="h5"
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "30px",
                  fontWeight: "400",
                }}
              >
                Prabhu's place in Ooty
              </Typography>
              <Typography
                as="h6"
                style={{
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                <LocationOnIcon style={{ fontSize: "30px" }} /> ooty, Tamil
                Nadu, India
              </Typography>
            </div>
          </Container>
          <div className="oprabhu-main-container">
            <div
              style={{ height: "auto", width: "auto", boxSizing: "border-box" }}
            >
              <div className="oprabhu-main-rooms-container">
                <div className="tabs-container">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={activeKey}
                    onSelect={handleSelect}
                    className="mb-3"
                    style={{ overflowX: "auto" }}

                    //   justify
                  >
                    <Tab
                      eventKey="description"
                      title={
                        <span
                          onClick={() => handleTabClick("description")}
                          style={{
                            color:
                              activeTab === "description" ? "blue" : "black",
                          }}
                        >
                          <InfoIcon /> Description
                        </span>
                      }
                    >
                      <div className="bprabhu-tabs-description">
                        <p style={{ fontFamily: "Roboto", fontSize: "16px" }}>
                          {" "}
                          Prabhu's Place Ooty is located ideally in the heart of
                          Ooty city center but nestled in a farm. This farm
                          house boosts with colonial architecture within 5
                          minutes walking distance to Botanical Gardens, Charing
                          cross, market and short hike to the boat lake and
                          railway station.{" "}
                        </p>

                        <p style={{ fontFamily: "Roboto", fontSize: "16px" }}>
                          {showLess
                            ? `${resultStr.slice(0, 150)}...`
                            : resultStr}
                        </p>
                        <Button
                          variant="text"
                          onClick={changeShow}
                          style={{ color: "palevioletred" }}
                        >
                          {showLess ? "Show more" : "Show Less"}
                        </Button>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="Ameinites"
                      title={
                        <span
                          onClick={() => handleTabClick("Ameinites")}
                          style={{
                            color: activeTab === "Ameinites" ? "blue" : "black",
                          }}
                        >
                          <LocalActivityOutlinedIcon /> Ameinites
                        </span>
                      }
                    >
                      <div className="facilities">
                        <Row>
                          {amenities.map((amenity, index) => (
                            <Col
                              xs={6}
                              md={4}
                              key={index}
                              className="amenity-col"
                            >
                              <div className="image-wrapper">
                                <img
                                  className="facilities-bng"
                                  src={amenity.img}
                                  alt=""
                                />
                                <div className="hover-text">{amenity.text}</div>
                              </div>
                              <h6 className="facilities-bng-text">
                                {amenity.label}
                              </h6>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="location"
                      title={
                        <span
                          onClick={() => handleTabClick("location")}
                          style={{
                            color: activeTab === "location" ? "blue" : "black",
                          }}
                        >
                          <FmdGoodIcon /> Location
                        </span>
                      }
                    >
                      <div className="tabs-map-container">
                        <Typography
                          variant="h5"
                          style={{ fontFamily: "Open Sans" }}
                        >
                          Prabhus place Location
                        </Typography>
                        <iframe
                          width="100%"
                          height="200"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                          src="
                          https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1/42%20Tonga%20Road,%20Bandishola,%20Ooty,%20Tamil%20Nadu,%20India,%20Ooty,%20India+(World%20Of%20Hostels)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        >
                          <a href="https://www.gps.ie/">gpstrackers</a>
                        </iframe>
                      </div>
                    </Tab>

                    <Tab
                      eventKey="review"
                      title={
                        <span
                          onClick={() => handleTabClick("review")}
                          style={{
                            color: activeTab === "review" ? "blue" : "black",
                          }}
                        >
                          <RateReviewIcon /> Review
                        </span>
                      }
                    >
                      <div className="review-tab">
                        <Typography
                          as="h6"
                          style={{
                            backgroundColor: "tomato",
                            margin: "10px",
                            fontFamily: "Roboto",
                          }}
                        >
                          ⭐ 100% genuine Review from real hostel travelers like
                          you!
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "30px",
                          }}
                        >
                          <div>
                            <h1
                              style={{
                                backgroundColor: "tomato",
                                color: "white",
                                borderRadius: "10%",
                              }}
                            >
                              8.9
                            </h1>
                          </div>
                          <div>
                            <h5 style={{ fontFamily: "Roboto" }}>Good</h5>
                            <h6 style={{ fontFamily: "Roboto" }}>
                              29 Total Reviews
                            </h6>
                          </div>
                        </div>
                        <Row style={{ margin: "10px" }}>
                          <Col>
                            {[
                              { label: "Security", value: 85 },
                              { label: "Staff", value: 90 },
                              { label: "Cleanliness", value: 80 },
                              { label: "Value For Money", value: 80 },
                            ].map((item, index) => (
                              <div key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    as="h6"
                                    style={{
                                      fontSize: "15px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                  <Typography
                                    as="h6"
                                    style={{
                                      fontSize: "15px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {item.value / 10}
                                  </Typography>
                                </div>
                                <ProgressBar
                                  variant="danger"
                                  now={item.value}
                                  style={{ height: "5px" }}
                                />
                              </div>
                            ))}
                          </Col>
                          <Col>
                            {[
                              { label: "Location", value: 80 },
                              { label: "Atmosphere", value: 85 },
                              { label: "Facilities", value: 85 },
                            ].map((item, index) => (
                              <div key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    as="h6"
                                    style={{
                                      fontSize: "15px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {item.label}
                                  </Typography>
                                  <Typography
                                    as="h6"
                                    style={{
                                      fontSize: "15px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {item.value / 10}
                                  </Typography>
                                </div>
                                <ProgressBar
                                  variant="danger"
                                  now={item.value}
                                  style={{ height: "5px" }}
                                />
                              </div>
                            ))}
                          </Col>
                        </Row>
                        <div
                          style={{
                            marginLeft: "30px",
                            maxHeight: showAllReviews ? "none" : "200px",
                            overflow: "hidden",
                          }}
                        >
                          {limitedReviewData.map((review, index) => (
                            <div key={index} style={{ display: "flex" }}>
                              <div>
                                <PersonIcon />
                                <Typography
                                  as="h6"
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "18px",
                                  }}
                                >
                                  {review.name}
                                </Typography>
                                <Typography
                                  as="h6"
                                  style={{ color: "gray", fontSize: "14px" }}
                                >
                                  {review.gender}, {review.age}
                                </Typography>
                                <Typography
                                  as="h6"
                                  style={{ color: "gray", fontSize: "14px" }}
                                >
                                  {review.status}
                                </Typography>
                                <Typography
                                  as="h6"
                                  style={{ color: "orange", fontSize: "14px" }}
                                >
                                  {review.reviews} reviews
                                </Typography>
                              </div>
                              <div
                                style={{
                                  marginLeft: "60px",
                                  textAlign: "start",
                                  marginTop: "10px",
                                }}
                              >
                                <div style={{ display: "flex", gap: "15px" }}>
                                  <h4
                                    style={{
                                      backgroundColor: "tomato",
                                      color: "white",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {review.rating}
                                  </h4>
                                  <h6 style={{ color: "tomato" }}>
                                    {review.rating >= 8
                                      ? "Very Good"
                                      : review.rating >= 5
                                      ? "Good"
                                      : "Average"}
                                  </h6>
                                </div>
                                <p
                                  style={{
                                    fontFamily: "Roboto",
                                    fontSize: "17px",
                                  }}
                                >
                                  {review.feedback}
                                </p>
                              </div>
                            </div>
                          ))}
                          {reviewData.length > 2 && (
                            <Button
                              variant="contained"
                              onClick={handleShowreview}
                            >
                              {showAllReviews ? "Show Less" : "Read More"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="gallery"
                      title={
                        <span
                          onClick={() => handleTabClick("gallery")}
                          style={{
                            color: activeTab === "gallery" ? "blue" : "black",
                          }}
                        >
                          <CollectionsOutlinedIcon /> Gallery
                        </span>
                      }
                    >
                      <div className="tabs-gallery-container">
                        <Container>
                          <Row>
                            {gallery.map((image, idx) => (
                              <Col xs={4} sm={3} md={3} lg={2} key={idx}>
                                <img
                                  src={image}
                                  alt={`Gallery Image ${idx}`}
                                  style={{
                                    width: "100%",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                    gap: "10px",
                                  }}
                                  onClick={() => handleShowgallery(idx)}
                                />
                              </Col>
                            ))}
                          </Row>

                          <Modal
                            show={show}
                            onHide={handleClosegallery}
                            size="lg"
                            centered
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <Carousel
                                activeIndex={index}
                                onSelect={handleSelect}
                                interval={null}
                              >
                                {gallery.map((image, idx) => (
                                  <Carousel.Item key={idx}>
                                    <img
                                      className="d-block w-100"
                                      src={image}
                                      alt={`Slide ${idx}`}
                                    />
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleClosegallery}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Container>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="thingstodo"
                      title={
                        <span
                          onClick={() => handleTabClick("thingstodo")}
                          style={{
                            color:
                              activeTab === "thingstodo" ? "blue" : "black",
                          }}
                        >
                          <ModeOfTravelIcon /> Local Tours
                        </span>
                      }
                    >
                      <div className="local-tours">
                        <Container
                          style={{
                            height: "auto",
                            overflowX: "scroll",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Row className="flex-nowrap">
                            {places.map((place, index) => (
                              <Col
                                key={index}
                                style={{
                                  display: "inline-block",
                                  float: "none",
                                }}
                              >
                                <Card
                                  style={{
                                    width: "18rem",
                                    display: "inline-block",
                                    margin: "0 10px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Card.Img
                                    variant="top"
                                    src={place.imageUrl}
                                    style={{ height: "150px" }}
                                  />
                                  <Card.Body>
                                    <Card.Title
                                      style={{
                                        fontFamily: "Open Sans",
                                        animation: "fadeIn 1s",
                                        textShadow:
                                          "2px 2px 4px rgba(0, 0, 0, 0.3)",
                                      }}
                                    >
                                      {place.title}
                                    </Card.Title>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <ReactStars
                                        count={5}
                                        value={place.rating}
                                        size={24}
                                        activeColor="#ffd700"
                                        edit={false}
                                        color={"#fff59d"}
                                      />
                                      <a
                                        style={{
                                          cursor: "pointer",
                                          color: "orange",
                                          textDecoration: "underline",
                                        }}
                                        onClick={() => handleShowtours(index)}
                                      >
                                        {selectedPlaceIndex === index
                                          ? "Show Less "
                                          : "Explore More "}
                                      </a>
                                    </div>
                                    <div
                                      style={{
                                        maxHeight:
                                          selectedPlaceIndex === index
                                            ? "100px"
                                            : "0",
                                        overflow: "hidden",
                                        transition: "max-height 0.5s ease",
                                      }}
                                    >
                                      {selectedPlaceIndex === index && (
                                        <Card.Text
                                          style={{
                                            fontFamily: "Open Sans",
                                            marginTop: "10px",
                                            whiteSpace: "normal",
                                            textShadow:
                                              "1px 1px 2px rgba(0, 0, 0, 0.2)",
                                          }}
                                        >
                                          {place.description} 📜
                                        </Card.Text>
                                      )}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </Container>

                        <style jsx="true">{`
                          @keyframes fadeIn {
                            from {
                              opacity: 0;
                            }
                            to {
                              opacity: 1;
                            }
                          }
                        `}</style>
                      </div>
                    </Tab>
                  </Tabs>
                </div>

                <Container className="mt-4">
                  <Row className="gx-3">
                    {ootyapartments.map((apt, index) => (
                      <Col key={index} xs={12} md={12} lg={12} className="mb-4">
                        <Card className="h-100">
                          <Row className="p-3">
                            <Col md={5}>
                              <Carousel>
                                {apt.images.map((image, idx) => (
                                  <Carousel.Item key={idx} interval={10000}>
                                    <img
                                      className="d-block w-100"
                                      src={image}
                                      alt={`Slide ${idx}`}
                                      style={{
                                        height: "250px",
                                        position: "relative",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            </Col>
                            <Col md={7}>
                              <div className="d-flex flex-column h-100 justify-content-between">
                                <div>
                                  <Typography
                                    variant="h5"
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontSize: "21px",
                                      fontWeight: "500",
                                      marginTop: "8px",
                                    }}
                                  >
                                    {apt.name}
                                  </Typography>
                                  <p
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "5px",
                                      fontFamily: "Roboto",
                                      width: "auto",
                                    }}
                                  >
                                    {apt.description}
                                  </p>
                                  <div className="d-flex justify-content-center align-items-center mt-3">
                                    <div className="mt-2">
                                      <h5
                                        style={{
                                          color: "palevioletred",
                                          fontFamily: "Roboto",
                                          fontSize: "15px",
                                        }}
                                      >
                                        Apartment
                                      </h5>
                                      <p
                                        style={{
                                          fontFamily: "Roboto",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {apt.discountedPrice}/night
                                      </p>
                                    </div>

                                    <div className="ms-auto">
                                      <Button
                                        variant="outlined"
                                        style={{
                                          backgroundColor: "orange",
                                          color: "black",
                                          // fontSize: "12px",
                                          width: "150px",
                                        }}
                                        className="add-btn"
                                        onClick={() =>
                                          window.open(
                                            "https://bookingengine.stayflexi.com/?hotel_id=29067",
                                            "_blank"
                                          )
                                        }
                                      >
                                        Book now
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterContainer />
    </section>
  );
};

export default Ootyprabhusplace;
